import "reflect-metadata";
import {ClickableColumns, TableColumnDefinition, TableConfig} from '../components/TableComponent/config';
import {Objects} from "./objects";
import {Lists} from "./lists";
import {ClassOf, InstanceOf} from "../cms/models";
import {CMSModel, Field, FieldGroup, FieldType, ModelInfo} from "../cms/models/__ModelInfo";
import {SyntheticTypes, Types} from "../cms/types";
import {getRecoil} from "../state/recoilNexus";
import {cmsModelsSelector} from "../state/models";
import {CMSObject} from "../cms/models/__CMSObject";
import {BaseWidget} from "../cms/models/PageWidget";

const tableDecorated: any = {}

const hiddenSymbol = Symbol("hidden");
const ignoredSymbol = Symbol("ignored");
const clickableSymbol = Symbol("clickable");

// Indicates to the table that should not add this property as a default column for the table.
export const tableHidden = (target: any, memberName: string) => {
    Reflect.defineMetadata(hiddenSymbol, true, target, memberName);
}

// Indicates to the table that should not display this property as a column for the table.
export const tableIgnored = (target: any, memberName: string) => {
    Reflect.defineMetadata(ignoredSymbol, true, target, memberName);
}

// Indicates to the table that should make this property a link pointing to the edit form
export const tableClickable = (target: any, memberName: string) => {
    Reflect.defineMetadata(clickableSymbol, true, target, memberName);
}

// Class decorator for tables
export const tableConfig = (config?: Partial<TableConfig>) => {
    return <T extends { new(...constructorArgs: any[]) }>(constructorFunction: T) => {
        const obj = Objects.default(tableDecorated[constructorFunction.name]);
        tableDecorated[constructorFunction.name] = Objects.extend(config, obj)
        return constructorFunction;
    }
}

const addColumnDefinition = (config: TableColumnDefinition, target: any, memberName: string) => {
    const tableCfg = Objects.default(tableDecorated[target.constructor.name])
    const column = {...config}
    if (typeof target[memberName] === "function") {
        column.render = target[memberName]
    }
    tableCfg.additionalColumns = [...Lists.default(tableCfg.additionalColumns), column]
    tableDecorated[target.constructor.name] = tableCfg;
}

export const tableColumn = (config?: TableColumnDefinition) => {
    return (target: any, memberName: string, _propertyDescriptor: PropertyDescriptor) => {
        addColumnDefinition(Objects.default(config), target, memberName);
    }
}

export const columnDefinition = (config?: Partial<TableColumnDefinition>) => {
    return function (target: Object, propertyKey: string) {
        addColumnDefinition({...Objects.default(config), field: propertyKey}, target, propertyKey);
    }
}

export function isColumnHidden(target: any, propertyKey: string): boolean {
    const val = Reflect.getMetadata(hiddenSymbol, target, propertyKey);
    if (typeof val === 'undefined') {
        return false
    }
    return val
}

export function isColumnIgnored(target: any, propertyKey: string): boolean {
    const val = Reflect.getMetadata(ignoredSymbol, target, propertyKey);
    if (typeof val === 'undefined') {
        return false
    }
    return val
}

export function isClickable(target: any, propertyKey: string) {
    return ClickableColumns.includes(propertyKey) || Reflect.getMetadata(clickableSymbol, target, propertyKey);
}

export function getTableConfig(target: any) {
    const obj = Objects.default(tableDecorated[target.constructor.name])
    let res = {
        ...obj,
        additionalColumns: [...Lists.default(obj.additionalColumns)]
    };

    let tmp = target.__proto__
    while (tmp) {
        if (target.constructor.name === tmp.constructor.name) {
            tmp = tmp.__proto__
            continue
        }
        res = Objects.extend(res, Objects.default(tableDecorated[tmp.constructor.name]))
        tmp = tmp.__proto__
    }

    res.additionalColumns = Lists.default(res.additionalColumns).filter((c: any) => {
        return !(c.manualPublish && target.constructor.autoPublish);
    });


    if (Lists.default(res.itemTypes).length) {
        res.itemTypes.forEach((itemType: any) => {
            const instance = InstanceOf(itemType, false);
            if (!instance) {
                return
            }
            const cfg = getTableConfig(instance);
            if (!cfg) {
                return
            }
            Lists.default(cfg.additionalColumns).forEach((col: any) => {
                if (!res.additionalColumns.find(c => c.field === col.field)) {
                    res.additionalColumns.push(col);
                }
            })
        })
    }
    return res;
}

// ------------------------------------------------------------------
// Form decorators and helper functions
// ------------------------------------------------------------------
const formDecorated: { [key: string]: FormConfig } = {}
// @ts-ignore
window.formDecorated = formDecorated;

const formFieldSymbol = Symbol("formField");
//const formLinkSymbol = Symbol("formLink");

type FormConfig = {
    title: string | ((object: CMSObject) => string)
    fields: Field[];
    groups: FieldGroup[];
    model: CMSModel;
    buttons: React.ReactElement[];
}

// Class decorator for forms
export function formConfig(config: Partial<FormConfig>) {
    return <T extends { new(...constructorArgs: any[]) }>(constructorFunction: T) => {
        const obj = Objects.default(formDecorated[constructorFunction.name]);
        formDecorated[constructorFunction.name] = Objects.extend(config, obj)
        return constructorFunction;
    }
}

export enum CustomDataSources {
    TrueFalse = "true-false",
    Events = "events"
}

export type DataSourceType = CustomDataSources | Types

export type DataSourceFieldConfig = {
    type: DataSourceType,
    // use the value of the filterField to filter the datasource values
    filterField?: string;
} & Partial<Field>

// Define a new property on the target object that will be rendered as a datasource
export function dataSourceFormField(config: DataSourceFieldConfig) {
    return (target: any, memberName: string) => {
        const {type, filterField, ...fieldConfig} = config;
        const field = new Field(fieldConfig);
        field.fieldtype = FieldType.Reference;
        field.config = {...field.config, refModel: type}
        Reflect.defineMetadata(formFieldSymbol, config, target, memberName)
    }
}

type ExtendedFormField = {
    linkedField?: string;
} & Partial<DataSourceFieldConfig>

export function formHidden() {
    return formField({flags: {hidden: true}})
}

// Decorator used to override the server field definition
export function formField(config: Partial<ExtendedFormField>) {
    return (target: any, memberName: string) => {
        const formCfg = Objects.default(formDecorated[target.constructor.name])
        const fields = Lists.default<Field>(formCfg.fields);
        let field: any = fields.find((f) => f.uid === memberName)
        const idx = fields.indexOf(field)
        if (!field) {
            field = new Field({uid: memberName, ...config});
            fields.push(field)
        } else {
            fields[idx] = new Field(extend(field.__data, config))
        }
        formCfg.fields = fields;
        formDecorated[target.constructor.name] = formCfg
        Reflect.defineMetadata(formFieldSymbol, config, target, memberName)
    }
}

export function linkedFormField(uuid: string) {
    return (target: any, memberName: string) => {
        const formCfg = Objects.default(formDecorated[target.constructor.name])
        const fields = Lists.default<Field>(formCfg.fields);
        let field = fields.find((f) => f.uid === memberName)
        if (!field) {
            field = new Field({uid: memberName, config: {linkedWith: uuid}});
            fields.push(field)
        } else {
            field.config.linkedWith = uuid
        }
        formCfg.fields = fields;
        formDecorated[target.constructor.name] = formCfg
    }
}

export function getFormConfig(formType: string) {
    let target: any
    let obj: any;

    if (formType.includes("_widget")) {
        let widget = BaseWidget.widgetStore[formType.replace("_widget", "")]
        if (widget) {
            target = new widget.Class()
        } else {
            target = new BaseWidget()
        }
        obj = Objects.default(formDecorated[target.constructor.name]);
    } else {
        target = InstanceOf(formType)
        obj = Objects.default(formDecorated[target.constructor.name]);
    }

    let res = {
        ...obj
    };
    let tmp = target.__proto__;
    while (tmp) {
        if (target.constructor.name === tmp.constructor.name) {
            tmp = tmp.__proto__
            continue
        }
        res = Objects.extend(res, Objects.default(formDecorated[tmp.constructor.name]))
        tmp = tmp.__proto__
    }

    let model: ModelInfo;
    if (!SyntheticTypes.includes(formType as Types)) {
        model = getRecoil(cmsModelsSelector(formType))
    } else {
        const cls = ClassOf(formType)
        model = cls.model
        if (!model) {
            model = new ModelInfo({model: {uid:'unspecified'}, fields: []})
        }
    }

    const fields: Field[] = [];
    if (model) {
        model.fields.forEach((field: Field) => {
            const customFieldSettings = Lists.default<Field>(res.fields).find((f: Field) => f.uid === field.uid);
            if (!customFieldSettings) {
                fields.push(field)
            } else {
                const f = new Field(extend(field.__data, customFieldSettings.__data))
                fields.push(f)
            }
        })
    }
    Lists.default<Field>(res.fields).forEach(f => {
        if (fields.find(mf => mf.uid === f.uid)) {
            return
        }
        fields.push(f)
    })

    Lists.sort(fields, 'order')

    res.model = model.clone();
    res.model.fields = fields;
    res.fields = fields
    return res;
}

const extend = (a: any, b: any): any => {
    const A = cleanObject(a)
    const B = cleanObject(b)
    if (Object.keys(A).length === 0 && Object.keys(B).length !== 0) {
        return B
    }
    if (Object.keys(A).length !== 0 && Object.keys(B).length === 0) {
        return A
    }

    const tmp = {...A, ...B};
    for (let k in A) {
        if (Array.isArray(A[k]) && Array.isArray(B[k])) {
            tmp[k] = Array.from(new Set([...A[k], ...Lists.default(B[k])]))
        } else if (typeof A[k] === 'object' && typeof B[k] === 'object') {
            tmp[k] = extend(A[k], Objects.default(B[k]))
        }
    }
    return tmp
}

const cleanObject = (obj: any): any => {
    const A = {...obj}
    for (let k in A) {
        if (Array.isArray(A[k])) {
            if (A[k].length === 0) {
                delete (A[k])
            }
        } else if (typeof A[k] === 'undefined' || A[k] === null) {
            delete (A[k])
        } else if (typeof A[k] === 'object') {
            A[k] = cleanObject(A[k])
            if (Object.keys(A[k]).length === 0) {
                delete (A[k])
            }
        }
    }
    return A
}
