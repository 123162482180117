import React, { useMemo } from 'react';
import { Dates, TIMEZONES } from '../../utils/dates';
import { Lists } from '../../utils/lists';
import SelectComponent from '../Form/renderers/components/Select/SelectComponent';
import { FieldType } from '../../cms/models/__ModelInfo';

const TimeZoneSettings = () => {
	const [ timezone, setTimezone ] = Dates.useTimeZone(); // default value = timezone date
	const zones = useMemo(() => {
		return Lists.default<string>(TIMEZONES).map(zone => ({
			value: zone,
			label: zone,
			object: zone,
		}));
	}, []);

	return (
		<SelectComponent
			portal={document.body}
			data-testid='timezone-selector-list'
			id="timezone"
			onChange={(selection) => setTimezone(selection.value)}
			values={zones}
			fieldType={FieldType.List}
			value={timezone.replaceAll('"', '')}
			isClearable={false}
			label='Show date/times using timezone:'/>
	);
};

export default TimeZoneSettings;
