import React, {useEffect, useMemo, useState} from 'react';
import {Field} from "../../../cms/models/__ModelInfo";
import {fi} from "../../../utils/helpers";
import {useFormField} from "../state";
import FileUpload from "./components/FileUpload/FileUpload";
import {CMSFile} from '../../../cms/models/File';
import {setRecoil} from '../../../state/recoilNexus';
import {references, uploadedFile} from '../../../state/state';
import {sessionFolderIdAtom} from '../../../state/session';
import { useRecoilValue } from 'recoil';

const DEFAULT_MAX_SIZE = 10000000;
const DEFAULT_ACCEPT = "*";

const FileRenderer = ({field}: { field: Field }) => {
    const folderId = useRecoilValue(sessionFolderIdAtom);
    const formField = useFormField(field.uid);
    const [fileId, setFileId] = useState(formField.value);
    const [maxSize, accept] = useMemo(() => {
        const validation = field.validations.find(v => v.type === "file")
        if (validation) {
            return [validation.config.maxSize, validation.config.allow]
        }
        return [DEFAULT_MAX_SIZE, DEFAULT_ACCEPT]
    }, [field])

    const onUpload = (files: CMSFile[]) => {
        if (files.length === 0) {
            return;
        }
        const file = files[0];
        setRecoil(references(file.fileuid), file)
        setRecoil(uploadedFile, file);
        setFileId(file.fileuid)
    }

    const body = () => {
        if(formField.field.config.body === 'tile-content') {
            return {folderId}
        } else {
            return formField.field.config.body;
        }
    }

    useEffect(() => {
        if (fileId !== formField.value) {
            formField.setValue(fileId);
        }
        // eslint-disable-next-line
    }, [fileId])

    return (
        <>
            <label className={fi(field.flags.required, 'required')}>{field.name}</label>
            {field.description && <small>{field.description}</small>}
            <FileUpload error={formField.showError()} accept={accept} maxSize={maxSize} onUpload={onUpload} uploadURL={formField.field.config.uploadURL} body={body()} />
        </>
    )
}

export default FileRenderer
