import ExpandMore from '@mui/icons-material/ExpandMore';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import React, { ForwardedRef, useEffect, useMemo, useRef, useState } from 'react';
import Select, { components as simpleSelectComponents } from 'react-select';
import WindowedSelect, { components as windowedComponents } from 'react-windowed-select';
import { fi } from '../../../../../utils/helpers';
import { Lists } from '../../../../../utils/lists';
import { Strings } from '../../../../../utils/strings';
import CheckboxOption from './CheckboxOption';
import { useRecoilValue } from 'recoil';
import { sessionAtom } from '../../../../../state/session';
import axios from 'axios';
import defaultIcon from '../../../../../assets/images/icon_placeholder.svg';
import { FieldType } from '../../../../../cms/models/__ModelInfo';

export interface ISelectValue {
	value: any;
	label: string;
	object: any;
	options?: ISelectValue[];
}

export interface ISelect {
	label: string;
	id: string;
	value: any;
	values: ISelectValue[];
	onChange: (...props: any) => any;

	fieldType: FieldType;

	name?: string;
	ariaLabel?: string;
	showError?: boolean;
	touched?: boolean;
	errorMessage?: string;
	checkbox?: boolean;
	dataTestId?: string;
	required?: boolean;
	loading?: boolean;
	readonly?: boolean;
	render?: (val: any) => string;
	showSelectAll?: boolean;
	selectAllValue?: boolean;
	onChangeSelectAll?: (selectValue: boolean, id: string) => any;
	placeholder?: string;
	portal?: any;
	updateTree?: any;
	clearAll?: any;
	isClearable?: boolean;
}

export const PageIcon = ({value, isSelected, icon = defaultIcon}: {
	value: string,
	isSelected?: boolean,
	icon?: any
}) => {
	const user = useRecoilValue(sessionAtom);
	return <img src={`${axios.defaults.baseURL}/iconDownload/${value}?token=${user!.downloadToken}&stream=${user!.selectedBusinessStream}&stream=${user!.selectedBusinessStream}`} alt={'page icon'}
				width='25px'
				height='25px'
				className={fi(isSelected, 'selected-icon', '')}
				onError={(e) => {
					e.currentTarget.onerror = null;
					e.currentTarget.src = icon;
				}} />;
};

const IconOption = (props) => {
	return (
		<simpleSelectComponents.Option {...props} >
			<div key={props.value}>
				<PageIcon value={props.value} isSelected={props.isSelected} />
			</div>
		</simpleSelectComponents.Option>
	);
};

const CustomList = React.forwardRef((props: any, ref: ForwardedRef<any>) => {
	const hasOptions = Lists.default<ISelectValue>(props.options).find(i => !!i.options);
	if (hasOptions) {
		return <Select ref={ref} key={props.id} {...props} />;
	}
	return <WindowedSelect ref={ref} key={props.id} {...props} />;
});

const MultiValueLabel = (props) => {
	const hasOptions = Lists.default<ISelectValue>(props.options).find(i => !!i.options);
	let Component: any = simpleSelectComponents.MultiValueLabel;
	if (hasOptions) {
		Component = windowedComponents.MultiValueLabel
	}

	return <Component key={props.selectProps.value}  {...props} />;
};

interface ICustomStyles {
	showError: boolean;
	alignRight: boolean;
	alignTop: boolean;
}

const option = (provided) => {
	return {
		...provided,
		backgroundColor: 'transparent',
		fontSize: 16,
		'&:hover': {
			backgroundColor: 'var(--color-selected)',
		},
		'&.bold-option': {
			padding: 0,
			display: 'flex',
			alignItems: 'flex-start',
			fontFamily: 'var(--font-semi-bold)',
			cursor: 'pointer',
			':active': {
				backgroundColor: 'transparent',
			},
			'& .checkbox-option-text': {
				paddingTop: 9,
			},
		},
	};
};

const customStyles = (props: ICustomStyles) => {
	return {
		menuPortal: base => ({...base, zIndex: 9999, fontFamily: 'var(--font-regular)'}),
		multiValue: (provided, {isDisabled}) => {
			return {
				...provided,
				backgroundColor: 'transparent',
				fontSize: 19,
				fontFamily: 'var(--font-regular)',
				paddingLeft: '0',
				'& .css-12jo7m5': {
					paddingLeft: '0',
				},
				'& div': {
					color: fi(isDisabled, 'var(--color-border)', 'var(--color-black)'),
				},
			};
		},
		multiValueRemove: (provided) => {
			return {...provided, display: 'none'};
		},
		control: (provided) => {
			return {
				...provided,
				borderColor: fi(props.showError, 'var(--color-red)', 'var(--color-border)'),
				boxShadow: 'none',
				minHeight: 44,
				fontFamily: 'var(--font-regular)',
				'&:hover': {
					borderColor: 'inherit',
				},
				backgroundColor: 'white',
			};
		},
		option,
	};
};

// eslint-disable-next-line
const tagStyles = () => {
	return {
		multiValue: (provided, {isDisabled}) => {
			return {
				...provided,
				height: '23px',
				margin: '0 8px 0 0',
				borderRadius: '11.5px',
				border: 'solid 1px var(--color-grey)',
				backgroundColor: 'var(--color-white)',
				'& .css-12jo7m5': {
					padding: '3px 8px',
					fontFamily: 'var(--font-bolder)',
					fontSize: 14,
					fontWeight: 'bold',
					fontStretch: 'normal',
					fontStyle: 'normal',
					lineHeight: '1.21',
					letterSpacing: 'normal',
					textAlign: 'left',
				},
				'& .css-eyboaj': {
					'svg': {
						width: 17,
						height: 17,
					},
				},
				'& div': {
					color: fi(isDisabled, 'var(--color-border)', 'var(--color-black)'),
				},
			};
		},
		multiValueRemove: (provided) => {
			return {
				...provided,
				borderRadius: '0',
				borderTopRightRadius: '11.5px',
				borderBottomRightRadius: '11.5px',
				':hover': {
					backgroundColor: 'var(--color-grey)',
					color: 'var(--color-black)',
				},


			};
		},
		control: (provided) => {
			return {
				...provided,
				borderColor: 'var(--color-border)',
				boxShadow: 'none',
				minHeight: 44,
				'&:hover': {
					borderColor: 'inherit',
				},
				backgroundColor: 'white',
			};
		},
		option,
	};
};

const SelectComponent = (props: ISelect) => {
	const [alignRight, setAlignRight] = useState(false);
	const [alignTop, setAlignTop] = useState(false);
	const portalRef = useRef<HTMLDivElement>(document.getElementById('list-portal-wrapper') as HTMLDivElement);

	const menuObserver: any = useRef({});
	const selectRef: any = useRef();

	const onMenuOpen = () => {
		const observeOnscreen = (entries: IntersectionObserverEntry[], _observer: IntersectionObserver): void => {
			const {boundingClientRect, intersectionRect} = entries[0];
			const isOffscreenLeft = boundingClientRect.width < intersectionRect.width;
			setAlignRight(!isOffscreenLeft);
			const isOffscreenBottom = boundingClientRect.height < intersectionRect.height;
			setAlignTop(!isOffscreenBottom);
			setPortalPosition(!isOffscreenBottom);
		};

		setTimeout(() => {
			const menuList = selectRef.current.menuListRef;
			menuObserver.current = new IntersectionObserver(observeOnscreen);
			menuObserver.current.observe(menuList);
			setPortalPosition();
		}, 1);
	};

	useEffect(() => {
		const tmp = portalRef.current;
		return () => {
			if (tmp) {
				tmp.style.width = '0px';
				tmp.style.height = '0px';
			}
		};
	}, []);

	const setPortalPosition = (topAlign: boolean = false) => {
		if (!props.portal) return;

		const input = selectRef.current.controlRef as any as HTMLDivElement;
		if (input) {
			const box = input.getBoundingClientRect();
			portalRef.current.style.left = `0px`;
			portalRef.current.style.width = `${box.width}px`;
			if (topAlign) {
				portalRef.current.style.bottom = `${box.top - 4}px`;
			} else {
				portalRef.current.style.top = `0px`;
			}
		}
	};

	const onMenuClose = () => {
		setAlignRight(false);
		if (menuObserver.current && menuObserver.current.disconnect) {
			menuObserver.current.disconnect();
		}
		const tmp = portalRef.current;
		if (tmp) {
			tmp.style.width = '0px';
			tmp.style.height = '0px';
		}
	};

	const handleChange = (selection, a) => {
		switch (props.fieldType) {
			case FieldType.NumberList:
				if (!props.checkbox) {
					props.onChange(selection, props.id);
					return
				}
				let newValue: any[] = [];
				if (selection.length >  0) {
					const value = +selection.pop().value
					 newValue = [...props.value];
					if (~newValue.indexOf(value)) {
						newValue.splice(newValue.indexOf(value), 1);
					} else {
						newValue.push(value)
						newValue = Array.from(new Set(newValue)).sort();
					}
				}
				props.onChange(newValue, props.id);
				break;

			default:
				props.onChange(selection, props.id);
		}
	};

	const handleValue = useMemo(() => {
		if (props.checkbox) {
			return Lists.default(props.value).map(v => ({label: v, value: v, object: v}));
		}
		// simple selection
		return props.values.find((option) => option.value === props.value) || null;
		// eslint-disable-next-line
	}, [props.value, props.values]);

	return (
		<FormControl variant='standard' fullWidth={true} sx={{marginBottom: 0}}>
			<FormLabel htmlFor={props.id} className={fi(Boolean(props.required), 'required')}>
				{props.label}
			</FormLabel>
			<CustomList
				ref={selectRef}
				menuPortalTarget={props.portal}
				styles={customStyles({showError: Boolean(props.showError), alignRight, alignTop})}
				required={Boolean(props.required)}
				IconComponent={ExpandMore}
				aria-label={Strings.default(props.ariaLabel, props.id)}
				components={fi(props.id === 'icon', {Option: IconOption}, props.checkbox && {
					Option: CheckboxOption,
					MultiValueLabel,
				})}
				title={props.name}
				variant='outlined'
				formatOptionLabel={(option) => {
					if (props.render) {
						return props.render(option);
					}
					if (props.id === 'icon') {
						return <PageIcon value={option.value} />;
					}
					return Strings.default(option.label);
				}}
				id={props.id}
				placeholder={props.placeholder ? props.placeholder : ''}
				name={Strings.default(props.name, props.id)}
				value={handleValue}
				onChange={handleChange}
				error={props.showError}
				isLoading={props.loading || false}
				isMulti={!!props.checkbox}
				data-testid={Strings.default(props.dataTestId)}
				options={props.values}
				menuPlacement='auto'
				windowThreshold={20}
				hideSelectedOptions={!props.checkbox}
				closeMenuOnSelect={!props.checkbox}
				isClearable={props.isClearable}
				onMenuOpen={onMenuOpen}
				onMenuClose={onMenuClose}
				isDisabled={props.readonly || props.selectAllValue}
			/>
		</FormControl>
	);
};

export default SelectComponent;
