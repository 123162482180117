import {BaseWidget, widgetConfig, WidgetGroup, WidgetType} from './PageWidget';
import {Types, UUID} from '../types';
import {formField} from '../../utils/decorators';
import {FieldType} from './__ModelInfo';
import {Lists} from '../../utils/lists';
import {IFormEvents} from '../../components/Form/model';
import React from 'react';
import {Faded, PreviewRow, PreviewWrapper} from './_style';
import {fi} from '../../utils/helpers';

@widgetConfig({
	group: WidgetGroup.Content,
	name: "Resource finder",
	description: "Used for searching resources.\n• It can only be added to the page once.",
	constrains: {
		once: true,
	}
})
export class ResourceFinderWidget extends BaseWidget implements IFormEvents{
	@formField({
		name: "Content Types",
		fieldtype: FieldType.Reference,
		flags: {multiple: true},
		config: {refModel: Types.CONTENT_TYPE}
	})
	public contentTypes: UUID[];

	@formField({
		fieldtype: FieldType.Boolean,
		name: 'Include Content Categories as filter',
	})
	public contentCategories: boolean = false;

	@formField({
		fieldtype: FieldType.Boolean,
		name: 'Include Content Groups as filter',
	})
	public contentGroups: boolean = false;

	constructor(item: any = {}) {
		super(item);
		this.type = WidgetType.ResourceFinder
		this.contentTypes = Lists.default(item.content_types)
		if (typeof item.contentCategories !== 'undefined') {
			this.contentCategories = Boolean(item.contentCategories);
		}
		if (typeof item.contentGroups !== 'undefined') {
			this.contentGroups = Boolean(item.contentGroups);
		}
	}

	public editable() {
		return true;
	}

	public preview(): React.ReactElement | null {
		return (
			<PreviewWrapper>
				<PreviewRow>
					<label>Filters</label>
					{fi(this.contentCategories, <span>Content categories</span>, <Faded>Content categories</Faded>)} /
					{fi(this.contentGroups, <span>Content groups</span>, <Faded>Content groups</Faded>)}
				</PreviewRow>
			</PreviewWrapper>
		);
	}
}