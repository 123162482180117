import React from 'react';
import { components } from 'react-select';
import { fi } from '../../../../../utils/helpers';
import styled from '@emotion/styled';
import ListCheckbox from '../../../../commons/ListCheckbox';

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    span.checkbox-option-text {
        padding-top: 0 !important;
    }
`;

const CheckboxOption = (props) => {
	const value = props.getValue();
	const isNumeric = !value.find((item) => typeof item.object !== 'number');

	const isSelected = props.isSelected || value.find((i: any) => isNumeric ? +i.value === +props.data.value : i.value === props.data.value);
	return (
		<components.Option {...props} data-testid="checkbox-option-test-id" className="bold-option">
			<Wrapper>
				<ListCheckbox style={{color: `var(${fi(isSelected, '--color-blue', '--color-monochrome')}`}} className={
					`
					${!isSelected ? 'checkbox-img-unchecked' : 'checkbox-img-checked'}
					`
				}>
				</ListCheckbox>
				<span style={{color: `var(${fi(isSelected, '--color-blue', '--color-monochrome')}`}}
					  className="checkbox-option-text">
					{props.data.label}
				</span>
			</Wrapper>
		</components.Option>
	);
};

export default CheckboxOption;
