import {createTheme} from '@mui/material';

export const theme = createTheme({
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: 16,
        h1: {
            fontFamily: 'var(--font-bolder)',
            fontWeight: 400,
            fontSize: 18,
            color: 'var(--color-monochrome)',
        },
        h2: {
            fontFamily: 'var(--font-bold)',
            fontWeight: 400,
            fontSize: 18,
            color: 'var(--color-monochrome)',
        },
        h3: {
            fontFamily: 'var(--font-semibold)',
            fontSize: 24,
        },
        h4: {
            fontFamily: 'var(--font-semibold)',
            fontSize: 16,
            fontWeight: 600,
        }
    },
    components: {
        MuiInputBase: {
            defaultProps: {
            },
            styleOverrides: {
                root: {
                    padding: '2px',
                    fontSize: 16,
                    fontFamily: 'var(--font-regular)',
                    border: '1px solid var(--color-border)',
                    borderRadius: 4,
                },

            },
        },
        MuiInput: {
            defaultProps: {
                disableUnderline: true,

            },
            styleOverrides: {
                root: {
                    padding: '4px 8px',
                    backgroundColor: 'white',
                    '&.Mui-error': {
                        borderColor: 'var(--color-red) !important',
                        backgroundColor: 'white',
                    }
                }
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    marginBottom: 16,
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontFamily: 'var(--font-semi-bold)',
                    fontSize: 16,
                    color: 'var(--color-monochrome)',
                    lineHeight: '22px',
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: 16,
                },
            },
        },
        MuiTableSortLabel: {
            styleOverrides: {
                root: {
                    color: 'var(--color-monochrome)',
                    fontFamily: 'var(--font-semi-bold)',
                    fontWeight: 500,
                },
                iconDirectionAsc: {
                    fill: 'var(--color-monochrome)',
                },
                iconDirectionDesc: {
                    fill: 'var(--color-monochrome)',
                }
            }
        },
        MuiSwitch: {
            defaultProps: {
                disableRipple: true,
                disableFocusRipple: true,
                disableTouchRipple: true,
            },
            styleOverrides: {
                root: {
                    paddingLeft: 0,
                    width: 52,
                },
                switchBase: {
                    background: 'none',
                    paddingLeft: 0,
                    '&:hover': {
                        background: 'none',
                    },
                },
                edgeStart: {
                    backgroundColor: `var(--color-blue)`,
                    borderColor: `var(--color-blue)`,
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    fontFamily: 'var(--font-semi-bold) !important',
                    fontSize: 16,
                    color: 'var(--color-monochrome)',
                    '.MuiFormControlLabel-asterisk': {
                        color: 'var(--color-red) !important',
                    }
                },
                label: {
                    fontFamily: 'var(--font-semi-bold)!important',
                    fontSize: 16,
                    color: 'var(--color-monochrome)',

                }
            }
        },
        MuiFormGroup: {
            styleOverrides: {
                root: {
                    '& label.MuiFormControlLabel-root': {
                        marginBottom: 0,
                    }
                }
            }
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    borderRadius: 4,
                    padding: '8px 22px',
                    color: 'var(--color-blue)',
                },
            },
        },
        MuiRadio: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    padding: '0 8px',
                    '& + .MuiTypography-root': {
                        fontSize: '16px',
                    }
                }
            },
        },
        MuiBreadcrumbs: {
            styleOverrides: {
                separator: {
                    minWidth: 18,
                },
                ol: {
                    flexWrap: 'nowrap',
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                },
                li: {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',

                    '&:first-of-type': {
                        overflow: 'inherit'
                    },
                    '&:last-child': {
                        overflow: 'inherit'
                    },
                },
            }
        },
        MuiButton: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    borderRadius: 4,
                    padding: '8px 22px',
                    textTransform: 'none',
                    boxShadow: 'none',
                    fontSize: 16,
                    fontFamily: 'var(--font-semi-bold)',
                    fontWeight: 500,
                    userSelect: 'none',
                    color: 'var(--color-blue)',
                    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    '&:hover': {
                        boxShadow: 'none',
                    },
                    '&:active': {
                        boxShadow: '0 0 0 1px var(--color-blue);',
                    },
                    '&:focus-visible': {
                        boxShadow: '0 0 0 1px var(--color-blue);',
                    }
                },
                containedPrimary: {
                    backgroundColor: 'var(--color-blue)',
                    borderColor: 'var(--color-blue)',
                    color: 'var(--color-white)',
                    minWidth: 100,
                    margin: '0 8px',
                    ":disabled": {
                        backgroundColor: 'var(--color-blue)',
                        color: 'var(--color-white)',
                        opacity: 0.5,
                    },
                    '&:last-of-type': {
                        marginRight: 0
                    },
                    '&:first-of-type': {
                        marginLeft: 0
                    },
                    '&:hover': {
                        backgroundColor: 'var(--color-darker-blue)',
                        borderColor: 'var(--color-blue)',
                        color: 'var(--color-white)',
                    },
                    '&:active': {
                        backgroundColor: 'var(--color-blue)',
                        borderColor: 'var(--color-blue)',
                        color: 'var(--color-white)',
                    },
                    '&:focus-visible': {
                        backgroundColor: 'var(--color-blue)',
                        borderColor: 'var(--color-blue)',
                        color: 'var(--color-white)',
                    }
                },
                containedSecondary: {
                    backgroundColor: 'var(--color-background)',
                    borderColor: 'var(--color-border)',
                    color: 'var(--color-black)',
                    minWidth: 100,
                    margin: '0 8px',
                    '&:last-of-type': {
                        marginRight: 0
                    },
                    '&:first-of-type': {
                        marginLeft: 0
                    },
                    '&:hover': {
                        backgroundColor: 'var(--color-grey)',
                        borderColor: 'var(--color-border)',
                        color: 'var(--color-black)',
                    },
                    '&:active': {
                        backgroundColor: 'var(--color-grey)',
                        borderColor: 'var(--color-border)',
                        color: 'var(--color-black)',
                    },
                    '&:focus-visible': {
                        backgroundColor: 'var(--color-grey)',
                        borderColor: 'var(--color-border)',
                        color: 'var(--color-black)',
                    }
                },
                containedError: {
                    backgroundColor: 'var(--color-red-light)',
                    borderColor: 'var(--color-red)',
                    color: 'var(--color-white)',
                    minWidth: 100,
                    margin: '0 8px',
                    '&:last-of-type': {
                        marginRight: 0
                    },
                    '&:first-of-type': {
                        marginLeft: 0
                    },
                    '&:disabled': {
                        backgroundColor: 'var(--color-red)',
                        opacity: 0.3,
                        pointerEvents: 'all',
                        color: 'var(--color-white)',
                    },
                    '&:hover': {
                        backgroundColor: 'var(--color-red)',
                        borderColor: 'var(--color-red)',
                        color: 'var(--color-white)',
                    },
                    '&:active': {
                        backgroundColor: 'var(--color-red)',
                        borderColor: 'var(--color-red)',
                        color: 'var(--color-white)',
                    },
                    '&:focus-visible': {
                        backgroundColor: 'var(--color-red)',
                        borderColor: 'var(--color-red)',
                        color: 'var(--color-white)',
                    }
                },
                textPrimary: {
                    borderRadius: 4,
                    border: '1px solid transparent',

                    '&:hover': {
                        backgroundColor: 'var(--color-box-shadow)'
                    },
                    '&:focus-visible': {
                        boxShadow: '0 0 0 1px var(--color-blue);',
                    }
                },
                outlined: {
                    borderRadius: 4,
                    borderColor: 'var(--color-blue)',
                    '&:hover': {
                        backgroundColor: 'var(--color-box-shadow)'
                    }
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    borderRadius: 2,
                    padding: 0,
                },
            },
        },
        MuiLink: {
            defaultProps: {
                color: 'inherit',
            },
        },
    },
});
