import React from "react";
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import {ActionButton} from "../commons/ActionButton";
import RefreshIcon from '@mui/icons-material/Refresh';
import Client from '../../cms/client';
import { useSnackbar } from 'notistack';
import styled from "@emotion/styled";


const Wrapper = styled.div`
  button {
    margin: 0;
  }

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`
const Misc = () => {
	const {enqueueSnackbar} = useSnackbar();
	const refresh = async () => {

	}


	const refreshCMSData = async () => {
		return Client.refreshCMSData()
	}

	const refreshMappings = async () => {
		return Client.refreshMappings()
	}

	const handleMiscAction = async (action) => {
		action().then(() => {
			enqueueSnackbar('Data reloaded successfully', {variant: 'success'});
		}).catch(() => {
			enqueueSnackbar('Error reloading data', {variant: 'error'});
		});
	}

	return (
		<div data-testid='feature-flags-container'>
			<div className='flex-row spaced'>
				<Typography variant={'h4'}>Misc</Typography>
				<div>
					<ActionButton variant="text" title={'Refresh'} onClick={refresh} tabIndex={0}
								  data-testid={'refresh'}>
						<RefreshIcon/>
					</ActionButton>
				</div>
			</div>
			<Divider className='mt8 mb24'/>
			<Wrapper>
				<Button variant="contained" onClick={() => handleMiscAction
					(refreshCMSData)}
				>
					Reload CMS data
				</Button>
				<Button variant="contained" onClick={() => handleMiscAction
					(refreshMappings)}
				>
					Reload Mappings
				</Button>
			</Wrapper>
		</div>
	)
}

export default Misc;
