import {useEffect} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import Client from './cms/client';
import {sessionAtom, sessionSelector} from './state/session';
import {Browser} from './utils/browser';
import {ROUTE_SERVER_ERROR, ROUTE_UNAUTHORIZED} from './utils/constants';
import {Process} from './utils/process';
import {Strings} from "./utils/strings";

// Auth component performs the login process and restores the user session if any
const Auth = () => {
    const REDIRECT_URL = 'redirectURL';
    const session = useRecoilValue(sessionAtom);
    const [restoredSession, setSession] = useRecoilState(sessionSelector);

    const handleSession = () => {
        if (restoredSession!.error) {
            Client.deleteAuthToken();
            switch (restoredSession!.error.toLowerCase()) {
                case 'unauthenticated':
                    // Save current page to get back to it once we log in
                    sessionStorage.setItem(REDIRECT_URL, window.location.pathname);
                    window.location.href = restoredSession!.redirectURL as string;
                    return;

                case 'unauthorized':
                    Browser.navigate(ROUTE_UNAUTHORIZED);
                    return false;
            }
        }

        Process.Authenticated = true


        setSession(restoredSession);
        // go back to the page the user was on before logging in
        const redirectURL = sessionStorage.getItem(REDIRECT_URL);

        if (redirectURL !== '/' && restoredSession!.isViewer()) {
            Browser.navigate('/');
            return
        }

        sessionStorage.removeItem(REDIRECT_URL);
        Browser.navigate(Strings.default(redirectURL, '/'), {replace: true});
    };

    useEffect(() => {
        if (!session) {
            if (restoredSession) {
                handleSession();
            } else {
                Process.Authenticated = false
                Browser.navigate(ROUTE_SERVER_ERROR);
            }
        } else {
            Process.Authenticated = true
        }
        // eslint-disable-next-line
    }, [session, restoredSession]);

    return null;
};

export default Auth;
