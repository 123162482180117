import React from 'react';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import GetAppIcon from '@mui/icons-material/GetApp';
import { Job } from '../../../cms/models/Job';
import { Objects } from '../../../utils/objects';
import ProgressBar from '../../commons/ProgressBar';
import { Numbers } from '../../../utils/numbers';
import Client from '../../../cms/client';
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ModeStandbyIcon from '@mui/icons-material/ModeStandby';
import styled from '@emotion/styled';

export interface JobAction {
	name: string;
	icon: any;
	action: () => Promise<any>;
}

export interface Config {
	interval?: string;
	schedule?: string;
	singleInstance: boolean;
	status?: (data: string) => any;
	actions: JobAction[];
}

const status = (data: string) => {
	let res: any = null;
	try {
		const d = JSON.parse(data);
		res = [
			<div key={1}>
				<label>Total</label> {d.total}
			</div>,
			<div key={2}>
				<label>Processed</label> {d.done}
			</div>,
			<div key={3}>
				<label>Errors</label> {d.errors}
			</div>,
			<div key={4}>
				<ProgressBar label={d.step} total={Numbers.default(d.total)}
							 height={'16px'} width={'800px'}
							 done={Numbers.default(d.done)} errors={Numbers.default(d.errors)} />
			</div>,
		];
	} catch (e) {
	}
	return res;
};

const Trigger = styled.span`
    display: inline-flex;
    align-items: center;
    align-content: center;

    svg {
        height: 12px;
        margin-bottom: -2px;
        width: 16px;
    }
`;

export const getTrigger = (job: Job): any => {
	const cfg = Objects.default(JobConfig[job.name]);
	if (!cfg.schedule && !cfg.interval) {
		return <Trigger><ModeStandbyIcon /> Manual</Trigger>;
	}
	if (cfg.interval) {
		return <Trigger><AccessTimeIcon /> Interval - {cfg.interval} </Trigger>;
	}
	if (cfg.schedule) {
		return <Trigger><EventIcon /> Schedule - {cfg.schedule}</Trigger>;
	}
	return 'Unknown';
};

export const JobConfig: {[key: string]: Config} = {
	'EventsAIR': {
		interval: '2h',
		singleInstance: true,
		actions: [
			{
				name: 'Trigger',
				icon: <PlayCircleOutlineIcon />,
				action: () => {
					return Client.runCMD('triggerEventsJob');
				},
			},
		],
	},
	'ExpiredTrialAccounts': {
		singleInstance: true,
		schedule: '@midnight',
		actions: [
			{
				name: 'Trigger',
				icon: <PlayCircleOutlineIcon />,
				action: () => {
					return Client.runCMD('triggerExpiredTrialAccountsJob');
				},
			},
		],
	},
	'LinksCheck': {
		singleInstance: true,
		schedule: 'Saturday 1AM',
		status: status,
		actions: [
			{
				name: 'Download report',
				icon: <GetAppIcon />,
				action: () => {
					return Client.getLinksReport();
				},
			},
			{
				name: 'Trigger',
				icon: <PlayCircleOutlineIcon />,
				action: () => {
					return Client.runCMD('triggerLinkCheckJob');
				},
			},
		],
	},
	'LinksReindex': {
		singleInstance: true,
		status: status,
		actions: [
			{
				name: 'Download report',
				icon: <GetAppIcon />,
				action: () => {
					return Client.getLinksReport();
				},
			},
			{
				name: 'Trigger',
				icon: <PlayCircleOutlineIcon />,
				action: () => {
					return Client.runCMD('triggerReindexLinksJob');
				},
			},
		],
	},
	'InternationalProductData': {
		singleInstance: true,
		schedule: '@midnight',
		actions: [
			{
				name: 'Trigger',
				icon: <PlayCircleOutlineIcon />,
				action: () => {
					return Client.runCMD('triggerIntProductDataJob');
				},
			},
		],
	},
	'OCRProductData': {
		singleInstance: true,
		schedule: '@midnight',
		actions: [
			{
				name: 'Trigger',
				icon: <PlayCircleOutlineIcon />,
				action: () => {
					return Client.runCMD('triggerOCRProductDataJob');
				},
			},
		],
	},
	'ZendeskOCR': {
		interval: '2h',
		singleInstance: true,
		actions: [
			{
				name: 'Trigger',
				icon: <PlayCircleOutlineIcon />,
				action: () => {
					return Client.runCMD('triggerZendeskJobOCR');
				},
			},
		],
	},
	'ZendeskInternational': {
		interval: '2h',
		singleInstance: true,
		actions: [
			{
				name: 'Trigger',
				icon: <PlayCircleOutlineIcon />,
				action: () => {
					return Client.runCMD('triggerZendeskJobCI');
				},
			},
		],
	},
	'OCRSearchIndex': {
		singleInstance: true,
		status: status,
		actions: [
			{
				name: 'Trigger',
				icon: <PlayCircleOutlineIcon />,
				action: () => {
					return Client.runCMD('rebuildOCRIndex');
				},
			},
		],
	},
	'InternationalSearchIndex': {
		singleInstance: true,
		status: status,
		actions: [
			{
				name: 'Trigger',
				icon: <PlayCircleOutlineIcon />,
				action: () => {
					return Client.runCMD('rebuildInternationalIndex');
				},
			},
		],
	},
	'OCRCacheRefresh': {
		singleInstance: false,
		interval: '2h',
		status: status,
		actions: [
			{
				name: 'Trigger',
				icon: <PlayCircleOutlineIcon />,
				action: () => {
					return Client.runCMD('ocrCacheRefresh');
				},
			},
		],
	},
	'OCRSubjectUpdate': {
		singleInstance: true,
		interval: '1m',
		status: status,
		actions: [],
	},
	'InternationalCacheRefresh': {
		singleInstance: false,
		interval: '2h',
		status: status,
		actions: [
			{
				name: 'Trigger',
				icon: <PlayCircleOutlineIcon />,
				action: () => {
					return Client.runCMD('internationalCacheRefresh');
				},
			},
		],
	},
};
