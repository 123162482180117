import moment from 'moment-timezone';
import {fi} from './helpers';
import {useLocalStorage} from "./hooks";

export const dateOptions: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
};

export enum timeOptions {
    AM = 'Morning',
    PM = 'Afternoon',
}

const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const Dates = {
    TimeZoneOption: 'TimeZoneOption',
    TimeZoneDefault: 'Europe/London',
    DefaultDateFormat: 'en-GB',

    setTimeZone: (value: string) => {
        if (!value) {
            localStorage.removeItem(Dates.TimeZoneOption);
        } else {
            localStorage.setItem(Dates.TimeZoneOption, value)
        }
    },

    getTimeZone: () => {
        return (localStorage.getItem(Dates.TimeZoneOption) || Dates.TimeZoneDefault).replace(/"/g, '');
    },

    useTimeZone: () => {
        return useLocalStorage(Dates.TimeZoneOption, Dates.TimeZoneDefault)
    },

    monthDiff: (d1: Date, d2: Date): number => {
        let months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        return months <= 0 ? 0 : months;
    },

    local: (value: string | Date): string => {
        if (!value) {
            return '';
        }
        return fi(typeof value === 'string', new Date(value), value).toLocaleString(Dates.DefaultDateFormat, {
            ...dateOptions,
            timeZone: Dates.getTimeZone(),
        })
    },

    localDate: (value: string | Date, format = Dates.DefaultDateFormat): string => {
        if (!value) {
            return ''
        }
        return fi(typeof value === 'string', new Date(value), value).toLocaleDateString(format, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            timeZone: Dates.getTimeZone(),
        });
    },

    localTime: (value: string | Date, format = Dates.DefaultDateFormat): string => {
        if (!value) {
            return ''
        }
        return fi(typeof value === 'string', new Date(value), value).toLocaleTimeString(format, {
            hour: '2-digit',
            minute: '2-digit',
            timeZone: Dates.getTimeZone(),
        });
    },

    revertToUTC: (value: string): string => {
        if (!value) {
            return ''
        }

        return moment.tz(value, Dates.getTimeZone()).utc().format()
    },

    onlyDate: (date: string | Date) => {
        if (!date) {
            return '';
        }
        const d = fi(typeof date === 'string', new Date(date), date);
        if (d.toString() === 'Invalid Date') {
            return ''
        }
        return Dates.localDate(d, "en-US")
    },

    onlyTime: (date: string | Date) => {
        if (!date) {
            return '';
        }
        const d = fi(typeof date === 'string', new Date(date), date);
        if (d.toString() === 'Invalid Date') {
            return ''
        }
        return Dates.localTime(d, "en-US");
    },

    timeAgo: (val: string | Date | number): string => {
        const now = +(new Date());
        let time: number = 0;
        switch (typeof val) {
            case 'number':
                time = (new Date(val)).getTime();
                break;
            case 'string':
                if (!val) {
                    return ''
                }
                time = +new Date(val);
                if (isNaN(time)) {
                    return ''
                }
                break;
            case 'object':
                if (val.constructor === Date) {
                    time = val.getTime();
                }
                break;
            default:
                time = +new Date();
        }
        if ((new Date(time)).getFullYear() === 1) {
            return '';
        }

        const timeFormats = [
            [60, 'seconds', 1], // 60
            [120, '1 minute ago', '1 minute from now'], // 60*2
            [3600, 'minutes', 60], // 60*60, 60
            [7200, '1 hour ago', '1 hour from now'], // 60*60*2
            [86400, 'hours', 3600], // 60*60*24, 60*60
            [172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
            [604800, 'days', 86400], // 60*60*24*7, 60*60*24
            [1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
            [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
            [4838400, 'Last month', 'Next month'], // 60*60*24*7*4*2
            [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
            [58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
            [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
            [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
            [58060800000, 'centuries', 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
        ];
        let seconds = (now - (time as any)) / 1000;
        let token = 'ago';
        let listChoice = 1;

        if (Math.floor(seconds) === 0) {
            return 'Just now';
        }
        if (seconds < 0 || Math.floor(seconds) === 0) {
            seconds = Math.abs(seconds);
            token = 'from now';
            listChoice = 2;
        }
        let i = 0;
        let format = timeFormats[i++];
        let result = time.toString()

        while (format) {
            if (seconds < format[0]) {
                if (typeof format[2] === 'string') {
                    result = format[listChoice].toString();
                } else {
                    result = Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token;
                }
                break
            }
            format = timeFormats[i++];
        }

        return result
    },

    format(value: string | Date, weekday: boolean = false, hideYear: boolean = false): string {
        if (!value) {
            return '';
        }
        const date = new Date(value);
        return (`${fi(weekday, weekdays[date.getDay()] + ' ', '')}` +
                `${date.getDate()} ${date.toLocaleString('default', {month: 'long'})} ` +
                `${fi(hideYear, '', date.getFullYear())}`).trim();
    },

    withTimeZone: (year, month, day, hour, minute, second) => {
        let date = new Date(Date.UTC(year, month, day, hour, minute, second));

        let utcDate = new Date(date.toLocaleString('en-US'));
        let tzDate = new Date(date.toLocaleString('en-US', {timeZone: Dates.getTimeZone()}));
        let offset = utcDate.getTime() - tzDate.getTime();

        date.setTime(date.getTime() + offset);

        return date;
    },

    getMonth(date: Date): string {
        return months[date.getMonth()];
    },
}

export const TIMEZONES: string[] = [
    'Africa/Cairo',
    'Africa/Lagos',
    'Africa/Tripoli',
    'Africa/Tunis',
    'America/Chicago',
    'America/Denver',
    'America/Detroit',
    'America/Los_Angeles',
    'America/Mexico_City',
    'America/New_York',
    'America/Phoenix',
    'America/Vancouver',
    'Asia/Bangkok',
    'Asia/Dubai',
    'Asia/Hong_Kong',
    'Asia/Jakarta',
    'Asia/Jerusalem',
    'Asia/Kuala_Lumpur',
    'Asia/Qatar',
    'Asia/Seoul',
    'Asia/Shanghai',
    'Asia/Singapore',
    'Asia/Taipei',
    'Atlantic/Bermuda',
    'Atlantic/Canary',
    'Atlantic/Cape_Verde',
    'Atlantic/Faroe',
    'Atlantic/South_Georgia',
    'Atlantic/Stanley',
    'Australia/Adelaide',
    'Australia/Brisbane',
    'Australia/Melbourne',
    'Australia/Sydney',
    'Europe/Amsterdam',
    'Europe/Andorra',
    'Europe/Athens',
    'Europe/Belgrade',
    'Europe/Berlin',
    'Europe/Brussels',
    'Europe/Bucharest',
    'Europe/Budapest',
    'Europe/Chisinau',
    'Europe/Copenhagen',
    'Europe/Dublin',
    'Europe/Gibraltar',
    'Europe/Helsinki',
    'Europe/Istanbul',
    'Europe/Kiev',
    'Europe/Lisbon',
    'Europe/London',
    'Europe/Luxembourg',
    'Europe/Madrid',
    'Europe/Malta',
    'Europe/Minsk',
    'Europe/Monaco',
    'Europe/Moscow',
    'Europe/Oslo',
    'Europe/Paris',
    'Europe/Prague',
    'Europe/Rome',
    'Europe/Sofia',
    'Europe/Stockholm',
    'Europe/Vienna',
    'Europe/Vilnius',
    'Europe/Warsaw',
    'Europe/Zurich',
    'Indian/Maldives',
    'Indian/Mauritius',
    'Indian/Reunion',
    'Pacific/Fiji',
    'Pacific/Galapagos',
    'Pacific/Guam',
    'Pacific/Honolulu',
];
