import React, { useContext, useMemo } from 'react';
import { Field, FieldType } from '../../../cms/models/__ModelInfo';
import { useRecoilValue } from 'recoil';
import { useFormField } from '../state';
import styled from '@emotion/styled';
import SelectComponent, { ISelectValue } from './components/Select/SelectComponent';
import { FormContext } from '../Form';
import { pageIconSelector } from '../../../state/state';

const Wrapper = styled.div`
    .default-icon {
        filter: invert(88%) sepia(0%) saturate(0%) hue-rotate(222deg) brightness(95%) contrast(90%);
    }


    div[class*='-menu'] {
        width: auto;
    }

    div[id*='listbox'] {
        width: 300px;
        max-height: 300px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: flex-start;
        align-content: flex-start;
        overflow: auto;
        
        & > div {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            gap: 8px;
            width: auto;
            justify-content: flex-start;
            align-content: flex-start;

            & > div {
                width: auto;
            }
        }
    }
`

const IconListRenderer = ({field}: { field: Field }) => {
    const context = useContext(FormContext);
    const formField = useFormField(field.uid)
    const icons = useRecoilValue(pageIconSelector)

    const options: ISelectValue[] = useMemo(() => {
        return icons.map(icon => ({value: icon, label: icon, object: icon}))
    }, [icons])

    const handleChange = (value: any) => {
        if (value) {
            formField.setValue(value.value)
        } else {
            formField.setValue('')
        }
    }

    return (
        <Wrapper>
            <label>Icon</label>
            <SelectComponent
                dataTestId={`${context.id}-${field.uid}-field`}
                label={''}
                fieldType={FieldType.IconList}
                required={field.flags.required}
                id={field.uid}
                value={formField.value}
                values={options}
                touched={formField.touched}
                errorMessage={formField.error}
                showError={formField.showError()}
                isClearable={true}
                readonly={formField.previewMode}
                onChange={handleChange}
            />
        </Wrapper>
    )
}

export default IconListRenderer