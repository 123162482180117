import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from '@emotion/styled';
import {Button} from '@mui/material';
import {useRecoilValue} from 'recoil';
import {getImagePath} from '../../../../../utils/helpers';
import {sessionAtom} from '../../../../../state/session';
import Form from '../../../Form';
import {TileContent} from '../../../../../cms/models/_TileContent';
import {useForm} from '../../../state';
import {RenderFormFields} from '../../../../Form/utils';

const ActionButtons = styled.div`
	display: flex;
	justify-content: end;
`;

const TileContentRows = (
	props: any & {onClose: () => void; onSave: () => void},
) => {
	const session = useRecoilValue(sessionAtom);
	const form = useForm();

	const fields = useMemo(() => {
		return RenderFormFields(form.state);
	}, [form]);

	const handleConfirm = useCallback(async () => {
		props.setError('');

		const {image, title, description, alternativeText, link} =
			form.state.values;

		form.validate();

		if (image && title && alternativeText && link) {
			const imagePath = getImagePath(
				image,
				session?.selectedBusinessStream,
			);
			props.onSave({imagePath, title, description, alternativeText, link});
		}
	}, [form]);

	return (
		<>
			{fields}
			<ActionButtons>
				<Button
					variant="text"
					color="warning"
					onClick={() => props.onClose()}
				>
					Cancel
				</Button>
				<Button
					variant="contained"
					color="primary"
					onClick={() => handleConfirm()}
				>
					Save
				</Button>
			</ActionButtons>
		</>
	);
};

const TileContentComponent = (props) => {
	const {onClose, onSave} = props;

	useEffect(() => {
		props.setTitle('Create Card/s?');
		// eslint-disable-next-line
	}, [props]);

	const formId = 'tile-content-component';

	return (
		<Form model={TileContent.model} id={formId}>
			<TileContentRows {...props} onClose={onClose} onSave={onSave} />
		</Form>
	);
};

export default TileContentComponent;
