import styled from "@emotion/styled";
import {Numbers} from "../../utils/numbers";
import React, { useMemo } from 'react';
import {ReactComponent as DOCIcon} from '../../assets/images/DOC.svg';
import {ReactComponent as ImageIcon} from '../../assets/images/IMAGE.svg';
import {ReactComponent as PDFIcon} from '../../assets/images/PDF.svg';
import {ReactComponent as PPTIcon} from '../../assets/images/PPT.svg';
import {ReactComponent as XLSIcon} from '../../assets/images/XLS.svg';
import InsertDriveFile from '@mui/icons-material/InsertDriveFile';
import {selector, useRecoilValue} from "recoil";
import Client from "../../cms/client";

const statsSelector = selector<any>({
    key: 'statsSelector',
    get: async () => {
        return await Client.stats() as any
    }
})

const Stats = () => {
    const stats = useRecoilValue(statsSelector)
    const otherSize = useMemo(() => {
        return Numbers.default(stats.all_size) - (Numbers.default(stats.doc_size) + Numbers.default(stats.img_size) +
            Numbers.default(stats.pdf_size) + Numbers.default(stats.ppt_size) + Numbers.default(stats.xls_size));
    }, [stats])

    const otherCount = useMemo(() => {
        return Numbers.default(stats.all_total) - (Numbers.default(stats.doc_total) + Numbers.default(stats.img_total) +
            Numbers.default(stats.pdf_total) + Numbers.default(stats.ppt_total) + Numbers.default(stats.xls_total));
    }, [stats]);

    return (
        <FileStats>
            <tbody>
            <tr>
                <td><DOCIcon/></td>
                <td>{Numbers.default(stats.doc_total).toLocaleString()} files</td>
                <td>{Numbers.asFileSize(stats.doc_size)}</td>
            </tr>
            <tr>
                <td><XLSIcon/></td>
                <td>{Numbers.default(stats.xls_total).toLocaleString()} files</td>
                <td>{Numbers.asFileSize(stats.xls_size)}</td>
            </tr>
            <tr>
                <td><PPTIcon/></td>
                <td>{Numbers.default(stats.ppt_total).toLocaleString()} files</td>
                <td>{Numbers.asFileSize(stats.ppt_size)}</td>
            </tr>
            <tr>
                <td><ImageIcon/></td>
                <td>{Numbers.default(stats.img_total).toLocaleString()} files</td>
                <td>{Numbers.asFileSize(stats.img_size)}</td>
            </tr>
            <tr>
                <td><PDFIcon/></td>
                <td>{Numbers.default(stats.pdf_total).toLocaleString()} files</td>
                <td>{Numbers.asFileSize(stats.pdf_size)}</td>
            </tr>
            <tr>
                <td><InsertDriveFile/></td>
                <td>{otherCount.toLocaleString()} files</td>
                <td>{Numbers.asFileSize(otherSize)}</td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
                <td>Total</td>
                <td>{Numbers.default(stats.all_total).toLocaleString()}</td>
                <td>{Numbers.asFileSize(stats.all_size)}</td>
            </tr>
            </tfoot>
        </FileStats>
    )
}

const FileStats = styled.table`
  border-collapse: separate;
  border-spacing: 0 6px;

  tr > td:not(:first-of-type) {
    color: var(--color-monochrome);
  }

  tfoot {
    td {
      border-top: 1px solid var(--color-border-light);
      font-size: 16px;
      font-weight: 600;
      color: var(--color-monochrome);
    }
  }

  th {
    font-weight: bold;
  }

  td {
    height: 27px;
    vertical-align: middle;
    padding: 0 16px;

    &:first-of-type {
      padding: 0 16px;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }

  td:last-of-type, td:nth-last-of-type(2) {
    text-align: right;
  }
`

export default Stats;