import React, {Suspense, useContext, useEffect, useMemo} from 'react';
import {Field} from '../../../cms/models/__ModelInfo';
import SelectComponent, {ISelectValue} from './components/Select/SelectComponent';
import {useFormField} from '../state';
import {FormContext} from '../Form';
import Client from '../../../cms/client';
import {useSnackbar} from 'notistack';
import {Lists} from '../../../utils/lists';
import {StrictModeDroppable} from '../../../utils/helpers';
import {Strings} from '../../../utils/strings';
import styled from '@emotion/styled';
import Spacer from '../../commons/Spacer';
import CloseRounded from '@mui/icons-material/CloseRounded';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import TextAction from '../../commons/TextAction';
import {Draggable} from 'react-beautiful-dnd';
import {CMSObject, DisplayMode} from '../../../cms/models/__CMSObject';
import {Types} from '../../../cms/types';
import {selectorFamily, useRecoilState, useRecoilValue} from 'recoil';
import {ErrorBoundary} from 'react-error-boundary';
import {cacheBuster, dragAndDropState} from "../../../state/state";

export const MultipleOptionsWrapper = styled.div`
  border-top: 1px solid var(--color-border);
  border-bottom: 1px solid var(--color-border);
  background-color: white;
  padding: 8px 0;
  margin-top: 8px;
`;

const SelectionList = styled.div`
  max-height: 200px;
  overflow-y: auto;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 16px;
  cursor: default;

  &:hover {
    background-color: var(--color-box-shadow);
  }

  svg {
    color: var(--color-border);
    margin-top: 3px;
    width: 16px;
    height: 16px;
    transition: color 0.2s ease-in-out;
  }

  span:first-of-type {
    cursor: grab;
    margin-right: 3px;
  }

  .remove {
    cursor: pointer;

    &:hover svg {
      color: var(--color-red);
    }
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 4px 8px 34px;
`;

const listValuesSelector = selectorFamily<ISelectValue[], string[]>({
    key: 'listValuesSelector',
    get: (models) => async ({get}) => {
        let promise: Promise<any>;

        get(cacheBuster(models.join(',')))

        if (models.length === 1 && models[0] === Types.EVENT) {
            promise = Client.events().then(list => {
                Lists.sort(list, 'label');
                return list;
            });
        } else {
            promise = Client.query<CMSObject>({
                references: false,
                types: models,
                limit: -1,
            }).then(res => {
                // Load and map the dataset to a ISelectValue list
                const list = res.results.map(i => ({
                    value: i.getId(),
                    label: i.displayLabel(DisplayMode.DETAILED),
                    object: i,
                }));

                Lists.sort(list, 'label');
                return list;
            });
        }

        return promise;

        // promise.catch((err) => {
        //     enqueueSnackbar(`Error loading data for ${field.name}: ${err.errorMessage}`, {variant: 'error'})
        // }).finally(() => {
        //     setLoading(false);
        // })
    },
});

const ReferenceRendererContent = ({field}: { field: Field }) => {
    const context = useContext(FormContext);
    const formField = useFormField(field.uid);

    const [dragAndDrop, setDragAndDrop] = useRecoilState(dragAndDropState);

    const models = useMemo(() => {
        if (formField.field.config.refModels && formField.field.config.refModels.length) {
            return formField.field.config.refModels;
        }
        return [formField.field.config.refModel];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [field]);

    const allOptions = useRecoilValue<ISelectValue[]>(listValuesSelector(models));

    const [filteredOptions, setFilteredOptions] = React.useState<ISelectValue[]>([]);

    useEffect(() => {

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setFilteredOptions(formField.filterDataset(allOptions));
    }, [allOptions, formField]);

    const options = useMemo(() => {
        if (field.flags.multiple) {
            return filteredOptions.filter(f => !Lists.default(formField.value).includes(f.value));
        }
        return filteredOptions;
    }, [filteredOptions, field, formField]);

    const handleChange = (newValue, _id) => {
        if (field.flags.multiple) {
            if (newValue == null) {
                formField.setValue([])
            } else {
                formField.setValue([...Lists.default(formField.value), newValue.value]);
            }
        } else {
            if (newValue == null) {
                formField.setValue('');
            } else {
                formField.setValue(newValue.value);
            }
        }
    };

    const removeOption = (index) => {
        const newValue = [...formField.value];
        newValue.splice(index, 1);
        formField.setValue(newValue);
    };

    const value = useMemo(() => {
        if (filteredOptions.length === 0) {
            return '';
        }

        if (field.flags.multiple) {
            return Lists.default(formField.value).map(i => {
                return filteredOptions.map(v => [v, ...Lists.default(v.options)]).flat().find((j: any) => j.value === i)
            }).filter(a => a);
        } else {
            return Strings.default(formField.value);
        }
    }, [formField.value, field, filteredOptions]);

    useEffect(() => {
        if (!dragAndDrop || dragAndDrop.type !== `${field.uid}-option-widgets`) {
            return;
        }
        const {destination, source} = dragAndDrop;
        if (!destination) {
            return;
        }
        if (!field.flags.multiple) {
            return;
        }
        // same position
        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        const newOrder: any[] = [...Lists.default(formField.value)];
        const elem = newOrder.splice(source.index, 1);
        newOrder.splice(destination.index, 0, elem[0]);
        formField.setValue(newOrder);
        setDragAndDrop(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dragAndDrop]);

    return (
        <>
            {!Boolean(field.flags.hideSelection) && <SelectComponent
                dataTestId={`${context.id}-${field.uid}-field`}
                label={field.name}
                required={field.flags.required || field.flags.dummyRequired}
                id={field.uid}
                value={value}
                fieldType={field.fieldtype}
                portal={document.getElementById("list-portal-wrapper")}
                values={options}
                touched={formField.touched}
                errorMessage={formField.error}
                showError={formField.showError()}
                isClearable={true}
                readonly={formField.previewMode}
                onChange={handleChange}
            />}

            {(field.flags.multiple && value.length > 0 && !Boolean(field.flags.hideValues)) && (
                <>
                    <MultipleOptionsWrapper>
                        {/*<DragDropContext onDragEnd={onDragEnd}>*/}
                        <StrictModeDroppable droppableId='droppable' type={`${field.uid}-option-widgets`}>
                            {(provided) => (
                                <SelectionList {...provided.droppableProps} ref={provided.innerRef}>
                                    {Lists.default<ISelectValue>(value).map((i, idx) => (
                                        <Draggable draggableId={i.value} index={idx} key={i.value}>
                                            {(provided) => (
                                                <Row key={idx}
                                                     ref={provided.innerRef} {...provided.draggableProps} >
                                                    <span {...provided.dragHandleProps}><DragIndicatorIcon/></span>
                                                    <span>{i.object.displayLabel(DisplayMode.DETAILED)}</span>
                                                    <Spacer/>
                                                    <span tabIndex={0} className='remove'
                                                          onClick={() => removeOption(idx)}><CloseRounded/></span>
                                                </Row>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </SelectionList>
                            )}
                        </StrictModeDroppable>
                        {/*</DragDropContext>*/}
                    </MultipleOptionsWrapper>
                    {!Boolean(field.flags.noClear) && (
                        <Actions>
                            <span>{value.length} out of {filteredOptions.length}</span>
                            <Spacer/>
                            <TextAction onClick={() => formField.setValue([])}>Clear all</TextAction>
                        </Actions>
                    )}
                </>
            )}
        </>
    );
};

const ErrorComponent = ({error, resetErrorBoundary}) => {
    const {enqueueSnackbar} = useSnackbar();
    useEffect(() => {
        enqueueSnackbar('Error loading data', {variant: 'error'});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            Error loading data: {error}. <TextAction onClick={resetErrorBoundary}>Retry</TextAction>
        </>
    );
};

const ReferenceRenderer = ({field}: { field: Field }) => {
    const context = useContext(FormContext);
    const formField = useFormField(field.uid);

    const fallbackComponent = useMemo(() => {
        if (field.flags.hideSelection) {
            if (field.flags.hideValues) {
                return <></>;
            } else {
                return <>Loading....</>;
            }
        } else {
            return <SelectComponent
                dataTestId={`${context.id}-${field.uid}-field`}
                label={field.name}
                portal={document.getElementById("list-portal-wrapper")}
                required={field.flags.required}
                id={field.uid}
                fieldType={field.fieldtype}
                value={''}
                loading={true}
                values={[]}
                isClearable={true}
                readonly={formField.previewMode}
                onChange={() => {
                }}
            />;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [field]);

    return (
        <>
            <ErrorBoundary FallbackComponent={ErrorComponent}>
                <Suspense fallback={fallbackComponent}>
                    <ReferenceRendererContent field={field}/>
                </Suspense>
            </ErrorBoundary>
        </>
    );
};

export default ReferenceRenderer;