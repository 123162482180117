import styled from '@emotion/styled';

const ListCheckbox = styled.span`
  &.checkbox-img-unchecked, &.checkbox-img-checked, &.checkbox-img-intermediate {
    display: block;
    line-height: 38px;

    &:before {
      content: "";
      background-repeat: no-repeat;
      background-size: 18px 18px;
      background-position-x: center;
      background-position-y: center;
      width: 24px;
      height: 38px;
      cursor: pointer;
      padding-left: 30px;
    }
  }

  &.checkbox-img-unchecked:before {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIZJREFUeNpiZACBkr0CQDIBiAUYqAM+APEChh7nD4xQw/cDsQEDdcEFIHZkgrqc2oYzQM1MYEELlkKozZQa3A9lC7BgeKvH+QBFxpfsReEyMdAYjFowasGoBaMWjFpADcCCUVmgledkVjgoFnxA4vdT2QMfmMC1P+XVJK5KfwEjrZstAAEGACbGGyc6yLKfAAAAAElFTkSuQmCC);
  }

  &.checkbox-img-checked:before {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAPhJREFUeNq0loENgyAQAF/iAI7iCG0cRNygJoxh4giyCLEjOAoj9Ck0BcFW8f3kjZh4pz4PFmBCqAqPHLMCmtCYEoZGFw4+Y9ZAGwvmnbknp4aDY/Jy9Vl6Zz4LHt15VUavNTTPU3ihgiGDi4NGINSIWV8jEGrC4+M9ExMSRgDnn4JitnSCEA6usXoaQRre7a+B+ZZC8bPwtMAWyiwdUyQ5CN96g9br7q8kA54W2EJJ74qRzDnw7RrYm33JLQf+exbFksPw/9M0lByG7+sDC+1y4PsbbWhkbsOX0WaxWs8zN5xAoL3xSLwdaOaKuAB9GKYsrv5teQkwAH7lWScXifqsAAAAAElFTkSuQmCC);
  }

  &.checkbox-img-intermediate:before {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAAxQTFRFgbrfAXW9xN7v////XcKp6wAAAAR0Uk5T////AEAqqfQAAABFSURBVHjazJIxCgAwCAPT5P9/bgsOViJ09NZzOSJEGChwWc65F7gCLCAEVeAQkasfkdv+RO6eE+hFO5Sftn2G7n22AAMAhOAD3V6Bzy4AAAAASUVORK5CYII=);
  }
`

export default ListCheckbox;