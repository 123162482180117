import GenericModal from "./GenericModal"
import React, {useCallback, useMemo, useState} from "react";
import Button from '@mui/material/Button';
import {atom, useRecoilState} from "recoil";
import {cancelEvent, fi} from "../../utils/helpers";
import {useHotkeys} from "react-hotkeys-hook";
import Loader from "../Loader/Loader";
import {Lists} from "../../utils/lists";
import styled from "@emotion/styled";
import {Strings} from "../../utils/strings";

const ErrorMessage = styled.div`
  color: var(--color-red);
`

export type ModalActions = {
    type: 'ok' | 'cancel' | 'custom'
    hint: string;
    label: string;
    color: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
    hidden?: boolean;
    disabled?: boolean;
}

export type ModalWrapperProps = {
    key: number,
    // Set a title for the modal
    setTitle: (title: string) => void,
    // Register a callback to be called when the modal is closed from the Cancel button or the X button.
    whenCancel: (callback: () => void) => void,
    // Register a callback to be called when the modal confirmation button is pressed
    // The button will switch to loading state until the callback promise is resolved
    // If the callback promise is rejected, the modal will display the error message and not close the modal
    // If the callback promise is resolved, the modal will close
    whenConfirm: (callback: () => Promise<any>) => void,
    // Call this method to configure the modal action buttons if needed.
    // By default, there are two buttons: Cancel (secondary), OK (primary)
    setActions: (...actions: ModalActions[]) => void,
    setError: (error: string) => void,
}


export const modalAtom = atom<{ width?: number, height?: number, footer?:React.ReactNode, component: React.FC<ModalWrapperProps> } | null>({
    key: 'editCreateModalAtom',
    default: null,
});

let key: number = 0;

const DefaultConfirmationButton: ModalActions = {
    type: 'ok',
    hidden: false,
    label: 'Ok',
    disabled: false,
    hint: 'Ok',
    color: 'primary',
}

const DefaultCancelButton: ModalActions = {
    type: 'cancel',
    hidden: false,
    label: 'Cancel',
    disabled: false,
    hint: 'Cancel',
    color: 'secondary',
}

// Modal wrapper component displays a modal dialog where the body is populated by setting a component in the `modalAtom`
// The component should take ModalWrapperProps and use the provided methods to interact and be notified by the modal wrapper when
// things happen
const ModalWrapper = () => {
    const [item, setItem] = useRecoilState(modalAtom);
    const [title, setTitle] = useState('');

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const [childCancel, setChildCancel] = useState<(() => void) | null>(null);
    const [childConfirm, setChildConfirm] = useState<(() => Promise<void>) | null>(null);

    const [okButton, setOkButton] = useState<ModalActions>({...DefaultConfirmationButton});
    const [cancelButton, setCancelButton] = useState<ModalActions>({...DefaultCancelButton});

    const onCancel = useCallback((evt) => {
        cancelEvent(evt);
        if (!item) return;
        if (childCancel) childCancel();
        setItem(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [childCancel, item]);

    const onConfirm = useCallback(async (evt) => {
        cancelEvent(evt);
        if (!item) return;
        if (childConfirm) {
            setLoading(true)
            childConfirm().then(() => {
                setItem(null)
            }).catch((err) => {
                setError(err.message)
            }).finally(() => {
                setLoading(false)
            })
        } else {
            setItem(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [childConfirm, item]);

    const setActions = useCallback((...actions: Partial<ModalActions>[]) => {
        Lists.default<ModalActions>(actions).forEach((action) => {
            switch (action.type) {
                case 'ok':
                    setOkButton((button) => ({...button, ...action}));
                    break;
                case 'cancel':
                    setCancelButton((button) => ({...button, ...action}));
                    break;
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const component = useMemo(() => {
        // reset state if there's no item to show
        if (!item) {
            setError('')
            setOkButton({...DefaultConfirmationButton})
            setCancelButton({...DefaultCancelButton})
            return null;
        }

        // render item
        return item.component({
            key: key++,
            setTitle: (title: string) => setTitle(title),
            whenCancel: (callback) => setChildCancel(() => callback),
            whenConfirm: (callback) => setChildConfirm(() => callback),
            setActions,
            setError,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item, setActions])

    useHotkeys('enter', onConfirm);
    useHotkeys('esc', onCancel);

    if (!item) return null;

    return (
        <GenericModal
            onClose={onCancel}
            title={title}
            width={item.width}
            height={item.height}
            footer={typeof item.footer !== 'undefined' ?item.footer:(
                <div className='flex-row spaced'>
                    <ErrorMessage>
                        {fi(error, `${Strings.default(error)}`, ' ')}
                    </ErrorMessage>
                    <div className='flex-row'>
                        {!Boolean(cancelButton.hidden) && (
                            <Button variant="contained"
                                    color={cancelButton.color}
                                    disabled={Boolean(cancelButton.disabled)}
                                    onClick={onCancel}
                                    title={cancelButton.hint}>
                                {cancelButton.label}
                            </Button>
                        )}
                        {!Boolean(okButton.hidden) && (
                            <Button variant="contained"
                                    color={okButton.color}
                                    onClick={onConfirm}
                                    disabled={loading || Boolean(okButton.disabled)}
                                    title={fi(loading, 'Loading, please wait....', okButton.hint)}>
                                {fi(loading, <Loader inverted={true} size={18}/>, okButton.label)}
                            </Button>
                        )}
                    </div>
                </div>
            )}>
            {component}
        </GenericModal>
    );
}

export default ModalWrapper;
