import styled from '@emotion/styled';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, {useEffect} from "react";
import FeatureFlags from '../../components/ControlPanel/FeatureFlags';
import Jobs from '../../components/ControlPanel/Jobs';
import Misc from '../../components/ControlPanel/Misc';
import APIKeys from '../../components/ControlPanel/APIKeys';
import SearchIndex from '../../components/ControlPanel/SearchIndex';
import Status from '../../components/ControlPanel/Status';
import {TabPanel} from "./Tabs";
import {Browser} from "../../utils/browser";
import {ROUTE_CONTROL_PANEL} from "../../utils/constants";
import {useParams} from "react-router-dom";
import {Strings} from "../../utils/strings";
import Assets from '../../components/ControlPanel/Assets';

const Wrapper = styled(Paper)`
  height: 100%;
  display: flex;
`;

const TabButton = styled(Tab)`
  align-items: flex-start;
  text-transform: none;
  font-family: var(--font-semi-bold);
  min-width: 250px;
`


// ControlPanelComponent is the page wrapper for the control panel section
// All the subcomponents used here are defined in /components/ControlPanel
const ControlPanelComponent = () => {
    const tabs = ['status', 'flags', 'search', 'jobs','assets', 'misc']
    const {page} = useParams()
    const [value, setValue] = React.useState(tabs.indexOf(Strings.default(page, 'status')));

    useEffect(() => {
        if (!page) {
            Browser.navigate(ROUTE_CONTROL_PANEL.replace(':page', "status"))
            setValue(0)
        } else {
            setValue(tabs.indexOf(Strings.default(page, 'status')))
        }
        // eslint-disable-next-line
    }, [page])

    const a11yProps = (index: number) => {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    }
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        Browser.navigate(ROUTE_CONTROL_PANEL.replace(':page', tabs[newValue]))
    };

    return (
        <Wrapper>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                aria-label='Control panel sections'
                onChange={handleChange}
            >
                <TabButton label="Status" {...a11yProps(0)}  />
                <TabButton label="Feature flags" {...a11yProps(1)} />
                <TabButton label="Search index" {...a11yProps(2)} />
                {/*<TabButton label="API Keys" {...a11yProps(3)} />*/}
                <TabButton label="Jobs" {...a11yProps(3)} />
                <TabButton label="Assets" {...a11yProps(4)} />
                <TabButton label="Misc" {...a11yProps(5)} />
            </Tabs>
            <TabPanel value={value} index={0}>
                <Status/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <FeatureFlags/>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <SearchIndex/>
            </TabPanel>
            {/*<TabPanel value={value} index={3}>*/}
            {/*    <APIKeys/>*/}
            {/*</TabPanel>*/}
            <TabPanel value={value} index={3}>
                <Jobs/>
            </TabPanel>
            <TabPanel value={value} index={4}>
                <Assets/>
            </TabPanel>
            <TabPanel value={value} index={5}>
                <Misc/>
            </TabPanel>
        </Wrapper>
    );
};

export default ControlPanelComponent;
