import {UUID} from '../../../../../cms/types';
import {CMSObject} from '../../../../../cms/models/__CMSObject';
import {ProductDataTree} from '../../../../../cms/models/__ProductDataTree';
import {fi} from '../../../../../utils/helpers';
import {ChildAssessment} from '../../../../../cms/models/ChildAssessment';
import {CTUnit} from '../../../../../cms/models/CTUnit';
import {Component} from '../../../../../cms/models/Component';
import {SubjectGroupMapping} from '../../../../../cms/models/SubjectGroupMapping';
import {Objects} from '../../../../../utils/objects';

export interface Node {
	id: UUID;
	depth: number,
	parents: Node[];
	children: Node[];
	object: CMSObject
	selected: boolean;
	expanded: boolean;
	selectedChildren: number;
}

export type NodesMapType = {[key: string]: Node}
export let NodesMap: {[key: string]: NodesMapType} = {};
export let NodeMap: NodesMapType = {};
window['NodesMap'] = NodesMap
window['NodeMap'] = NodeMap
export const cloneTree = (tree: any) => {
	const tmp = {};
	for (let k in tree) {
		const clone = {...tree[k]};
		tmp[k] = clone;
	}
	for (let k in tmp) {
		const clone = tmp[k];
		clone.parents = clone.parents.map(i => tmp[i.id]);
		clone.children = clone.children.map(i => tmp[i.id]);
	}
	return tmp;
};

export const newNode = (obj: CMSObject, parent?: Node, map: any = NodeMap) => {
	const existing = map[obj.getId()];
	if (existing) {
		if (parent) {
			existing.parents.push(parent);
			parent.children.push(existing);
			return existing;
		}
		return existing;
	}

	const node: Node = {
		id: obj.getId(),
		depth: -1,
		children: [],
		parents: [],
		object: obj,
		selected: false,
		expanded: false,
		selectedChildren: 0,
	};
	if (parent) {
		node.depth = parent.depth + 1;
		node.parents.push(parent);
		parent.children.push(node);
	} else {
		map[''] = node; // root
	}
	map[node.id] = node;
	return node;
};

export const generateTree = (tree: ProductDataTree, subjectOnly: boolean = false, map: any = NodeMap): Node => {
	Objects.empty(map);
	const root = newNode(new CMSObject(), undefined, map);
	root.expanded = true;

	tree.qualifications.forEach(q => {
		const qualificationNode = newNode(q, root, map);

		q.subjects.forEach(s => {
			const subjectNode = newNode(s, qualificationNode, map);
			if (subjectOnly) {
				return;
			}
			s.qualificationSizes.forEach(qs => {
				const qualificationSizeNode = newNode(qs, subjectNode, map);
				qs.units.forEach(u => {
					newNode(u, qualificationSizeNode, map);
				});
			});
			s.assessments.forEach(a => {
				const assessmentNode = newNode(a, subjectNode, map);
				a.childAssessments.forEach(ca => {
					newNode(ca, assessmentNode, map);
				});
				a.components.forEach(c => {
					newNode(c, assessmentNode, map);
				});
			});
		});
	});

	return root;
};

export const selectDown = (node: Node, subjectOnly: boolean = false): Node[] => {
	const base: Node[] = [];
	const walk = (n: Node) => {
		n.children.forEach(c => {
			c.selected = node.selected;
			c.selectedChildren = fi(node.selected, c.children.length, 0);
			walk(c);
		});
		if (subjectOnly) {
			if (n.object instanceof SubjectGroupMapping) {
				base.push(n);
			}
		} else {
			if (n.object instanceof Component || n.object instanceof ChildAssessment || n.object instanceof CTUnit) {
				base.push(n);
			}
		}
	};

	walk(node);
	node.selectedChildren = fi(node.selected, node.children.length, 0);
	return base;
};

export const selectUp = (node: Node) => {
	const walk = (n: Node, parent: Node) => {
		let selected = 0;
		let partiallySelected = 0;
		parent.children.forEach(c => {
			if (c.selected) {
				selected++;
			} else if (c.selectedChildren > 0) {
				partiallySelected++;
			}
		});

		parent.selectedChildren = partiallySelected + selected;
		parent.selected = selected === parent.children.length;

		n = parent;
		parent.parents.forEach(p => {
			walk(n, p);
		});
	};

	node.parents.forEach(p => {
		walk(node, p);
	});
};

export const flattenTree = (root: Node): Node[] => {
	const res: Node[] = [];
	const walk = (node: Node) => {
		res.push(node);
		if (node.expanded) {
			node.children.forEach(child => {
				walk(child);
			});
		}
	};

	root.children.forEach(n => {
		walk(n);
	});

	return res;
};

export const resetTree = (nodes: any) => {
	for (let k in nodes) {
		const obj = nodes[k];
		obj.selected = false;
		obj.expanded = false;
		obj.selectedChildren = 0;
	}
}