import styled from '@emotion/styled';
import Paper from '@mui/material/Paper';
import React, {Suspense, useEffect} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {fi} from '../../utils/helpers';
import {useResetPreferences} from '../../utils/hooks';
import {currentTableState, useTable, itemNoteState, ItemNotes} from './state';
import TableBody from './TableBody';
import TableFooter from './TableFooter';
import TableHeader from './TableHeader';
import TableHeading from './TableHeading';
import {setRecoil} from "../../state/recoilNexus";
import {Objects} from "../../utils/objects";
import { useSetRecoilState } from 'recoil';
import Client from '../../cms/client';

const Wrapper = styled(Paper)`
    box-shadow: 0 4px 6px 0 var(--color-box-shadow);
    border-width: 1px;
    border-style: solid;
    border-color: var(--color-border-light);
    position: relative;
    margin-bottom: 150px;
`;

const TableWrapper = styled.div`
    overflow-x: auto;
    margin-right: 100px;
    overflow-y: hidden;
    padding: 0;

    &.no-action-column {
        margin-right: 0;
    }

    &.checkboxes {
        margin-left: 60px;

        table {
            width: calc(100% - 1px);
        }
    }
`;

const ErrorFallback = ({error, resetErrorBoundary}) => {
    console.error(error)
    return (
        <p>
            Error: <span>{Objects.default(error).message}</span>
            <br/>
            <button onClick={resetErrorBoundary}>Retry</button>
        </p>
    );
};

const Table = styled.table`
    width: calc(100% - 1px);
    border-collapse: collapse;

    .fixed, .fixed-start {
        box-shadow: 1px 0 inset var(--color-box-shadow);
        height: 54px;
        text-align: center;
        position: absolute;
        width: 100px;
        right: 0;
        top: auto;
        display: flex;
        align-items: center;
        margin-top: -1px;
        justify-content: center;
        border-top: 1px solid var(--color-grey);
        border-bottom: 1px solid var(--color-grey);
    }

    .fixed-start {
        right: unset;
        left: 0;
        width: 60px;
    }
`;

interface Note {
    item: string;
    comment: string;
}

const CustomTable = () => {
    const setItemNote = useSetRecoilState(itemNoteState);
    const table = useTable()

    useResetPreferences(() => {
        table.actions.resetPreferences().catch();
    });

    useEffect(() => {
		const appendInternalNotes = async () => {
			const notes = await Client.query({
				limit: -1,
				types: ['note'],
				sort: 'desc',
			});

			const itemNotes = (notes.results as Note[]).reduce<ItemNotes>(
				(acc, note) => {
					if (!acc[note.item]) {
						acc[note.item] = [note.comment];
					} else {
						acc[note.item].push(note.comment);
					}
					return acc;
				},
				{} as ItemNotes,
			);
            setItemNote(itemNotes);
            table.actions.refresh().catch();
            setRecoil(currentTableState, table.state)
		};
        appendInternalNotes();

        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => {
            setRecoil(currentTableState, null)
        };
        // eslint-disable-next-line
    }, []);

    return (
        <Wrapper>
            <TableHeading/>
            <TableWrapper
                className={`${fi(table.state.config.manualPublish, 'checkboxes')} ${fi(table.state.config.hideActionColumn, 'no-action-column')}`}>
                <Table>
                    <TableHeader/>
                    <TableBody/>
                </Table>
            </TableWrapper>
            <TableFooter/>
        </Wrapper>
    );
};

const TableComponent = () => {
    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Suspense fallback={<div>Loading...</div>}>
                <CustomTable/>
            </Suspense>
        </ErrorBoundary>
    );
};

export default TableComponent;
