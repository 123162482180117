import styled from '@emotion/styled';
import CircularProgress from '@mui/material/CircularProgress';
import React from "react";
import {ActionButton} from "./ActionButton";
import {fi} from "../../utils/helpers";
import {useHotkeys} from "react-hotkeys-hook";

const Wrapper = styled(ActionButton)<{loading: string}>`
  svg {
    width: 18px;
    height: 18px;
    color: var(${props => fi(props.loading === 'true', '--color-blue', '--color-monochrome')}) !important;
  }
`

const AsyncActionButton = (props: any) => {
    const {onClick, hotKey, ...rest} = props
    const [loading, setLoading] = React.useState(false)
    const onButtonClick = () => {
        if (loading) {
            return
        }

        setLoading(true)
        onClick().finally(() => {
            setLoading(false)
        })
    }

    let keys = crypto.randomUUID()
    if (hotKey) { keys = hotKey }

    useHotkeys(keys, (evt) => {
        evt.preventDefault();
        onButtonClick();
    });

    return (
        <Wrapper onClick={onButtonClick} loading={loading.toString()} {...rest}>
            {(loading && <CircularProgress size={18}/>) || props.children}
        </Wrapper>
    )
}

export default AsyncActionButton;