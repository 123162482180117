import styled from '@emotion/styled';
import AddBoxIcon from '@mui/icons-material/AddBox';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Divider from '@mui/material/Divider';
import Input from '@mui/material/Input';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useHotkeys} from 'react-hotkeys-hook';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {Types} from '../../cms/types';
import {cacheBuster} from '../../state/state';
import {fi} from '../../utils/helpers';
import {useLocalStorage} from '../../utils/hooks';
import {ActionButton} from '../commons/ActionButton';
import {InstanceOf} from '../../cms/models';
import {Browser} from '../../utils/browser';
import {Strings} from '../../utils/strings';
import {modalAtom} from '../ModalDialogs/ModalWrapper';
import {Tree} from './Tree';

const Toolbar = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 2px;

  hr {
    margin: 6px 8px;
    height: 20px;
  }
  
`;

const SearchBar = styled.div`
  position: absolute;
  background: white;
  width: 100%;
  z-index: 1;
  padding: 0;
  margin: 0;
  height: 32px;
  display: flex;
  align-items: center;

  svg {
    color: var(--color-monochrome);
    width: 20px;
    height: 20px;
  }

  .MuiInputBase-root {
    border: none;
    padding: 4px 8px;

    &::before, &::after {
      content: none;
    }
  }

  input {
    font-size: 14px;
    padding: 2px 8px;
    flex-grow: 1;
  }
`;

export const TreeActions = ({treeType}: { treeType: Types }) => {
    const key = `tree-${treeType}`;
    const [cache] = useRecoilState(cacheBuster(key));
    const items = useRef<Tree>();
    const [searching, setSearching] = useState<boolean>(false);
    const [showQuickAccess, setShowQuickAccess] = useLocalStorage(`show-qa-${treeType}`, true);
    const setModalEdit = useSetRecoilState(modalAtom);

    useEffect(() => {
        const tree = Tree.getTree(key);
        if (tree) {
            items.current = tree;
        }
        // eslint-disable-next-line
    }, [cache]);

    const cantCreateReason = useMemo<string>(() => {
        const objectInstance = InstanceOf(treeType, false);
        if (!objectInstance) {
            return 'Unknown object type';
        }
        return objectInstance.canCreate();
    }, [treeType]);

    const clearSearch = () => {
        setSearching(false);
		Tree.filterNodes('');
    };

    const refresh = (evt) => {
        evt.preventDefault();
        items.current?.refresh();
    };

    const collapseAll = (evt) => {
        evt.preventDefault();
        items.current?.collapseAll();
    };

    const expandAll = (evt) => {
        evt.preventDefault();
        items.current?.expandAll();
    };

    const onSearchKeyDown = (evt: any) => {
        if (evt.key === 'Escape') {
            clearSearch();
            return;
        }
    };

    const onSearch = (evt: any) => {
        const query = evt.target.value.toLowerCase();
		Tree.filterNodes(query.toLowerCase());
    };

    const toggleQuickAccess = (e: any) => {
        e.preventDefault();
        setShowQuickAccess(!showQuickAccess);
    };

    const createItem = (e: any) => {
        e.preventDefault();
        const routes = InstanceOf(treeType).routes();
        if (routes.createInline) {
            setModalEdit({component: routes.createInline});
        } else if (routes.create) {
            Browser.navigate(Strings.default(routes.create));
        }
    };

    useHotkeys('ctrl+f', (e) => {
        e.preventDefault();
        setSearching(true);
    });
    useHotkeys('alt+add', expandAll);
    useHotkeys('alt+subtract', collapseAll);
    useHotkeys('ctrl+q', toggleQuickAccess);

    return (
        <>
            <Toolbar>
                {searching && (
                    <SearchBar>
                        <ActionButton variant="text" title={'Filter'} tabIndex={0} data-testid={'search-tree'}
                                      onClick={clearSearch}>
                            <SearchIcon/>
                        </ActionButton>
                        <Input size="small" type={'search'} placeholder={'Search...'} autoFocus={true}
                               onKeyDown={onSearchKeyDown}
                               onChange={onSearch} autoComplete={'off'} spellCheck={false}/>
                    </SearchBar>
                )}

                <div className={'flex-row'}>
                    <ActionButton variant="text" title={'Search [Ctrl+F]'} tabIndex={0} data-testid={'search-tree'}
                                  onClick={() => setSearching(true)}>
                        <SearchIcon/>
                    </ActionButton>

                    <Divider orientation='vertical' variant='middle' flexItem />

                    <ActionButton variant="text" title={'Expand all [Alt+Numpad +]'} onClick={expandAll}
                                  tabIndex={0}
                                  data-testid={'expand-all'}>
                        <UnfoldMoreIcon/>
                    </ActionButton>

                    <ActionButton variant="text" title={'Collapse all [Alt+Numpad -]'} onClick={collapseAll}
                                  tabIndex={0}
                                  data-testid={'collapse-all'}>
                        <UnfoldLessIcon/>
                    </ActionButton>

                    <Divider orientation='vertical' variant='middle' flexItem />

                    <ActionButton variant="text" title={'Refresh'} onClick={refresh} tabIndex={0}
                                  data-testid={'refresh'}>
                        <RefreshIcon/>
                    </ActionButton>
                    <ActionButton variant="text" title={'Toggle Quick Access [Ctrl+Q]'} onClick={toggleQuickAccess}
                                  tabIndex={0}
                                  data-testid={'quick-access'}>
                        {fi(showQuickAccess, <StarIcon/>, <StarBorderIcon/>)}
                    </ActionButton>
                </div>

                <div>
                    <ActionButton variant="text" title={Strings.default(cantCreateReason, `Create new ${treeType}`)}
                                  tabIndex={0}
                                  disabled={Boolean(cantCreateReason)} data-testid={'create-new'} onClick={createItem}>
                        <AddBoxIcon/>
                    </ActionButton>
                </div>
            </Toolbar>
            <Divider/>
        </>
    );
};

export default TreeActions;
