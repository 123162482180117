import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import {bulkTableColumnsState, bulkTableState} from './state';
import styled from '@emotion/styled';
import Form from '../Form/Form';
import {BulkFile} from '../../cms/models/BulkFile';
import ReferenceRenderer from '../Form/renderers/ReferenceRenderer';
import {debounce, fi} from '../../utils/helpers';
import TagsRenderer from '../Form/renderers/TagsRenderer';
import ListRenderer from '../Form/renderers/ListRenderer';
import TextRenderer from '../Form/renderers/TextRenderer';
import ProductDataRenderer from '../Form/renderers/ProductDataRenderer';
import DateRenderer from '../Form/renderers/DateRenderer';
import {Types} from '../../cms/types';
import {useForm, useFormField} from '../Form/state';
import {Objects} from '../../utils/objects';
import {Document} from '../../cms/models/Document';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {getRecoil, setRecoil} from '../../state/recoilNexus';
import {Lists} from '../../utils/lists';
import {preventReloadAtom} from '../commons/PreventReload';

const Wrapper = styled.div`
    position: fixed;
    border: 1px solid var(--color-border);
    background: white;
    padding: 0;
    min-width: 500px;
    max-width: 600px;
    z-index: 3;
    display: flex;
    gap: 4px;
    flex-direction: row;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
    align-items: stretch;

    .Mui-error, .error {
        border-color: inherit !important;
    }

    #bulk-upload {
        padding: 8px 0;

        &:last-of-type {
            padding-right: 8px;
        }

        &:first-of-type {
            padding-left: 8px;
        }

        .Mui-error {
            border-color: inherit !important;
        }
    }
`;

const NavButton = styled.div`
    display: flex;
    align-items: center;
    align-content: center;
    padding: 0 2px;
    cursor: pointer;

    &:last-of-type {
        border-left: 1px solid var(--color-border);
    }

    &:first-of-type {
        border-right: 1px solid var(--color-border);
    }

    &:hover {
        background: var(--color-background)
    }
`;

const FieldWrapper = styled.div`
    &.title, &.description, &.paper_number {
        display: flex;
        flex-direction: column;

        label {
            font-weight: bold;
            margin-bottom: 8px;
        }
    }

    &.productData {
        label {
            font-weight: bold;
        }
    }

`;

const CellFormField = ({fieldName, file}: { fieldName: string, file: BulkFile }) => {
    const form = useForm();
    const formField = useFormField(fieldName);
    const [state] = useRecoilState(bulkTableState);
    const [editFile, setEditFile] = useState<any | undefined>();

    const bounce = useRef(debounce((form, editFile, state, file) => {
        if (!editFile) return;
        if (!state.editMode) return;
        if (file.filename !== form.state.meta) return;
        editFile.setData(form.state.values, true);
    }, 0))

    useEffect(() => {
        if (!state.editMode || Objects.isEmpty(file)) {
            form.setMeta(undefined);
            form.setValues(new Document());
            setEditFile(undefined);
            return;
        }

        form.setMeta(file.filename);
        form.setObject(file.data);
        form.setValues(file.data);
        setEditFile(file);
        // eslint-disable-next-line
    }, [state, file]);

    const field = useMemo(() => {
        if (!fieldName) return null;
        const f = formField.state.fieldState(fieldName);
        if (!f) return null;

        switch (fieldName) {
            case 'title':
            case 'paper_number':
                return <TextRenderer field={f.field} autoFocus={true}/>;
            case 'productData':
                return <ProductDataRenderer field={f.field} nodes={file.filename}/>;
            case 'content_type':
                return <ReferenceRenderer field={f.field}/>;
            case 'description':
                return <TextRenderer field={f.field} autoFocus={true}/>;
            case 'exam_year':
                return <ListRenderer field={f.field} />;
            case 'series':
                return <ListRenderer field={f.field}/>;
            case '__tag':
                return <TagsRenderer field={f.field}/>;
            case 'public_from':
                return <DateRenderer field={f.field} rkey={file.bulkid.toString()}/>;
            default:
                return null;
        }
        // eslint-disable-next-line
    }, [fieldName, file]);

    useEffect(() => {
        return () => {
			// eslint-disable-next-line
            bounce.current(form, editFile, state, file);
        }
        // eslint-disable-next-line
    }, [form, state, formField, editFile]);

    return (
        <FieldWrapper className={fieldName}>
            {field}
        </FieldWrapper>
    );
};

const CellForm = () => {
    const ref = useRef<any>();
    const docRef = useRef<any>(new Document());
    const [state] = useRecoilState(bulkTableState);
    const [field, setField] = useState<string>('');
    const [file, setFile] = useState<any>({});
    const tableColumns = useRecoilValue(bulkTableColumnsState)

    useEffect(() => {
        if (!state.selectedCell || state.selectedCell.length === 0) {
            setField('');
            setFile({});
            return;
        }

        const cell = document.getElementById('cell-highlight');
        if (!cell || !ref.current) {
            return;
        }

        const cellBox = cell.getBoundingClientRect();

        ref.current.style.left = (cellBox.left - 255 + (cellBox.width / 2)) + 'px';
        ref.current.style.top = (cellBox.top - 41 + (cellBox.height / 2)) + 'px';

        setFile(BulkFile.files.find(f => f.bulkid === +state.selectedCell[0]));
        setField(state.selectedCell[1]);

    }, [state]);

    const form = useMemo(() => {
        if (!BulkFile.model || !file) return null;

        return (
            <CellFormField file={file} fieldName={field}/>
        );
    }, [file, field]);

    const nav = (where: string) => {
        const s = getRecoil(bulkTableState);
        let idx: number;
        const columnsName = tableColumns.slice(1).map(c => c.name)
        if (where === 'left') {
            idx = Math.max(columnsName.indexOf(s.selectedCell[1]) - 1, 0);
            setRecoil(bulkTableState, (val) => ({
                editMode: true,
                selectedCell: [s.selectedCell[0], columnsName[idx]],
                selectedRange: [],
            }));
            setRecoil(preventReloadAtom, false)
            return
        }

        idx = Math.min(columnsName.indexOf(s.selectedCell[1]) + 1, tableColumns.length - 1);
        setRecoil(bulkTableState, (val) => ({
            editMode: true,
            selectedCell: [s.selectedCell[0], columnsName[idx]],
            selectedRange: [],
        }));
        setRecoil(preventReloadAtom, false)
    }

    const selectedCell: string[] = Lists.default(Objects.default(state).selectedCell)

    return (
        <Wrapper ref={ref} style={{display: fi(!Objects.default(state).editMode, 'none', 'flex')}} tabIndex={0}
                 autoFocus={true}>
            {selectedCell[1] !== 'title' && <NavButton onClick={() => nav('left')}><KeyboardArrowLeftIcon/></NavButton>}
            <Form id={`bulk-upload`} noPreventReload={true} model={Types.DOCUMENT} object={docRef.current}>
                {Objects.default(state).editMode && form}
            </Form>
            {selectedCell[1] !== 'public_from' &&
                <NavButton onClick={() => nav('right')}><KeyboardArrowRightIcon/></NavButton>}
        </Wrapper>
    );
};

export default CellForm;