import React, {useContext, useMemo} from "react"
import { Field, FieldType } from '../../../cms/models/__ModelInfo';
import SelectComponent, {ISelectValue} from "./components/Select/SelectComponent";
import {FormContext} from "../Form";
import {useFormField} from "../state";
import {Lists} from "../../../utils/lists";
import {Strings} from "../../../utils/strings";
import {LocalizedString} from "../../../cms/types";

const ListRenderer = ({field}: { field: Field}) => {
    const context = useContext(FormContext);
    const formField = useFormField(field.uid);

    const handleChange = (newValue, _id) => {
        if (newValue === null) {
            if (field.flags.multiple) {
                formField.setValue([])
            } else {
                formField.setValue(null)
            }
        } else {
            if (field.flags.multiple) {
                if (field.fieldtype === FieldType.NumberList) {
                    formField.setValue(newValue)
                } else {
                    formField.setValue([...Lists.default(formField.value), newValue.value])
                }

            } else {
                formField.setValue(newValue.value)
            }
        }
    }

    const options: ISelectValue[] = useMemo(() => {
        return Lists.default<LocalizedString>(field.config.options).map(option => ({
            value: Strings.en(option),
            label: Strings.startCase(Strings.en(option)),
            object: Strings.en(option),
        }));
    }, [field])

    const currentValue = useMemo(() => {
        if (field.flags.multiple) {
            return Lists.default(formField.value)
        } else {
            return Strings.default(formField.value).toString()
        }
        // eslint-disable-next-line
    }, [formField.value])

    return (
        <>
            <SelectComponent
                dataTestId={`${context.id}-${field.uid}-field`}
                label={field.name}
                required={field.flags.required}
                id={field.uid}
                fieldType={field.fieldtype}
                checkbox={field.flags.multiple}
                value={currentValue}
                touched={formField.touched}
                errorMessage={formField.error}
                portal={document.getElementById("list-portal-wrapper")}
                showError={formField.showError()}
                isClearable={true}
                readonly={formField.previewMode}
                onChange={handleChange}
                values={options}
            />
        </>
    )
}

export default ListRenderer