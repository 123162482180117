import {ProductMapped} from "./__ProductMapped";
import {UUID} from "../types";
import {CMSFile} from "./File";
import {getRecoil, setRecoil} from "../../state/recoilNexus";
import {cacheBuster, references} from "../../state/state";
import {Browser} from "../../utils/browser";
import {ROUTES} from "../../utils/routes";
import {Strings} from "../../utils/strings";
import {formConfig, tableColumn, tableHidden} from "../../utils/decorators";
import React from "react";
import {ColumnType} from "../../components/TableComponent/renderers";
import {ItemRoutes} from "./__CMSObject";
import {FormEvent, IFormEvents} from "../../components/Form/model";
import {Dates} from "../../utils/dates";
import {fi} from '../../utils/helpers';
import {Messages} from '../../utils/messages';
import {itemTypes} from "../../utils/constants";
import {CreateMediaLibraryItem} from "../../components/CreateMediaLibraryItem/CreateMediaLibraryItem";
import BulkUpload from "../../components/TableComponent/bulkActionButtons/BulkUpload";
import TextAction from "../../components/commons/TextAction";
import Client from "../client";

const noFolder = 'na';
// Base class to be extended by items that belong in the Media Library
@formConfig({
    buttons:[<BulkUpload />, <CreateMediaLibraryItem />]
})
export class LibraryItem extends ProductMapped implements IFormEvents {
    public title: string;
    public description: string;
    public folder: UUID;

    constructor(item: any = {}) {
        super(item);
        this.title = Strings.default(item.title);
        this.description = Strings.default(item.description);
        this.folder = Strings.default(item.folder);
    }

    public routes(): ItemRoutes {
        return {
            list: `/library/${Browser.getParams(ROUTES).folderId === noFolder? "":Strings.default(this.folder, Browser.getParams(ROUTES).folderId)}`,
            edit: `/library/${Strings.default(this.folder,noFolder)}/${this.getType()}/${this.getId()}`,
            create: `/library/${Strings.default(Browser.getParams(ROUTES).folderId,noFolder)}/${this.getType()}/create`,
        };
    }

    public formOnBeforeSave(evt: FormEvent): any {
        const res = {...evt.state.values}
        if (!res.folder) {
            res.folder = Browser.getParams(ROUTES).folderId;

            if(res.folder===noFolder){
                delete res.folder
            }
        }
        return res
    }

    public formOnSaveSuccess(): any {
        setRecoil(cacheBuster(itemTypes.join(",")), (val) => val + 1)
    }

    public async canDelete(): Promise<string> {
        return fi(this.isPublished(), Messages.CantDeletePublishedItem, '');
    }

}

// Base class to be extended by items that belong in the Media Library and have a file
export class MediaItemFile extends LibraryItem {
    @tableHidden
    public file: UUID;


    constructor(item: any = {}) {
        super(item);

        this.file = item.file;
    }

    public fileInfo(): CMSFile | null {
        return getRecoil(references(this.file)) as any as CMSFile;
    }

    @tableColumn({
        order: 4,
        label: 'Filename',
        field: 'file',
        type: ColumnType.File,
        default: true,
        filter: {noFilter: true}
    })
    public __file(plain?: boolean): string | React.ReactNode {
        // @ts-ignore
        if (typeof this.fileInfo !== "function") {
            return '-'
        }

        const info = this.fileInfo();
        if (!info) {
            return '-';
        }
        if (plain) {
            return info.filename;
        }
        return <TextAction onClick={() => {
            Client.download(info.fileuid).catch((err) => {
                console.error(`Error downloading file: ${err}`, {variant: 'error'});
            });
        }}>{info?.filename}</TextAction>
    }

    @tableColumn({
        order: 4.5,
        label: 'File size',
        field: 'fileSize',
        type: ColumnType.Number,
        filter: {noFilter: true}
    })
    public __fileSizeColumn(plain?: boolean): string | React.ReactNode {
        if (!this.fileInfo) {
            return '-';
        }
        const info = this.fileInfo();
        if (info) {
            return info.formattedSize
        }
        return '-'
    }

    @tableColumn({
        order: 99,
        label: 'Published from',
        field: '__meta.published',
        type: ColumnType.Date,
    })
    public __publishedFrom(plain?: boolean): string | React.ReactNode {
        if (this.getMeta().published) {
            let tmp = Dates.local(this.getMeta().published!);
            if (this.getMeta().unpublish) {
                tmp += ' → '+ Dates.local(this.getMeta().unpublish!);
            }
            return tmp;
        }
        return ''
    }
}
