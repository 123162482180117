import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import {atomFamily} from 'recoil';
import {getRecoil, setRecoil, transaction} from '../state/recoilNexus';
import {references} from '../state/state';
import {AUTH_TOKEN, DEFAULT_PAGE_SIZE, DEFAULT_QUERY_RESULT} from '../utils/constants';
import {Lists} from '../utils/lists';
import {Numbers} from '../utils/numbers';
import {Objects} from '../utils/objects';
import {Strings} from '../utils/strings';
import {InstanceOf} from './models';
import {ModelInfo} from './models/__ModelInfo';
import {UserSession} from './models/Session';
import {
	IDistinctResult,
	IQueryResult,
	QueryContentRequest,
	QueryDistinctRequest,
	Sort,
	Types,
	UsageStatus,
	UUID,
} from './types';
import {CMSObject} from './models/__CMSObject';
import {CMSFile} from './models/File';
import {IFeatureFlag, IOpenSearchStatus} from './models/__Interfaces';
import {ProductDataTree} from './models/__ProductDataTree';
import {ISelectValue} from '../components/Form/renderers/components/Select/SelectComponent';
import {Job, NodeJob} from './models/Job';
import {Environment, Node, Service} from './models/Node';
import {BulkFile} from './models/BulkFile';
import {fi} from '../utils/helpers';
import {KeyDateFile} from './models/KeyDateFile';

enum Method {
	GET = 'get',
	POST = 'post',
	PUT = 'put',
	DELETE = 'delete',
}

type subjectNotificationEmail = {
	id?: string,
	subject: string,
	content: string,
	page: string,
	to?: string[] ,
	schedule?: Date
}

const queryETags = atomFamily<string, string>({
	key: 'queryETags',
	default: '',
});

const cachedQueryResults = atomFamily<IQueryResult<any>, string>({
	key: 'cachedQueryResults',
	default: {...DEFAULT_QUERY_RESULT},
});

export interface IUploadProgress {
	fileName: string;
	percent: number;
	cancel: CancelTokenSource;
}

class Client {
	constructor() {
		axios.defaults.baseURL = Strings.default(process.env.REACT_APP_API_URL, `//api.${document.location.host}`) + `/cms-api/v1.0`;
		axios.defaults.withCredentials = true;
	}

	public async query<T>(request: QueryContentRequest): Promise<IQueryResult<T>> {
		const req = {...request};
		req.start = Numbers.default(req.start);
		req.limit = Numbers.default(req.limit, DEFAULT_PAGE_SIZE);
		req.sort = Strings.default(req.sort, 'asc') as Sort;
		req.types = Array.from(new Set(req.types));

		if (req.uuids) {
			req.uuids = Lists.default<string>(req.uuids.filter(r => r));
		}

		const cacheKey = JSON.stringify(req);
		//const etag = getRecoil(queryETags(cacheKey));
		// if (etag) {
		// 	req.etag = etag;
		// }

		let data = await this.call<IQueryResult<T>>(Method.POST, `/query`, req);
		if (data) {
			if (data.status === 304) {
				data = Objects.clone(getRecoil(cachedQueryResults(cacheKey)));
			} else if (data.status === 200 && data.etag) {
				setRecoil(queryETags(cacheKey), data.etag);
				setRecoil(cachedQueryResults(cacheKey), Objects.clone(data));
			}
			data = this.toClassResults(data, request.metadataonly);
		}
		return Objects.default(data, {...DEFAULT_QUERY_RESULT});
	}

	public async distinctValues(request: QueryDistinctRequest): Promise<any[]> {
		return this.call<IDistinctResult>(Method.POST, `/queryDistinct`, request).then(data => {
			if (data.references) {
				// replace reference objects with classes
				transaction(({set}) => {
					for (let id in data.references) {
						const ref = InstanceOf(data.references[id]);
						data.references[id] = ref;
						set(references(id), ref);
					}
				});
			}
			return data.results;
		});
	}

	public async events(): Promise<ISelectValue[]> {
		const res = await this.call(Method.GET, '/events?codes=all');
		return Lists.default<any>(res).map(o => {
			o.__type = Types.EVENT;
			return {label: o.code, value: o.code, object: InstanceOf(o)};
		});
	}

	public async get(id: UUID): Promise<CMSObject> {
		return this.call<any>(Method.GET, `/content/${id}`).then((res) => {
			const tmp = InstanceOf(res);
			transaction(({set}) => {
				set(references(id), tmp);
				const refs = Objects.default(res.__references);
				for (let key in refs) {
					set(references(key), InstanceOf(refs[key]));
				}
			});
			return tmp as CMSObject;
		});
	}

	public async delete(id: UUID): Promise<void> {
		return this.call<void>(Method.DELETE, `/content/${id}`).then(() => {
			transaction(({reset}) => {
				reset(references(id)); // remove from recoil
			});
		});
	}

	public async update(obj: any): Promise<CMSObject> {
		return this.call<CMSObject>(Method.PUT, `/content`, obj).then((res) => {
			const tmp = InstanceOf(res);
			setRecoil(references(tmp.getId()), tmp);
			return tmp;
		});
	}

	public async create(obj: {}): Promise<CMSObject> {
		return this.call<CMSObject>(Method.POST, `/content`, obj).then((res) => {
			const tmp = InstanceOf(res);
			setRecoil(references(tmp.getId()), tmp);
			return tmp;
		});
	}

	public async deleteMore(ids: UUID[]): Promise<void> {
		return this.call<any>(Method.POST, `/delete`, ids).then(() => {
			transaction(({reset}) => {
				ids.forEach(id => reset(references(id)));
			});
		});
	}

	public getAuthToken(): string {
		return Strings.default(localStorage.getItem(AUTH_TOKEN));
	}

	public setAuthToken(token: string): void {
		localStorage.setItem(AUTH_TOKEN, token);
		axios.defaults.headers.common['Authorization'] = token;
	}

	public deleteAuthToken(): void {
		localStorage.removeItem(AUTH_TOKEN);
		delete (axios.defaults.headers.common['Authorization']);
	}

	public async getAccountDetails(): Promise<UserSession> {
		return this.call<UserSession>(Method.GET, '/accountInfo').then(response => new UserSession(response));
	}

	public async switchStream(streamId: string): Promise<UserSession> {
		return this.call<UserSession>(Method.GET, `/setBusinessStream/${streamId}`).then(response => new UserSession(response));
	}

	public async login(): Promise<UserSession> {
		return this.call(Method.GET, `/login${document.location.search}`).then(response => new UserSession(response));
	}

	public async logout(): Promise<void> {
		return this.call(Method.GET, `/logout`).then(() => {
			this.deleteAuthToken();
		});
	}

	public async modelInfo(model: string): Promise<ModelInfo> {
		return this.call(Method.GET, `/modelInfo/${model}`).then(response => new ModelInfo(response));
	}

	public async reorderPages(parent: UUID, children: UUID[]): Promise<void> {
		return this.call(Method.PUT, `/move`, {parent, uuids: children});
	}

	public async checkUsage(itemUUID: UUID): Promise<UsageStatus> {
		return this.call(Method.GET, `/usageStatus/${itemUUID}`);
	}

	public async tags(): Promise<any> {
		return this.call(Method.GET, `/tags`);
	}

	public async getFolderFiles(folderName: string): Promise<any[]> {
		return this.call(Method.GET, `/folderFiles/${folderName}`);
	}

	public async getLibraryFolders(): Promise<any[]> {
		return this.call(Method.GET, `/folders`);
	}

	public async deleteLibraryFile(item: UUID): Promise<void> {
		return this.call(Method.DELETE, `/libraryFile/${item}`);
	}

	public async getLinksReport(): Promise<void> {
		return axios.get(`/linkStatusReport`, {
			responseType: 'arraybuffer',
		}).then((response) => this.forceDownload(response));
	}

	public async getItemLinksReport(item: string): Promise<any> {
		return axios.get(`/documentLinkStatusReport/${item}`, {
			responseType: 'arraybuffer',
		}).then((response) => this.forceDownload(response));
	}

	public async excelTemplate(): Promise<any> {
		return axios.get(`/excelTemplate`, {
			responseType: 'arraybuffer',
		}).then((response) => this.forceDownload(response));
	}

	public async noop(): Promise<void> {
		return;
	}

	public async rescanLinks(): Promise<void> {
		return this.call(Method.GET, `/rescanLinks`);
	}

	public async runCMD(cmd: string): Promise<void> {
		return this.call(Method.GET, `/runCmd/${cmd}`);
	}

	public async refreshCMSData(): Promise<void> {
		return this.call(Method.GET, `/refreshCMSData`);
	}

	public async refreshMappings(): Promise<void> {
		return this.call(Method.GET, `/refreshMappings`);
	}

	public async getJobs(): Promise<Job[]> {
		return this.call<any[]>(Method.GET, `/jobs`).then(res => {
			const result: Job[] = [];
			res.forEach(j => {
				const node = new NodeJob(j);
				let job = result.find(p => p.name === j.name);
				if (job) {
					job.addNode(node);
				} else {
					const p = new Job(node);
					result.push(p);
				}
			});
			Lists.sort(result, 'name');
			return result;
		});
	}


	public async getNodes(): Promise<Environment> {
		return this.call<any[]>(Method.GET, `/jobs`).then(res => {
			let env: Environment | undefined = undefined;
			const services: {[key: string]: Service} = {};
			res.forEach(n => {
				const node = new Node(n);
				let s = services[node.nodeName];
				if (s) {
					s.addNode(node);
				} else {
					const service = new Service(node);
					if (!env) {
						env = new Environment(service);
					} else {
						env.addService(service);
					}
				}
			});
			if (!env) {
				return new Environment({environment: 'Unknown'} as Service);
			}
			return env;
		});
	}

	public async activityReport(fileUID: UUID) {
		return axios.get('/seenReport/' + fileUID, {
			responseType: 'arraybuffer',
		}).then((response) => this.forceDownload(response));
	}

	public async exportTable(config: any) {
		return axios.post('/exportTable', config, {
			headers: {'content-type': 'application/json'},
			responseType: 'arraybuffer',
		}).then((response) => this.forceDownload(response));
	}

	public async exportItemsReport(itemIds: string[]) {
		return axios.post('/exportItemsReport', itemIds, {
            headers: {'content-type': 'application/json'},
            responseType: 'arraybuffer',
        }).then((response) => this.forceDownload(response));
	}

	public async downloadSubjectUpdatesViews(req): Promise<any> {
		return axios.post('/downloadEmailReport', req, {
			headers: {'content-type': 'application/json'},
			responseType: 'arraybuffer',
		}).then(this.forceDownload);
	}

	public async itemRating(itemID: UUID): Promise<any> {
		return this.call<any[]>(Method.GET, `/itemRating/${itemID}`).then((r: any[]) => {
			return Lists.sort(Lists.default(r), 'created').reverse();
		});
	}

	public async discardDrafts(uids: UUID[]): Promise<void> {
		try {
			await this.call(Method.POST, `/discardDrafts`, uids);
			await this.refreshReferences(uids);
		} catch (e) {
			throw e; // fail promise
		}
	}

	public async accountsOverview(): Promise<void> {
		return this.call(Method.GET, `/accountsOverview`);
	}

	public async userStatusReport(): Promise<void> {
		return axios.get('/userStatusReport', {responseType: 'arraybuffer'}).then(this.forceDownload);
	}

	public async feedbackFormReport() {
		return axios.get('/feedbackFormReport/generic', {responseType: 'arraybuffer'}).then(this.forceDownload);
	}

	public async trialAccessSurveyReport() {
		return axios.get('/feedbackFormReport/trial', {responseType: 'arraybuffer'}).then(this.forceDownload);
	}

	public async userSubjectDetailsReport() {
		return axios.get('/userSubjectDetailsReport', {responseType: 'arraybuffer'}).then(this.forceDownload);
	}

	public async trialAccessUsersReport() {
		return axios.get('/trialAccessUsersReport', {responseType: 'arraybuffer'}).then(this.forceDownload);
	}

	public async ratingReport() {
		return axios.get('/ratingReport', {responseType: 'arraybuffer'}).then(this.forceDownload);
	}

	public async subjectUpdatesEmailsReport() {
		return axios.get('/subjectUpdatesEmailsReport', {responseType: 'arraybuffer'}).then(this.forceDownload);
	}

	public async stats(): Promise<void> {
		return this.call(Method.GET, `/statistics`);
	}

	public async productDataTree(): Promise<ProductDataTree> {
		return this.call(Method.GET, `/productDataTree`).then((res: any) => {
			return new ProductDataTree(res);
		});
	}

	public async unpublish(request: {uuids: UUID[], from?: Date | string}): Promise<void> {
		try {
			await this.call(Method.PUT, '/unpublish', request);
			await this.refreshReferences(request.uuids);
		} catch (e) {
			throw e; // fail promise
		}
	}

	public async publish(request: {
		uuids: UUID[],
		start_date?: Date | string,
		end_date?: Date | string,
		minor_change?: boolean,
		change?: string
	}): Promise<void> {
		try {
			await this.call(Method.PUT, '/publish', request);
			await this.refreshReferences(request.uuids);
		} catch (e) {
			throw e; // fail promise
		}
	}

	public async missingLinksCount(fileHash: string): Promise<{[key: string]: number}> {
		return this.call(Method.GET, `/missingLinksCount/${fileHash}`);
	}

	public async download(fileUID: UUID) {
		return axios.get('/asset/' + fileUID, {
			responseType: 'arraybuffer',
		}).then((response) => this.forceDownload(response));
	}

	public upload(file: File, callback?: (progress: IUploadProgress) => void, uploadURL: string = '/asset', body: object | null = null): [Promise<CMSFile>, CancelTokenSource] {
		const [res, cancel] = this.uploadFile<CMSFile>(uploadURL, file, callback, body);
		return [res.then(res => new CMSFile(res)), cancel];
	}

	public processKeyDates(fileUID: string, replaceFor: string = ''): Promise<any> {
		return this.call(Method.GET, `/processKeyDates/${fileUID}` + (fi(replaceFor, `?parent=${replaceFor}`, '')));
	}

	public checkKeyDateFile(fileUID: string): Promise<KeyDateFile | undefined> {
		return this.call(Method.GET, `/checkKeyDatesFile/${fileUID}`).then((res) => {
			if (res) {
				return InstanceOf(res, false);
			}
		});
	}

	public downloadKeyDatesTemplate() {
		return axios.get('/keyDatesTemplate', {responseType: 'arraybuffer'}).then(this.forceDownload);
	}

	public downloadTimetableDatesTemplate() {
		return axios.get('/timetableDatesTemplate', {responseType: 'arraybuffer'}).then(this.forceDownload);
	}

	public async getFeatureFlags(): Promise<IFeatureFlag[]> {
		return this.call(Method.GET, '/featureFlags');
	}

	public async updateFeatureFlag(flag: IFeatureFlag): Promise<IFeatureFlag> {
		return this.call(Method.PUT, `/featureFlags`, flag);
	}

	public async redeployFeatureFlags(): Promise<void> {
		return this.call(Method.GET, '/redeployFeatureFlags');
	}

	public async reorderTree(req: {parent: UUID | null | undefined, uuids: UUID[]}): Promise<any> {
		return this.call(Method.PUT, '/move', req);
	}

	public async getIndexStatus(): Promise<IOpenSearchStatus> {
		return this.call(Method.GET, '/indexStatus');
	}

	public async groupTagOrder(tag: string): Promise<string[]> {
		return this.call(Method.GET, `/groupTagOrder/${tag}`);
	}

	public async updateGroupTagOrder(tag: string, list: string[]): Promise<string[]> {
		return this.call(Method.POST, `/updateGroupTagOrder`, {groupTag: tag, tags: list});
	}

	public async moveItems(request: any): Promise<void> {
		return this.call(Method.PUT, `/move`, {...request})
	}

	public references<T>(uuids: UUID[]): T[] {
		const res: any[] = [];
		for (const uuid of uuids) {
			const ref = getRecoil(references(uuid));
			if (ref) {
				res.push(ref);
			}
		}
		return res;
	}

	public async bulkUpdate(uuids: UUID[], values: any): Promise<any> {
		return this.call(Method.PUT, '/items', {uuids, values});
	}

	public async bulkUploadFiles(): Promise<BulkFile[]> {
		return this.call(Method.GET, '/bulkFiles').then(res => {
			return Lists.default(res);
		});
	}

	public async deleteBulkFiles(ids: number[]): Promise<void> {
		return this.call(Method.POST, '/deleteBulkFiles', ids);
	}

	public async updateBulkFiles(items: BulkFile[]): Promise<BulkFile[]> {
		return this.call(Method.PUT, '/bulkFiles', items).then(res => {
			return Lists.default(res).map(f => new BulkFile(f));
		});
	}

	public async moveBulkItems(ids: number[], folder: UUID): Promise<void> {
		return this.call(Method.POST, '/moveBulkItems', {ids, folder});
	}

	public async addBulkItem(file: BulkFile): Promise<BulkFile> {
		return this.call(Method.POST, '/bulkFile', file).then((res) => {
			return new BulkFile(res);
		});
	}

	public parseTemplateFile(file: File, callback?: (progress: IUploadProgress) => void): [Promise<string[][][]>, CancelTokenSource] {
		const [res, cancel] = this.uploadFile<any[]>('/fromBulkTemplate', file, callback);
		return [res, cancel];
	}

	public uploadFileToFolder(file: File, folderId: number, callback?: (progress: IUploadProgress) => void): [Promise<string>, CancelTokenSource] {
		const [res, cancel] = this.uploadFile<string>(`/fileInFolder`, file,  callback, {folderId: folderId});
        return [res, cancel];
	}

	public async sendPreviewMail( payload: subjectNotificationEmail): Promise<string[]> {
		const payloadData = {
			subject: payload.subject,
			content: payload.content,
			page: payload.page,
		}
		if (payload.id){
			payloadData['id'] = payload.id
		}
		if (payload.to&&payload.to.length>0) {
			payloadData['to'] = payload.to
		}
		if (payload.schedule) {
			payloadData['schedule'] = payload.schedule
		}

		return this.call(Method.POST, `/emailUpdate`, payloadData);
	}

	private forceDownload(response) {
		const blob = new Blob([response.data], {
			type: response.headers['content-type'],
		});
		const link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		link.download = Strings.default(response.headers['content-disposition'].split('=')[1]).replace(/['"]+/g, '');
		link.click();
	}

	private toClassResults(data: IQueryResult<any>, skipCache: boolean = false): any {
		if (data) {
			data.references = Objects.default(data.references);
			// replace reference objects with classes

			transaction(({set, get}) => {
				for (let id in data.references) {
					const existing = get(references(id));
					const ref = InstanceOf(data.references[id]);
					if (existing && existing instanceof CMSObject && existing.complete() && existing.isNewerThan(ref)) {
						continue;
					}

					data.references[id] = ref;
					if (!skipCache) {
						set(references(id), ref);
					}
				}
				data.results = Lists.default<any>(data.results).map((item) => {
					const existing = get(references(item.__uuid));
					const obj = InstanceOf(item);
					if (existing && existing instanceof CMSObject && existing.complete() && existing.isNewerThan(obj)) {
						return existing;
					}
					if (!skipCache) {
						set(references(obj.getId()), obj);
					}
					return obj;
				});
			});
		}
		return data;
	}

	private async call<T>(method: Method, endpoint: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
		try {
			const response = await axios[method](endpoint, data, config);
			return response?.data;
		} catch (e: any) {
			if (e && e.response && e.response.data && e.response.data.error === 'Unauthorized') {
				this.deleteAuthToken();
				window.location.href = '/';
			} else if (e && e.response && e.response.data) {
				throw new Error(e.response.data.error);
			} else if (e && e.message) {
				throw new Error(e.message);
			}
			throw new Error(e);
		}
	}

	private async refreshReferences(uuids: UUID[]) {
		switch (uuids.length) {
			case 0:
				return;
			case 1:
				await this.get(uuids[0]);
				break;
			default:
				await this.query({
					types: this.getItemTypes(uuids),
					uuids,
				});
		}
	}

	private getItemTypes(uuids: UUID[]): Types[] {
		let types: Types[] = [];
		uuids.forEach(uuid => {
			const item = getRecoil(references(uuid)) as CMSObject;
			if (item) {
				types.push(item.getType());
			}
		});
		return Array.from(new Set(types));
	}

	private uploadFile<T>(url: string, file: any, onProgress?: (progress: IUploadProgress) => void, body?: any): [Promise<T>, CancelTokenSource] {
		const formData = new FormData();
		formData.append('file', file);
		if (body) {
			Object.keys(body).forEach(k => {
				formData.append(k, body[k]);
			});
		}
		const source = axios.CancelToken.source();
		const promise = this.call<T>(Method.POST, url, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			onUploadProgress: function(progressEvent) {
				const percentCompleted = Math.round(
					(progressEvent.loaded * 100) / Numbers.default(progressEvent.total, 1),
				);
				if (onProgress) {
					onProgress({fileName: file.name, percent: percentCompleted, cancel: source});
				}
			},
			cancelToken: (source || {}).token,
		});
		return [promise, source];
	}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new Client();
