import { CMSObject, DisplayMode, ItemRoutes } from './__CMSObject';
import { Types, UUID } from '../types';
import { Lists } from '../../utils/lists';
import { Strings } from '../../utils/strings';
import { getRecoil, setRecoil } from '../../state/recoilNexus';
import { cacheBuster, productDataSelector, references } from '../../state/state';
import { Assessment } from './Assessment';
import { QualificationSize } from './QualificationSize';
import { formField, linkedFormField } from '../../utils/decorators';
import { sessionAtom } from '../../state/session';
import { Messages } from '../../utils/messages';
import Client from '../client';
import { FormEvent, IFormEvents } from '../../components/Form/model';
import { QualificationGroupMapping } from './QualificationGroupMapping';
import { ISelectValue } from '../../components/Form/renderers/components/Select/SelectComponent';
import { Qualification } from './Qualification';

export class SubjectGroupMapping extends CMSObject implements IFormEvents {
	public static title = 'Subject Group Mapping';
	public static autoPublish = true;

	public title: string;
	public link: string;
	public description: string;
	@formField({
		name: 'Disabled',
	})
	public disabled: boolean;

	public qualification_group_mapping: UUID;

	@linkedFormField('qualification_group_mapping')
	@formField({
		flags: {
			dummyRequired: true,
		}
	})
	public specification_group: UUID[];
	@linkedFormField('qualification_group_mapping')
	@formField({
		flags: {
			dummyRequired: true,
		}
	})
	public qualification: UUID[];

	@linkedFormField('specification_group')
	@formField({
		flags: {
			dummyRequired: true,
		}
	})
	public assessment: UUID[];
	@formField({
		flags: {
			dummyRequired: true,
		}
	})
	@linkedFormField('qualification')
	public qualification_size: UUID[];

	// Fields used when SubjectGroupMapping class is part of the ProductDataTree.
	public assessments: Assessment[] = [];
	public qualificationSizes: QualificationSize[] = [];

	constructor(item: any = {}) {
		super(item);
		this.link = Strings.default(item.link);
		this.title = Strings.default(item.title);
		this.description = Strings.default(item.description);
		this.assessment = Lists.default(item.assessment);
		this.qualification_size = Lists.default(item.qualification_size);
		this.specification_group = Lists.default(item.specification_group);
		this.qualification = Lists.default(item.qualification);
		this.qualification_group_mapping = Strings.default(item.qualification_group_mapping);
		this.disabled = Boolean(item.disabled);
		getRecoil(productDataSelector);
	}

	public routes(): ItemRoutes {
		return {
			list: `/product`,
			edit: `/${this.getType()}/${this.getId()}`,
			create: `/${this.getType()}/create`,
		};
	}

	public displayLabel(options: DisplayMode = DisplayMode.SHORT): string {
		switch (options) {
			case DisplayMode.SHORT:
				return this.title;
			case DisplayMode.FULL:
				const assessmentCodes = this.assessment.map(a => getRecoil(references(a))).filter(a => a).map(a => (a as any as Assessment).code);
				let extra = '';
				if (assessmentCodes.length) {
					extra = ' (' + assessmentCodes.join(', ') + ')';
				}
				return `${this.title}${extra}`;
			default:
				return this.title;
		}
	}

	public formOnRenderField(evt: FormEvent): boolean {
		const qualificationMapping = getRecoil(references(evt.state.values.qualification_group_mapping)) as QualificationGroupMapping;
		const isTechnicals = qualificationMapping && qualificationMapping.technicals;

		if (evt.fieldUID === 'assessment' || evt.fieldUID === 'specification_group') {
			// this two fields we show when non technicals
			return !isTechnicals;
		} else if (evt.fieldUID === 'qualification_size' || evt.fieldUID === 'qualification') {
			// this two fields we show for technicals
			return isTechnicals;
		}

		return true;
	}

	public formOnFieldChange(evt: FormEvent) {
		switch (evt.fieldUID) {

			case Types.QUALIFICATION_GROUP_MAPPING:
				// When the qualification group changes, we need to reset the other lists
				evt.state.clearFields({
					assessment: [],
					specification_group: [],
					qualification_size: [],
					qualification: [],
				});
				break;

			case Types.SPECIFICATION_GROUP:
				// When the list of specification group changes, make sure
				// that all the assessments left on the object are part of the definined set of
				// specifications
				const specGroups = evt.fieldValue as string[];
				const assessments = Lists.default<string>(evt.state.values.assessment);
				const newList: string[] = [];
				assessments.forEach(a => {
					const assessment = getRecoil(references(a)) as Assessment;
					if (!assessment) {
						return;
					}
					if (Lists.intersects(assessment.specification_group, specGroups)) {
						newList.push(a);
					}
				});
				evt.state.setValue(Types.ASSESSMENT, newList);
				break;

			case Types.QUALIFICATION_GROUP:
				// when the qualification group changes, make sure
				// that all the qualification sizes left on the object are part of the definined set of
				// qualifications
				const qualGroups = evt.fieldValue as string[];
				const sizes = Lists.default<string>(evt.state.values.assessment);
				const sizeList: string[] = [];
				sizes.forEach(a => {
					const size = getRecoil(references(a)) as QualificationSize;
					if (!size) {
						return;
					}
					if (qualGroups.includes(size.qualification)) {
						sizeList.push(a);
					}
				});
				evt.state.setValue(Types.QUALIFICATION_SIZE, sizeList);
				break;
		}
	}

	public formOnFilterDataset(evt: FormEvent): ISelectValue[] {
		const dataset = Lists.default<ISelectValue>(evt.dataset);
		const qualificationMapping = getRecoil(references(evt.state.values.qualification_group_mapping)) as QualificationGroupMapping;

		switch (evt.fieldUID) {
			case Types.QUALIFICATION:
				if (qualificationMapping) {
					return dataset.filter(q => qualificationMapping.qualification_group.includes(q.object.qualification_group));
				}
				return dataset;
			case Types.SPECIFICATION_GROUP:
				if (qualificationMapping) {
					return dataset.filter(q => qualificationMapping.qualification_group.includes(q.object.qualification_group));
				}
				return [];
			case Types.ASSESSMENT:
				if (evt.state.values.specification_group.length > 0) {
					return dataset.filter(q => Lists.intersects(evt.state.values.specification_group, q.object.specification_group));
				}
				return [];
			case Types.QUALIFICATION_SIZE:
				const res = dataset
					.filter(q => evt.state.values.qualification.includes(q.object.qualification))
					.map(q => {
						const tmp = {...q};
						const qual = getRecoil(references(q.object.qualification)) as Qualification;
						if (qual) {
							tmp.label = `${qual.name} - ${q.object.name} - Level ${q.object.level}`;
						}
						return tmp;
					});
				const lvl2 = res.filter(q => q.object.level === 2);
				const lvl3 = res.filter(q => q.object.level === 3);

				const tmp: ISelectValue[] = [];
				if (lvl2.length > 0) {
					tmp.push({
						value: 'lvl2',
						label: 'LEVEL 2',
						object: {},
						options: lvl2,
					});
				}
				if (lvl3.length > 0) {
					tmp.push({
						value: 'lvl3',
						label: 'LEVEL 3',
						object: {},
						options: lvl3,
					});
				}
				return tmp;
			default:
				return dataset;
		}
	}

	public async canDelete(): Promise<string> {
		const user = getRecoil(sessionAtom);
		if (!user || !user.isPublisher()) {
			return Messages.NoPermissionToDelete;
		}
		const usage = await Client.checkUsage(this.getId());
		if (usage.items_count > 0) {
			return Messages.CantDeletePublishedAssociations;
		}
		return '';
	}

	public formOnSaveSuccess(): any {
		setRecoil(cacheBuster('productDataSelector'), (val) => val + 1);
	}

	public formOnValidate(evt: FormEvent): any {
		const errors: any = {};
		const tree = getRecoil(productDataSelector);
		const qualificationMapping = getRecoil(references(evt.state.values.qualification_group_mapping)) as QualificationGroupMapping;
		if (!qualificationMapping) {
			errors[Types.QUALIFICATION_GROUP_MAPPING] = Messages.FieldIsRequired;
		}
		if (qualificationMapping && qualificationMapping.technicals) {
			if (evt.state.values.qualification_size.length === 0) {
				errors[Types.QUALIFICATION_SIZE] = Messages.FieldIsRequired;
			}
			if (evt.state.values.qualification.length === 0) {
				errors[Types.QUALIFICATION] = Messages.FieldIsRequired;
			}
		} else {
			if (evt.state.values.specification_group.length === 0) {
				errors[Types.SPECIFICATION_GROUP] = Messages.FieldIsRequired;
			}
			if (evt.state.values.assessment.length === 0) {
				errors[Types.ASSESSMENT] = Messages.FieldIsRequired;
			} else {
				const tmp = evt.state.values.assessment.map(a => getRecoil(references(a))).reduce((acc, a) => {
					if (a.modular) {
						acc.modular++;
					} else {
						acc.linear++;
					}
					return acc;
				}, {modular: 0, linear: 0});

				if (tmp.modular > 0 && tmp.linear > 0) {
					errors[Types.ASSESSMENT] = Messages.CantMixModularAndLinear;
				}
			}
		}

		evt.state.values.assessment.forEach((id: string) => {
			const result = tree.getAssessment(id).filter(u => u);
			if (result.length > 0 && !this.assessment.includes(id)) {
				errors[Types.ASSESSMENT] = Messages.AssessmentDuplicate;
			}
		});
		return errors;
	}

	public formOnBeforeSave(evt: FormEvent): any {
		const qualificationMapping = getRecoil(references(evt.state.values.qualification_group_mapping)) as QualificationGroupMapping;
		const data = {...evt.state.values};
		if (qualificationMapping && !qualificationMapping.technicals) {
			delete data.qualification_size;
		}
		return data;
	}

}
