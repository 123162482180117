import React, {useContext, useMemo} from "react"
import {Field} from "../../../cms/models/__ModelInfo";
import {InputField} from "./components/InputField/InputField";
import {useFormField} from "../state";
import {FormContext} from "../Form";
import {fi} from "../../../utils/helpers";
import {TextArea} from "./components/TextArea/TextArea";
import Wysiwyg from "./components/Wysiwyg/Wysiwyg";
import styled from "@emotion/styled";
import {Strings} from "../../../utils/strings";

const WysiwygWrapper = styled.div`
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid var(--color-border-light);

    &.error {
      border: 1px solid var(--color-red);
    }
`

const TextRenderer = ({field, autoFocus}: { field: Field, autoFocus?: boolean }) => {
    const formField = useFormField(field.uid);
    const context = useContext(FormContext)

    const onChange = (e) => {
        formField.setValue(e.target.value);
    }

    const MaxLength = useMemo(() => {
        const validation = field.validations.find(v => v.type === 'text')
        if (validation) {
            return validation.config.maxLen
        }
        return
    }, [field])

    if (field.config.isHTML) {
        return (
            <>
                <label className={fi(field.flags.required, 'required')}>{field.name}</label>
                <WysiwygWrapper className={fi(formField.hasError(), 'error')}>
                    <Wysiwyg
                        height={field.config?.height}
                        value={Strings.default(formField.value)}
                        readOnly={formField.previewMode}
                        onChange={(val) => formField.setValue(val)}
                    />
                </WysiwygWrapper>
            </>
        )
    }

    if (field.config.isMultiline) {
        return (
            <>
                <label className={fi(field.flags.required || field.flags.dummyRequired, 'required')}>{field.name}</label>
                <TextArea
                    data-testid={`${context.id}-${field.uid}-field`}
                    required={field.flags.required}
                    error={Boolean(formField.error) && Boolean(formField.touched)}
                    value={Strings.default(formField.value)}
                    autoFocus={autoFocus}
                    rows={4}
                    disabled={formField.previewMode}
                    max={MaxLength}
                    onChange={onChange} />
            </>
        )
    }

    return (
        <>
            <label className={fi(field.flags.required, 'required')}>{field.name}</label>
            <InputField
                data-testid={`${context.id}-${field.uid}-field`}
                required={field.flags.required}
                type='text'
                autoFocus={autoFocus}
                disabled={formField.previewMode}
                error={formField.showError()}
                value={Strings.default(formField.value)}
                max={MaxLength}
                onChange={onChange}/>
        </>
    )
}

export default TextRenderer
