import {atom} from 'recoil';

export type BulkTableState = {
	editMode: boolean;
	selectedRange: number[];
	selectedCell: string[];
};

export type BulkTableColumnsState = {
	label: string;
	name: string;
	mandatory: boolean;
	centered: boolean;
	visible: boolean;
};

export const bulkTableState = atom<BulkTableState>({
    key: 'bulkTableState',
    default: {
        editMode: false,
        selectedRange: [],
        selectedCell: [],
	},
});

export const bulkTableProps = [
	{
		label: 'File',
		name: 'file',
		mandatory: false,
		centered: false,
		visible: true,
	},
	{
		label: 'Title',
		name: 'title',
		mandatory: true,
		centered: false,
		visible: true,
	},
	{
		label: 'Product mapping',
		name: 'productData',
		mandatory: true,
		centered: false,
		visible: true,
	},
	{
		label: 'Content type',
		name: 'content_type',
		mandatory: true,
		centered: false,
		visible: true,
	},
	{
		label: 'Paper Number',
		name: 'paper_number',
		mandatory: false,
		centered: true,
		visible: true,
	},
	{
		label: 'Description',
		name: 'description',
		mandatory: false,
		centered: false,
		visible: true,
	},
	{
		label: 'Exam year',
		name: 'exam_year',
		mandatory: false,
		centered: true,
		visible: true,
	},
	{
		label: 'Series',
		name: 'series',
		mandatory: false,
		centered: true,
		visible: true,
	},
	{
		label: 'Tag',
		name: '__tag',
		mandatory: false,
		centered: true,
		visible: true,
	},
	{
		label: 'Date available to public',
		name: 'public_from',
		mandatory: false,
		centered: true,
		visible: true,
	},
];

export const bulkTableColumnsState = atom<BulkTableColumnsState[]>({
	key: 'bulkTableColumnsState',
	default: bulkTableProps
});

export const selectedRows = atom<number[]>({
    key: "bulkSelectedRows",
    default: []
})

export const ConfigKey: string = `bulkTableColumnState`
export const DefaultColumnWidths: number[] = Array.from(new Array(9)).map(a => -1)
