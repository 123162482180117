import { CMSObject, ItemRoutes } from './__CMSObject';
import {Types, UUID} from '../types';
import { Lists } from '../../utils/lists';
import { Strings } from '../../utils/strings';
import { SubjectGroupMapping } from './SubjectGroupMapping';
import { formField, tableHidden } from '../../utils/decorators';
import { getRecoil, setRecoil } from '../../state/recoilNexus';
import { sessionAtom } from '../../state/session';
import { Messages } from '../../utils/messages';
import Client from '../client';
import {cacheBuster, productDataSelector} from '../../state/state';
import { FormEvent } from '../../components/Form/model';
import { OCR_STREAM } from '../../utils/constants';

export class QualificationGroupMapping extends CMSObject {
	public static title = 'Qualification Group Mapping';
	public static autoPublish = true;

	public title: string;
	public description: string;
	@formField({
		name: 'Disabled',
	})
	public disabled: boolean;
	public qualification_group: UUID[];
	@tableHidden
	public technicals: boolean;

	// This field is used when QualificationGroupMapping class is part of the ProductDataTree.
	public subjects: SubjectGroupMapping[] = [];

	constructor(item: any = {}) {
		super(item);

		this.title = Strings.default(item.title);
		this.description = Strings.default(item.description);
		this.disabled = Boolean(item.disabled);
		this.technicals = Boolean(item.technicals);
		this.qualification_group = Lists.default(item.qualification_group);
		getRecoil(productDataSelector);
	}

	public routes(): ItemRoutes {
		return {
			list: `/product`,
			edit: `/${this.getType()}/${this.getId()}`,
			create: `/${this.getType()}/create`,
		};
	}

	public formOnSaveSuccess(): any {
		setRecoil(cacheBuster('productDataSelector'), (val) => val + 1);
	}

	public async canDelete(): Promise<string> {
		const user = getRecoil(sessionAtom);
		if (!user || !user.isPublisher()) {
			return Messages.NoPermissionToDelete;
		}
		const usage = await Client.checkUsage(this.getId());
		if (usage.items_count > 0) {
			return Messages.CantDeletePublishedAssociations;
		}
		return '';
	}

	formOnBeforeSave(evt: FormEvent): any {
		const data = {...evt.state.values};
		const userSession = getRecoil(sessionAtom);
		let businessStream = OCR_STREAM;

		if (userSession && userSession.selectedBusinessStream) {
			businessStream = userSession.selectedBusinessStream;
		}

		if (businessStream === OCR_STREAM && (data.title.toLowerCase().includes('technicals') || data.title.toLowerCase().includes('advanced'))) {
			data.technicals = true;
		}
		return data;
	}

	public formOnValidate(evt: FormEvent): any {
		const errors: any = {}
		const data = {...evt.state.values};
		const tree = getRecoil(productDataSelector);

		data.qualification_group.forEach((id: string) => {
			tree.qualifications.forEach(qm => {
				const found = qm.qualification_group.findIndex(q => q === id)
				if (found !== -1) {
					errors[Types.QUALIFICATION_GROUP] = Messages.QualificationDuplicate
					return;
				}
			})

		});
		return errors;
	}
}
