import styled from '@emotion/styled';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import CheckBox from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import TableSortLabel from '@mui/material/TableSortLabel';
import React, { useMemo } from 'react';
import { fi } from '../../utils/helpers';
import { Lists } from '../../utils/lists';
import { Objects } from '../../utils/objects';
import { Strings } from '../../utils/strings';
import { TableColumnDefinition } from './config';
import ColumnFilter from './columnFilters/ColumnFilter';
import { useTable, useTableState } from './state';

const Row = styled.tr`
    border-top: 1px solid var(--color-grey);
    border-bottom: 1px solid var(--color-grey);
    font-size: 16px;
    user-select: none;
`;

const THead = styled.thead`
`;

const Cell = styled.th`
  font-family: var(--font-semi-bold);
  font-weight: 500;

  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.fixed {
    font-family: var(--font-semi-bold);
    height: 62px;
    margin-top: -1px;
    border-top-width: 1.1px;

		@-moz-document url-prefix() {
			margin-top: -37px;
		}
  }

  &.fixed-start {
    height: 62px;
    width: 60px;
		margin-top: -1px;

		@-moz-document url-prefix() {
			margin-top: -37px;
		}
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 60px;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const NameWrapper = styled.div`
  padding: 8px 0;
  display: flex;
  align-items: center;

  .unsortable {
    padding: 16px;
  }
`;

const Splitter = styled.div`
  cursor: ew-resize;
  margin: 0 4px;
  padding: 4px;
  background: url('data:image/gif;base64,R0lGODlhAQABAPcAAAAAAAAAMwAAZgAAmQAAzAAA/wArAAArMwArZgArmQArzAAr/wBVAABVMwBVZgBVmQBVzABV/wCAAACAMwCAZgCAmQCAzACA/wCqAACqMwCqZgCqmQCqzACq/wDVAADVMwDVZgDVmQDVzADV/wD/AAD/MwD/ZgD/mQD/zAD//zMAADMAMzMAZjMAmTMAzDMA/zMrADMrMzMrZjMrmTMrzDMr/zNVADNVMzNVZjNVmTNVzDNV/zOAADOAMzOAZjOAmTOAzDOA/zOqADOqMzOqZjOqmTOqzDOq/zPVADPVMzPVZjPVmTPVzDPV/zP/ADP/MzP/ZjP/mTP/zDP//2YAAGYAM2YAZmYAmWYAzGYA/2YrAGYrM2YrZmYrmWYrzGYr/2ZVAGZVM2ZVZmZVmWZVzGZV/2aAAGaAM2aAZmaAmWaAzGaA/2aqAGaqM2aqZmaqmWaqzGaq/2bVAGbVM2bVZmbVmWbVzGbV/2b/AGb/M2b/Zmb/mWb/zGb//5kAAJkAM5kAZpkAmZkAzJkA/5krAJkrM5krZpkrmZkrzJkr/5lVAJlVM5lVZplVmZlVzJlV/5mAAJmAM5mAZpmAmZmAzJmA/5mqAJmqM5mqZpmqmZmqzJmq/5nVAJnVM5nVZpnVmZnVzJnV/5n/AJn/M5n/Zpn/mZn/zJn//8wAAMwAM8wAZswAmcwAzMwA/8wrAMwrM8wrZswrmcwrzMwr/8xVAMxVM8xVZsxVmcxVzMxV/8yAAMyAM8yAZsyAmcyAzMyA/8yqAMyqM8yqZsyqmcyqzMyq/8zVAMzVM8zVZszVmczVzMzV/8z/AMz/M8z/Zsz/mcz/zMz///8AAP8AM/8AZv8Amf8AzP8A//8rAP8rM/8rZv8rmf8rzP8r//9VAP9VM/9VZv9Vmf9VzP9V//+AAP+AM/+AZv+Amf+AzP+A//+qAP+qM/+qZv+qmf+qzP+q///VAP/VM//VZv/Vmf/VzP/V////AP//M///Zv//mf//zP///wAAAAAAAAAAAAAAACH5BAEAAPwALAAAAAABAAEAAAgEADMFBAA7');
  opacity: 0.2;
 background-repeat: no-repeat repeat;
  height: 30px;

  &.last {
    margin-right: -1px;
    padding-right: 0;
  }
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;


const TableHeader = () => {
	const {preferences, actions: {setOrder, selectAll, deselectAll, setColumnPreferences}} = useTable();
	const state = useTableState()

	const createSortHandler = (property: string) => (_event: React.MouseEvent<unknown>) => {
		setOrder(property).catch();
	};

	const columns: any[] = useMemo(() => {
		return preferences.columns.map(c => {
			if (!c.width) {
				return null;
			}

			const column = Lists.default<TableColumnDefinition>(state.config.columns).find(i => i.field === c.field);
			if (!column) {
				return null;
			}

			return {
				definition: column,
				field: c.field,
				width: fi(c.width === -1, 'auto', `${c.width}px`),
				sortable: Boolean(column.sortable),
				label: Strings.default(Objects.default(column).label, c.field),
			};
		}).filter(c => c);
	}, [ state, preferences ]);

	const selected = useMemo(() => {
		let selected = 0;
		for (const key in state.selected) {
			selected += fi(state.selected[key], 1, 0);
		}
		return selected;
	}, [ state.selected ]);

	const toggleSelectAll = async () => {
		if (selected === state.items.total) {
			deselectAll();
			return;
		}
		return selectAll();
	};

	const setWidth = (field: string, width: number) => {
		const cols = [...preferences.columns]
		for (let i = 0; i < cols.length; i++) {
			if (cols[i].field === field) {
				cols[i] = {...cols[i], width: Math.round(width)}
			}
		}
		setColumnPreferences(cols);
	}

	const onMouseDown = (event: any, cell: any) => {
		const parent = event.target.parentElement?.parentElement.parentElement;
		const originalWidth = parent.getBoundingClientRect().width;

		let thisMouseUp;
		let thisMouseMove;
		let startX = -1;
		let width = 0;

		const mouseMove = (evt) => {
			if (startX === -1) {
				startX = evt.pageX;
				return;
			}
			const delta = evt.pageX - startX;
			width = (originalWidth + delta);
			parent.style.minWidth = width + 'px';
			parent.style.width = width + 'px';
		};
		thisMouseMove = mouseMove.bind(this);

		const mouseUp = (_evt) => {
			if (width) {
				setWidth(cell.field, width)
			}
			window.removeEventListener('mousemove', thisMouseMove);
			window.removeEventListener('mouseup', thisMouseUp);
			startX = -1;
		};
		thisMouseUp = mouseUp.bind(this);

		window.addEventListener('mousemove', thisMouseMove);
		window.addEventListener('mouseup', thisMouseUp);
	};

	// Set automatic width of a column when double-clicking the resize handle of a cell
	const onDoubleClick = (event: any, field: string, cellIndex: number) => {
		// get the nearest table element from the event target
		const parent = event.target.closest('table');
		if (!parent) {
			return;
		}
		const elements = document.querySelectorAll(`table>tbody>tr>td:nth-child(${cellIndex + fi(state.config.manualPublish, 2, 1)})`)
		const tmp: any = document.createElement('div');
		tmp.style = 'white-space: nowrap; display:inline-block;';
		parent.parentElement.appendChild(tmp);
		let max = 0;
		elements.forEach(elem => {
			tmp.innerHTML = elem.innerHTML
			max = Math.max(tmp.getBoundingClientRect().width, max)
			tmp.innerHTML = '';
		})
		parent.parentElement.removeChild(tmp);
		if (max > 0) {
			setWidth(field, max + 34) // cell padding and borders added to the content
		}
	}

	return (
		<THead>
		<Row>
			{state.config.manualPublish && (
				<Cell className="fixed-start">
					<Checkbox
						className="form-checkbox"
						aria-label="Select all from table"
						id={`select-all-checkbox`}
						name={`select-all`}
						checked={selected > 0 && selected === state.items.total}
						indeterminate={selected > 0 && selected !== state.items.total}
						tabIndex={0}
						color="primary"
						checkedIcon={<CheckBox/>}
						size="small"
						onChange={toggleSelectAll}
						disableRipple
					/>
				</Cell>
			)}
			{columns.map((c, idx) => (
				<Cell data-testid={`table-header-${c.label.replace(/\s/g, '').toLowerCase()}`} key={idx} style={{width: c.width, minWidth: c.width}}>
					<Wrapper>
						<NameWrapper>
							{fi(c.sortable, (
									<TableSortLabel
										active={preferences.order === c.field}
										direction={fi(preferences.order === c.field, preferences.sort, 'desc')}
										IconComponent={ArrowDropDown}
										onClick={createSortHandler(c.field)}
										data-testid="content-table-sort"
									>
										{c.label}
									</TableSortLabel>
								),
								<span className={'unsortable'}>{c.label}</span>,
							)}
						</NameWrapper>
						<RightSide>
							<ColumnFilter definition={c.definition} />
							<Splitter className={fi(columns.length - 1 === idx, 'last', '')}
									  onDoubleClick={(event) => onDoubleClick(event, c.field, idx)}
									  onMouseDown={(event) => onMouseDown(event, c)}/>
						</RightSide>
					</Wrapper>
				</Cell>
			))}
			{!state.config.hideActionColumn&&<Cell className="fixed">Actions</Cell>}
		</Row>
		</THead>
	);
};

export default TableHeader;
