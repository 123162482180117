import {fi} from '../../utils/helpers';
import {Types} from '../types';
import {CMSObject} from './__CMSObject';
import {Assessment} from './Assessment';
import {ChildAssessment} from './ChildAssessment';
import {Component} from './Component';
import {ContentGroup} from './ContentGroup';
import {ContentType} from './ContentType';
import {CTUnit} from './CTUnit';
import {CMSFile} from './File';
import {Folder} from './Folder';
import {Library} from './__Library';
import {Page} from './Page';
import {Pathway} from './Pathway';
import {Qualification} from './Qualification';
import {QualificationGroup} from './QualificationGroup';
import {QualificationSize} from './QualificationSize';
import {SpecificationGroup} from './SpecificationGroup';
import {Subject} from './Subject';
import {SubjectUpdate} from './SubjectUpdate';
import {Topic} from './Topic';
import {UsefulLink} from './UsefulLink';
import {BusinessStream} from './BusinessStream';
import {Document} from './Document';
import {Audio} from './Audio';
import {Link} from './Link';
import {EventMaterial, EventsAir} from './EventMaterial';
import {Video} from './Video';
import {Text} from './Text';
import {Notification} from './Notification';
import {HelpInfo} from './HelpInfo';
import {Image} from './Image';
import {Note} from './Note';
import {QualificationGroupMapping} from './QualificationGroupMapping';
import {SubjectGroupMapping} from './SubjectGroupMapping';
import {ProductMapping} from './__ProductMapping';
import {TimeTables} from './TimeTables';
import {KeyDates} from './KeyDates';
import {KeyDateFile} from './KeyDateFile';
import {PublishSchedule} from './_PublishSchedule';
import './widgets';
import {ContentGroupWidget} from './ContentGroupWidget';
import {ContentTypeWidget} from './ContentTypeWidget';
import {ContentItemsWidget} from './ContentItemsWidget';
import {ChildPagesWidget} from './ChildPagesWidget';
import {FAQWidget} from './FAQWidget';
import {EventsWidget} from './EventsWidget';
import {SubjectFilterWidget} from './SubjectFilterWidget';
import {YearFilterWidget} from './YearFilterWidget';
import {EventsFilterWidget} from './EventsFilterWidget';
import {UsefulLinksWidget} from './UsefulLinksWidget';
import {HomepageResourcesWidget} from './HomepageResourcesWidget';
import {HomepageWidget} from './HomepageWidget';
import {SubjectUpdatesWidget} from './SubjectUpdatesWidget';
import {SizePathwayWidget} from './SizePathwayWidget';
import {FormWidget} from './FormWidget';
import {QuickLinksWidget} from './QuickLinksWidget';
import {PageContentWidget} from './PageContentWidget';
import {BulkEdit} from './_BulkEdit';
import {BulkFile} from './BulkFile';
import {SubjectEmailNotification} from "./__SubjectEmailNotification";
import {PreviewMail} from "./__PreviewMail";
import {KeyDatesWidget} from './KeyDatesWidget';
import {MoveItems} from './_MoveItems';
import {TileContent} from './_TileContent';
import {ExtendedLibrary} from "./__ExtendedLibrary";
import {GlobalAnnouncementsWidget} from "./GlobalAnnouncementsWidget";
import {ResourceFinderWidget} from './ResourceFinderWidget';
import {MySubjectsWidget} from './MySubjectsWidget';
import { ContentCategory } from './ContentCategory';
import {PathwayConfig, PathwayWidget} from "./PathwayWidget";
import {SubjectsListWidget} from './SubjectsListWidget';

// Holds a mapping of what CMS type is an instance of what class
const classRegistry = {
	[Types.BUSINESS_STREAM]: BusinessStream,
	[Types.QUALIFICATION_GROUP]: QualificationGroup,
	[Types.SPECIFICATION_GROUP]: SpecificationGroup,
	[Types.QUALIFICATION]: Qualification,
	[Types.SUBJECT]: Subject,
	[Types.ASSESSMENT]: Assessment,
	[Types.QUALIFICATION_SIZE]: QualificationSize,
	[Types.CHILD_ASSESSMENT]: ChildAssessment,
	[Types.COMPONENT]: Component,
	[Types.ITEM_NOTE]: Note,
	[Types.PATHWAY]: Pathway,
	[Types.CT_UNIT]: CTUnit,
	[Types.FOLDER]: Folder,
	[Types.CONTENT_TYPE]: ContentType,
	[Types.CONTENT_CATEGORY]: ContentCategory,
	[Types.CONTENT_GROUP]: ContentGroup,
	[Types.PAGE]: Page,
	[Types.USEFUL_LINK]: UsefulLink,
	[Types.SUBJECT_UPDATES]: SubjectUpdate,
	[Types.TOPIC]: Topic,
	[Types.DOCUMENT]: Document,
	[Types.AUDIO]: Audio,
	[Types.LINK]: Link,
	[Types.VIDEO]: Video,
	[Types.IMAGE]: Image,
	[Types.TEXT]: Text,
	[Types.EVENT_MATERIAL]: EventMaterial,
	[Types.EVENT]: EventsAir,
	[Types.HELP_INFO]: HelpInfo,
	[Types.NOTIFICATIONS]: Notification,
	[Types.QUALIFICATION_GROUP_MAPPING]: QualificationGroupMapping,
	[Types.SUBJECT_GROUP_MAPPING]: SubjectGroupMapping,
	[Types.TIMETABLE]: TimeTables,
	[Types.KEY_DATE]: KeyDates,
	[Types.KEY_DATES_FILE]: KeyDateFile,

	// There below are not CMS models but wrappers required by some table pages
	[Types.EXTENDED_LIBRARY]: ExtendedLibrary,
	[Types.LIBRARY]: Library,
	[Types.PRODUCT_MAPPING]: ProductMapping,
	[Types.PUBLISH_SCHEDULE]: PublishSchedule,
	[Types.CONTENT_GROUP_WIDGET]: ContentGroupWidget,
	[Types.CONTENT_TYPE_WIDGET]: ContentTypeWidget,
	[Types.CONTENT_ITEMS_WIDGET]: ContentItemsWidget,
	[Types.CHILD_PAGES_WIDGET]: ChildPagesWidget,
	[Types.FAQ_WIDGET]: FAQWidget,
	[Types.EVENTS_WIDGET]: EventsWidget,
	[Types.QUALIFICATION_FILTER_WIDGET]: SubjectFilterWidget,
	[Types.YEAR_FILTER_WIDGET]: YearFilterWidget,
	[Types.EVENTS_FILTER_WIDGET]: EventsFilterWidget,
	[Types.FREE_TEXT_WIDGET]: PageContentWidget,
	[Types.USEFUL_LINKS_WIDGET]: UsefulLinksWidget,
	[Types.HOMEPAGE_RESOURCES_WIDGET]: HomepageResourcesWidget,
	[Types.HOMEPAGE_WIDGET]: HomepageWidget,
	[Types.GLOBAL_ANNOUNCEMENTS_WIDGET]: GlobalAnnouncementsWidget,
	[Types.RESOURCE_FINDER_WIDGET]: ResourceFinderWidget,
	[Types.MY_SUBJECTS_WIDGET]: MySubjectsWidget,
	[Types.SUBJECT_UPDATES_WIDGET]: SubjectUpdatesWidget,
	[Types.KEY_DATES_WIDGET]: KeyDatesWidget,
	[Types.SIZE_PATHWAY_WIDGET]: SizePathwayWidget,
	[Types.FORM_WIDGET]: FormWidget,
	[Types.QUICK_LINKS_WIDGET]: QuickLinksWidget,
	[Types.SUBJECTS_LIST_WIDGET]: SubjectsListWidget,
	[Types.BULK_EDIT]: BulkEdit,
	[Types.SCHEDULE_EMAIL]: SubjectEmailNotification,
	[Types.PREVIEW_MAIl]: PreviewMail,
	[Types.BULK_FILE]: BulkFile,
	[Types.MOVE_ITEMS]: MoveItems,
	[Types.TILE_CONTENT]: TileContent,
	[Types.PATHWAY_WIDGET]: PathwayWidget,
	[Types.PATHWAY_CONFIG]: PathwayConfig,
};

// Class of returns a Class from a Type that is registered in the classRegistry. If the class is not registered,
// or doesn't exist, it will return the CMSObject class if useDefault is true, otherwise it will return null
export const ClassOf = (type: Types | string, useDefault: boolean = true): any => {
	return classRegistry[type] || (fi(useDefault, CMSObject, null));
};

// InstanceOf returns an instance of a given class type or attempts to instantiate it from an object returned
// from the server that has the __type property indicating the class type.
// If the class type is not registered in the classRegistry then it will instantiate
// a CMSObject class if useDefault is true or return null if useDefault is false
export const InstanceOf = (type: Types | any, useDefault: boolean = true): any => {
	if (typeof type === 'string') {
		const cls: any = ClassOf(type as Types, useDefault);
		if (cls) {
			return new cls({__type: type});
		}
	} else if (typeof type === 'object') {
		if (type.__type) {
			const cls: any = ClassOf(type.__type, useDefault);
			if (cls) {
				return new cls(type);
			}
		} else if (type.fileuid) {
			return new CMSFile(type);
		}
	}

	if (useDefault) {
		return new CMSObject({__type: type});
	}

	return null;
};
