import styled from "@emotion/styled";
import Input from "@mui/material/Input";
import React, {memo, useEffect, useMemo, useState} from "react";
import {ColumnFilterProps} from './ColumnFilter';
import {useTableActions} from "../state";
import {Lists} from "../../../utils/lists";
import {Strings} from "../../../utils/strings";
import {areEqual, FixedSizeList as List} from 'react-window';
import {ColumnPreference} from "../preferences";
import memoize from 'memoize-one';
import PageLoader from "../../Loader/PageLoader";
import {fi} from "../../../utils/helpers";
import {ColumnType} from "../renderers";
import {getRecoil} from "../../../state/recoilNexus";
import {references} from "../../../state/state";
import {DisplayMode} from "../../../cms/models/__CMSObject";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 150px;
`

const SearchBox = styled(Input)`
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--color-border-light);
  border-radius: 0;
`

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
`

const ListRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  padding: 0 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  label {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  label, input {
    cursor: pointer;
  }

  &.selected {
    background-color: var(--color-selected);
  }

  &:hover {
    background-color: var(--color-box-shadow);
  }

  input[type="checkbox"] {
    accent-color: dodgerblue;
  }
`

const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-red);
  height: 150px;
`

const EmptyMessage = styled(ErrorMessage)`
  color: var(--color-grey);
`

const Row = memo(({data, index, style}: any) => {
    const {items, toggleItemActive} = data;
    const item = items[index];

    return (
        <ListRow style={style} className={fi(item.isActive, 'selected')} title={item.label}>
            <input type={'checkbox'} onChange={(evt) => toggleItemActive(item, evt.target.checked)}
                   checked={item.isActive} id={`checkbox-${index}`}/>
            <label htmlFor={`checkbox-${index}`}>{item.label}</label>
        </ListRow>
    )
}, areEqual);

const createItemData = memoize((items, toggleItemActive) => ({
    items,
    toggleItemActive,
}));

const ListFilter = (props: ColumnFilterProps & { preferences: ColumnPreference }) => {
    const [search, setSearch] = useState<string>('')
    const [loading, setLoading] = useState(true)
    const [items, setItems] = useState<Array<any[]>>([]);
    const [error, setError] = useState<string>('')
    const {getFieldDistinctValues} = useTableActions()

    useEffect(() => {
        getFieldDistinctValues(props.definition.field).then(res => {
            setItems(res);
            setLoading(false);
        }).catch(err => {
            setError(err)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const toggleItem = (item, val) => {
        const list: string[] = [...Lists.default<string>(props.preferences.filter)]
        if (!val) {
            list.splice(list.indexOf(item.value), 1)
        } else {
            list.push(item.value)
        }
        if (list.length > 0) {
            props.onChange!(list)
        } else {
            props.onChange!(null)
        }
    }

    const onSearch = (e) => {
        setSearch(e.target.value.toLowerCase().trim())
    }

    const filtered = useMemo(() => {
        const getLabel = (i) => {
            if (props.definition.filter && props.definition.filter.getLabel) {
                return props.definition.filter.getLabel(i)
            }
            if (props.definition.type === ColumnType.Reference) {
                return Strings.default(getRecoil(references(i))?.displayLabel(DisplayMode.FULL), '-')
            }
            return Strings.default(i, '-')
        }

        const currentFilter = Lists.default<string>(props.preferences.filter)
        let list: any[] = [...items]


        const val = createItemData(list.map(i => ({
            label: getLabel(i),
            value: i,
            isActive: currentFilter.includes(i),
            toggleItem
        })), toggleItem)

        if (search) {
            val.items = val.items.filter(item => item.label.toLowerCase().includes(search))
        }

        Lists.sort(val.items, 'label')
        return val
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.preferences, items, search])


    // Loading state
    if (loading) {
        return (
            <Wrapper>
                <PageLoader size={24}/>
            </Wrapper>
        )
    }

    // Error state
    if (error) {
        return (
            <Wrapper>
                <ErrorMessage>{error}</ErrorMessage>
            </Wrapper>
        )
    }

    // Empty state
    if (!filtered.items.length) {
        return (
            <Wrapper>
                {(filtered.items.length > 5 || search) && (
                    <SearchBox type='search' autoFocus name='filter-options' placeholder='Search ...'
                               onChange={onSearch}/>
                )}
                <EmptyMessage>There are no items</EmptyMessage>
            </Wrapper>
        )
    }

    // Normal state
    return (
        <Wrapper>
            <>
                {(filtered.items.length > 5 || search) && (
                    <SearchBox type='search' autoFocus name='filter-options' placeholder='Search ...'
                               onChange={onSearch}/>
                )}
                <ListWrapper>
                    <List
                        height={150}
                        itemSize={35}
                        itemData={filtered}
                        itemCount={filtered.items.length}
                        width={240}>
                        {Row}
                    </List>
                </ListWrapper>
            </>
        </Wrapper>
    );
};

export default ListFilter;
