import React from "react";
import styled from "@emotion/styled";
import {Node} from "./utils";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {fi} from "../../../../../utils/helpers";
import {DisplayMode} from "../../../../../cms/models/__CMSObject";
import ListCheckbox from '../../../../commons/ListCheckbox';

const NodeWrapper = styled.li`
  cursor: default;
  display: flex;
  flex-direction: row;
  height: 30px;
  align-items: center;
`

const ToggleWrapper = styled.div`
  width: 20px;

  svg {
    height: 20px;
  }
`

const Small = styled.span`
  font-size: 14px;
  margin-left: 16px;
  color: var(--color-border)
`



type ITreeNodeProps = {
    node: Node;
    query: string;
    onToggle: (node: Node) => void;
    onSelect: (node: Node) => void;
}

const TreeNode = ({node, onToggle, onSelect, query}: ITreeNodeProps) => {
    const childLength = node.children.length;

    const classNames = () => {
        const classes: string[] = [];
        if (node.selected) {
            classes.push("checkbox-img-checked")
        } else {
            if (node.selectedChildren > 0) {
                classes.push("checkbox-img-intermediate")
            } else {
                classes.push("checkbox-img-unchecked")
            }
        }
        return classes.join(" ")
    }

    return (
        <NodeWrapper data-uuid={node.id} style={{marginLeft: node.depth * fi(Boolean(query), 0, 16)}}>
            {!Boolean(query) &&
                <ToggleWrapper onClick={() => onToggle(node)}>
                    {fi(childLength, fi(node.expanded, <ExpandLess/>, <ExpandMore/>))}
                </ToggleWrapper>
            }
            <ListCheckbox className={classNames()} onClick={() => onSelect(node)}></ListCheckbox>
            <span>{node.object.displayLabel(DisplayMode.DETAILED)}</span>
            {Boolean(query) &&
                <Small>{fi(node.parents.length, node.parents[0].object.displayLabel(DisplayMode.DETAILED))}</Small>
            }
        </NodeWrapper>
    )
}

export default TreeNode