import {FieldType, ModelInfo} from './__ModelInfo';
import {Types} from '../types';
import {BaseClass} from './__base';
import {formField} from '../../utils/decorators';
import {IFormEvents, FormEvent} from '../../components/Form/model';

const Model = new ModelInfo({
	model: {
		uid: Types.TILE_CONTENT,
		name: 'Tile content',
	},
	fields:[]
})

export class TileContent extends BaseClass implements IFormEvents {
	public static model = Model;
	public static folderId = 3

	constructor(item: any = {}) {
		super(item);
	}

	@formField({
		fieldtype: FieldType.File,
		flags: {required: true, multiple: false},
		name: 'Image',
		config: {
			uploadURL: '/fileInFolder',
			body: 'tile-content',
		},
		validations: [
			{
				type: FieldType.File,
				config: {
					maxSize: 5000000,
					allow: 'jpeg,JPEG,jpg,JPG,png,PNG,jpe,JPE,jfif,JFIF',
				},
			},
		],
	})
	public image: string = '';

	@formField({
		fieldtype: FieldType.Text,
		flags: {required: true},
		name: 'Title',
		validations: [
			{
				type: FieldType.Text,
				config: {
					maxLen: 30,
				},
			},
		],
	})
	public title: string = '';


	@formField({
		fieldtype: FieldType.Text,
		name: 'Description',
		config: {isMultiline: true},
		validations: [
			{
				type: FieldType.Text,
				config: {
					maxLen: 148,
				},
			},
		],
	})
	public description: string = '';

	@formField({
		fieldtype: FieldType.Text,
		flags: {required: true},
		name: 'Alternative Text',
		validations: [
			{
				type: FieldType.Text,
				config: {
					maxLen: 30,
				},
			},
		],
	})
	public alternativeText: string = '';

	@formField({
		fieldtype: FieldType.Text,
		flags: {required: true},
		name: 'Link',
		validations: [
			{
				type: FieldType.Text,
				config: {
					maxLen: 480,
				},
			},
		],
	})
	public link: string = '';

	public formOnValidate(evt: FormEvent): any {
		const errors: any = {};
		// check if image has empty value
		if (!evt.state.values.image) {
			errors['image'] = 'Image is required';
		}
		// check if title has no value
		if (!evt.state.values.title) {
			errors['title'] = 'Title is required';
		}
		// check if alternative text has no value
		if (!evt.state.values.alternativeText) {
			errors['alternativeText'] = 'Alternative text is required';
		}
		// check if link has no value
		if (!evt.state.values.link) {
			errors['link'] = 'Link required';
		}
		return errors;
	}
}
