import { Field } from '../../../cms/models/__ModelInfo';
import React, { useEffect, useMemo, useState } from 'react';
import Form from '../Form';
import { useForm, useFormField } from '../state';
import { MultipleOptionsWrapper } from './ReferenceRenderer';
import { fi, StrictModeDroppable } from '../../../utils/helpers';
import styled from '@emotion/styled';
import { Lists } from '../../../utils/lists';
import { Draggable } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { DisplayMode } from '../../../cms/models/__CMSObject';
import Spacer from '../../commons/Spacer';
import CloseRounded from '@mui/icons-material/CloseRounded';
import { InstanceOf } from '../../../cms/models';
import { getFormConfig } from '../../../utils/decorators';
import FormRow from '../FormRow';
import Button from '@mui/material/Button';
import EditNote from '@mui/icons-material/EditNote';
import { ActionButton } from '../../commons/ActionButton';
import ExpandLessRounded from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRounded from '@mui/icons-material/ExpandMoreRounded';
import { useRecoilState } from 'recoil';
import { dragAndDropState } from '../../../state/state';

const Tabs = styled.div`
`;

const Children = styled.div`
    padding: 8px 32px;
    border-left: 1px solid var(--color-border-light);
    margin-left: 23px;

    &:empty {
        display: none;
    }
`;

const FormActions = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;

const Tab = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 16px;
    cursor: default;

    &:hover {
        background-color: var(--color-box-shadow);
    }

    svg {
        color: var(--color-border);
        margin-top: 3px;
        width: 16px;
        height: 16px;
        transition: color 0.2s ease-in-out;
    }

    span:first-of-type {
        cursor: grab;
        margin-right: 3px;
    }

    .edit {
        cursor: pointer;

        &.disabled {
            pointer-events: none;
            opacity: 0.5;
        }

        &:hover svg {
            color: var(--color-blue);
        }
    }

    .remove {
        cursor: pointer;

        &.disabled {
            pointer-events: none;
            opacity: 0.5;
        }

        &:hover svg {
            color: var(--color-red);
        }
    }
`;

const EmptyState = styled.p`
    text-align: center;
    padding: 16px;
    opacity: 0.4;
`;

const ErrorMessage = styled.div`
    color: var(--color-red);
    font-size: 12px;
`;

const SubModelForm = ({onCancel, onSave, editModel}: any) => {
	const form = useForm();
	const [error, setError] = useState<string>('');

	const cancelEdit = () => {
		onCancel();
	};

	const saveEdit = () => {
		if (!form.validate()) {
			setError('Please fix the errors and try again');
			return;
		}
		onSave(form.state.values);
	};

	return (
		<>
			{editModel.fields.filter(f => f.uid !== '__tag').map((f) => (
				<FormRow key={f.uid} field={f.uid}/>
			))}
			<FormActions>
				<ErrorMessage>{error}</ErrorMessage>
				<Spacer/>
				<Button onClick={() => cancelEdit()}>Cancel</Button>
				<Button onClick={() => saveEdit()}>Save</Button>
			</FormActions>
		</>
	);
};

const SubModelRenderer = ({field}: {field: Field}) => {
	const formField = useFormField(field.uid);
	const formId = `${field.uid}-subform`;
	const [expanded, setExpanded] = useState<{[key: string]: boolean}>({});
	const [edit, setEdit] = useState('');
	const [dragAndDrop, setDragAndDrop] = useRecoilState(dragAndDropState);

	useEffect(() => {
		if (!dragAndDrop || !dragAndDrop.type.toString().includes('-subform-list')) {
			return;
		}

		const {destination, source, draggableId} = dragAndDrop;
		if (!destination) {
			return;
		}
		// same position
		if (destination.droppableId === source.droppableId && destination.index === source.index) {
			return;
		}

		const newOrder = [...formField.value];
		newOrder.splice(source.index, 1);
		newOrder.splice(destination.index, 0, formField.value.find(w => w.__uuid === draggableId));
		setDragAndDrop(null);
		formField.setValue(newOrder);
	}, [dragAndDrop]);

	const editModel = useMemo(() => {
		return getFormConfig(field.config.refModel!).model;
	}, [field]);

	const removeOption = (index) => {
		const newValue = [...formField.value];
		newValue.splice(index, 1);
		formField.setValue(newValue);
	};

	const onAddStep = () => {
		const id = crypto.randomUUID();
		formField.setValue([...formField.value, {__uuid: id, isNew: true}]);
		setExpanded(val => ({...val, [id]: true}));
		setEdit(id);
	};

	const value = useMemo(() => {
		if (field.flags.multiple) {
			return formField.value.map((i) => InstanceOf({...i, __type: field.config.refModel!}));
		}
		return InstanceOf(formField.value);
	}, [formField.value, field]);

	const editOption = (id: string) => {
		setExpanded(val => ({...val, [id]: true}));
		setEdit(id);
	};

	const cancelEdit = (id: string) => {
		const list = [...formField.value];
		const step = list.find(item => item.__uuid === id);
		const idx = list.findIndex(item => item.__uuid === id);
		if (step && step.isNew && idx !== -1) {
			list.splice(idx, 1);
			formField.setValue(list);
		}
		setExpanded(val => ({...val, [id]: false}));
		setEdit('');
	};

	const saveEdit = (id: string, vals: any) => {
		const list = [...formField.value];
		const idx = list.findIndex(item => item.__uuid === id);


		if (idx !== -1) {
			if (list[idx].isNew) {
				delete list[idx].isNew;
			}
			list[idx] = {...list[idx], ...vals};
			formField.setValue(list);
		} else {
			formField.setValue([...formField.value, vals]);
		}
		setExpanded(val => ({...val, [id]: false}));
		setEdit('');
	};

	const toggleForm = (id: string) => {
		setExpanded(val => ({...val, [id]: !val[id]}));
	};

	if (!field.flags.multiple) {
		return (
			<Form model={editModel} id={formId} object={value}>
				{editModel.fields.filter(f => f.uid !== '__tag').map((f) => (
					<FormRow key={f.uid} field={f.uid}/>
				))}
			</Form>
		);
	}

	const preview = (obj: any) => {
		if (obj.preview) {
			return obj.preview();
		}
	};

	return (
		<>
			<MultipleOptionsWrapper>
				<StrictModeDroppable droppableId="droppable" type={`${field.uid}-subform-list`}>
					{(provided) => (
						<Tabs {...provided.droppableProps} ref={provided.innerRef}>
							{Lists.default<any>(value).length === 0 && (
								<EmptyState>
									There is nothing here yet. Click the button below to add a
									new {editModel.model.name.toLowerCase()}.
								</EmptyState>
							)}
							{Lists.default<any>(value).map((i, idx) => (
								<Draggable draggableId={i.getId()} index={idx} key={i.value}>
									{(provided) => (
										<>
											<Tab key={idx}
												 ref={provided.innerRef} {...provided.draggableProps} >
												<span {...provided.dragHandleProps}><DragIndicatorIcon/></span>
												<span>{i.displayLabel(DisplayMode.DETAILED)}</span>
												<ActionButton key={3} onClick={() => toggleForm(i.getId())}
															  disabled={Boolean(edit)}
															  title={'Show/hide configuration'}>
													{fi(expanded[i.getId()], <ExpandLessRounded/>,
														<ExpandMoreRounded/>)}
												</ActionButton>

												<Spacer/>
												<span tabIndex={0} className={`edit ${fi(Boolean(edit), 'disabled')}`}
													  title={`Edit ${editModel.model.name.toLowerCase()}`}
													  onClick={() => editOption(i.getId())}><EditNote/></span>
												<span tabIndex={0} className={`remove ${fi(Boolean(edit), 'disabled')}`}
													  title={`Delete ${editModel.model.name.toLowerCase()}`}
													  onClick={() => removeOption(idx)}><CloseRounded/></span>
											</Tab>
											<Children>
												{expanded[i.getId()] && (edit !== i.getId()) && i.preview()}
												{edit === i.getId() && (
													<Form model={editModel} id={formId} object={i}>
														<SubModelForm onCancel={() => cancelEdit(i.getId())}
																	  onSave={(val) => saveEdit(i.getId(), val)}
																	  editModel={editModel}/>
													</Form>
												)}
											</Children>
										</>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</Tabs>
					)}
				</StrictModeDroppable>
			</MultipleOptionsWrapper>
			<Button onClick={onAddStep} disabled={Boolean(edit)}>Add {editModel.model.name.toLowerCase()}</Button>
		</>
	);
};

export default SubModelRenderer;