import React, {useEffect, useState} from "react";
import {Droppable, DroppableProps} from 'react-beautiful-dnd';
import { UUID } from '../cms/types';

export const fi = (condition, a: any, b?: any): any => {
	return Boolean(condition) ? a : b;
};

// Handle action is used where there are click events on elements that not usually have those (divs, spans, etc)
// and you want them both on click and on enter when element is selected. Make sure you add tabindex="0" to the element.
export const handleAction = (callback: (e) => void): any => {
	return {
		onClick: (e) => {
			callback(e);
		},
		onKeyPress: (e) => {
			if (e.key === 'Enter') {
				callback(e);
			}
		},
	};
};

export const cancelEvent = (e: any): void => {
	e.preventDefault();
    e.stopPropagation();
}

export const suspensePromise = (promise) => {
	let status = 'pending';
	let result;

	let suspender = promise.then((r) => {
		status = 'success';
		result = r;
	}).catch((e) => {
		status = 'error';
		result = e;
	});

	return {
		read() {
			if (status === 'pending') {
				throw suspender;
			} else if (status === 'error') {
				throw result;
			} else if (status === 'success') {
				return result;
			}
		},
	};
};

export const flatten = (value: any, cb: (value: any) => any) => {
	if (typeof value === 'undefined' || value === null) {
		return '-'
	}
	if (Array.isArray(value)) {
        return value.map((v) => cb(v)).filter(a => a).join(', ') || '-';
    }
	return cb(value) || '-';
}

export const StrictModeDroppable = ({children, ...props}: DroppableProps) => {
	const [enabled, setEnabled] = useState(false);
	useEffect(() => {
		const animation = requestAnimationFrame(() => setEnabled(true));
		return () => {
			cancelAnimationFrame(animation);
			setEnabled(false);
		};
	}, []);
	if (!enabled) {
		return null;
	}
	return <Droppable {...props}>{children}</Droppable>;
};

export const generateYearRange = (): any[] => {
	const years: any[] = [];
	const now= new Date().getFullYear()
	for (let i = now - 20; i <= now + 20; i++) {
        years.push([{lang:'en', text: i}]);
    }
	return years;
}

export const getImagePath = (imageId: UUID, stream?: string) => {
	if (window.location.host.includes("localhost")) {
		return `http://localhost:9090/api/v1.0/pageAsset/${imageId}?stream=${stream}`;
	} else if (window.location.host.includes("dev.")) {
		return `https://api.dev.teacherwebsite.cambridgeassessment.org.uk/api/v1.0/pageAsset/${imageId}?stream=${stream}`;
	} else if (window.location.host.includes("test.")) {
		return `https://api.test.teacherwebsite.cambridgeassessment.org.uk/api/v1.0/pageAsset/${imageId}?stream=${stream}`;
	} else if (window.location.host.includes("pp1.")) {
		return `https://api.pp1.teacherwebsite.cambridgeassessment.org.uk/api/v1.0/pageAsset/${imageId}?stream=${stream}`;
	} else {
		return `https://api.teachcambridge.org/api/v1.0/pageAsset/${imageId}?stream=${stream}`;
	}
};

export const debounce = (func, timeout = 300) =>{
	let timer;
	return (...args) => {
		clearTimeout(timer);
		timer = setTimeout(() => { func.apply(this, args); }, timeout);
	};
}