import React, {useEffect, useMemo, useState} from 'react';
import {BulkFile} from '../../cms/models/BulkFile';
import { bulkTableProps } from '../../components/Bulk/state';
import {useRecoilState, useRecoilValue} from 'recoil';
import {bulkTableColumnsState} from './state';
import ProgressBar from '../commons/ProgressBar';
import TextAction from '../commons/TextAction';
import {fi} from '../../utils/helpers';
import styled from '@emotion/styled';
import {Strings} from '../../utils/strings';
import {cacheBuster} from '../../state/state';
import {Objects} from "../../utils/objects";
import {setRecoil} from "../../state/recoilNexus";
import {selectedRows} from './state';
import {Dates} from "../../utils/dates";
import {sessionAtom} from "../../state/session";
import {INTERNATIONAL_STREAM} from "../../utils/constants";

const Row = styled.tr`
    &:hover {
        background-color: var(--color-box-shadow);

        .fixed, .fixed-start {
            background-color: var(--color-box-shadow);
        }
    }
`;

const Cell = styled.td`
    padding: 0 8px;
    height: 36px;
    vertical-align: middle;
    text-align: left;
    position: relative;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 35px;

    &.no-padding {
        padding: 0;
    }

    &.centered {
        text-align: center;
    }

    &.fixed, &.fixed-start {
        margin-top: -0.5px;
        border-top-color: transparent;
    }

    &.empty {
        text-align: center;
        color: #c0c0c0;

        &.filename {
            text-align: left;
        }
    }

    &.ok {
        color: var(--color-green);
    }

    &.error {
        color: var(--color-red);
    }

    .MuiFormLabel-root {
        display: none;
    }

    &.has-error:after {
        content: ' ';
        display: block;
        width: 10px;
        height: 4px;
        position: absolute;
        right: 0;
        bottom: 0;
        background: transparent;
        border-right: 7px solid red;
        box-sizing: border-box;
        border-top: 7px solid transparent;
        z-index: 1;
    }
`;


const Block = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: row;
    align-items: center;
    pointer-events: none;
    white-space: nowrap;
    overflow: hidden;

    &.clickable {
        pointer-events: unset;
    }
`;

const RowStatus = styled.div`
    width: 2px;
    height: 100%;
    margin-right: 6px;

    &.error {
        background-color: red;
    }

    &.ok {
        background-color: green;
    }

    &.loading {
        background-color: cornflowerblue;
    }
`;

const ItemRow = ({file}: { file: BulkFile }) => {
    const [columnCount, setColumnCount] = useState(8);
    const [cache, setCache] = useRecoilState(cacheBuster(`bulk-file-${file.filename}`));
    const timezone = Dates.useTimeZone()

	const session = useRecoilValue(sessionAtom);

    const onRemove = () => {
        file.cancelUpload();
    };

    useEffect(() => {
        const columns = bulkTableProps.filter((column)=> {
            if (session?.selectedBusinessStream !== INTERNATIONAL_STREAM) {
                return column.name !== 'paper_number'
            }
            return true
        }).slice(1);

        setColumnCount(columns.length);
    }, [bulkTableProps]);

    const fields = useRecoilValue(bulkTableColumnsState);
    const columns = useMemo(() => {
        const res: any[] = [];
        fields.filter(field => field.visible === true).slice(1).forEach((field) => {
            const className: string[] = [];
            if (field.centered) {
                className.push('centered');
            }
            if (!file[field.name]) {
                className.push('empty');
            }
            if (file.errors[field.name]) {
                className.push('has-error');
            }
            res.push(
                <Cell data-testid={`${file.bulkid}-${field.name}`}
                      key={`${file.bulkid}-${field.name}`}
                      data-cell={true}
                      data-row={file.bulkid}
                      data-col={field.name}
                      title={Strings.default(file.errors[field.name], file[field.name])}
                      className={className.join(' ')}>
                    {Strings.default(file[field.name], 'Not set')}
                </Cell>,
            );
        });
        return res;
        // eslint-disable-next-line
    }, [file, cache, timezone]);

    const toggleSelect = (evt: React.ChangeEvent<HTMLInputElement>) => {
        file.selected = evt.target.checked;
        setRecoil(selectedRows, (val) => file.selected ? [...val, file.bulkid] : val.filter(id => id !== file.bulkid));
        setCache((val) => val + 1);
    }

    if (file.uploadInfo) {
        return (
            <Row key={file.bulkid}>
                <Cell className='fixed-start no-padding'>
                    <Block>
                        <RowStatus className='loading'/>
                        <input type='checkbox' value={file.bulkid}/>
                    </Block>
                </Cell>
                <Cell>
                    {file.filename}
                </Cell>
                <Cell colSpan={columnCount}>
                    <Block>
                        <ProgressBar total={100} done={file.uploadProgress} errors={0} label='Uploading file...'
                                     width={'100%'} height={'15px'}/>
                    </Block>
                </Cell>
                <Cell align='center'>
                    <Block>
                        {fi(file.error === 'Canceled',
                            <TextAction onClick={onRemove}>Remove</TextAction>,
                            <TextAction onClick={() => file.cancelUpload()}>Cancel</TextAction>,
                        )}
                    </Block>
                </Cell>
            </Row>
        );
    }

    return (
        <Row data-fileid={file.bulkid}>
            <Cell className='fixed-start no-padding'>
                <Block className='clickable'>
                    <RowStatus className={fi(!file.errorMessage, 'ok', 'error')}/>
                    <input type='checkbox' value={file.bulkid} onChange={(evt) => toggleSelect(evt)}
                           checked={file.selected}/>
                </Block>
            </Cell>
            <Cell data-row={file.bulkid} data-col='filename'
                  title={Strings.default(file.filename, 'File not set')}
                  className={`filename ${fi(Objects.isEmpty(file.fileInfo), 'empty has-error', '')}`}>
                {Strings.default(file.filename, 'Not set')}
            </Cell>
            {columns}
            <Cell data-row={file.bulkid}
                  data-col='error'
                  title={file.errorMessages}
                  className={`fixed ${fi(!file.errorMessage, 'ok', 'error')}`}>
                {Strings.default(file.errorMessage, 'OK')}
            </Cell>
        </Row>
    );
};

export default ItemRow;
