import { ReactElement } from 'react';
import { BaseClass } from './__base';
import { Strings } from '../../utils/strings';
import {INTERNATIONAL_STREAM, OCR_STREAM} from "../../utils/constants";

export enum WidgetType {
	/* ✓ */ ContentGroup = 'content_group', // ContentGroupWidget.tsx
	/* ✓ */ ContentType = 'content_type', // ContentTypeWidget.tsx
	ContentItems = 'content_items', // ContentItemsWidget.tsx
	/* ✓ */ ChildPages = 'child_pages', // ChildPagesWidget.tsx
	/* ✓ */ FAQ = 'faq', // FAQWidget.tsx
	/* ✓ */ Events = 'events', // EventsWidget.tsx
	/* ✓ */ FilterByQualification = 'qualifications', // SubjectFilterWidget.tsx
	/* ✓ */ FilterContent = 'years', // YearFilterWidget.tsx
	/* ✓ */ EventFilter = 'month_and_year', // EventsFilterWidget.tsx
	/* ✓ */ FreeText = 'text', // PageContentWidget.tsx
	/* ✓ */ UsefulLinks = 'links', // UsefulLinksWidget.tsx
	/* ✓ */ HomepageResources = 'home_page_resources', // HomePageResourcesWidget.tsx
	/* ✓ */ Homepage = 'home_page', // HomePageWidget.tsx
	/* ✓ */ SubjectUpdate = 'subject_update', // SubjectUpdateWidget.tsx
	/* ✓ */ GlobalAnnouncements = 'global_announcements', // GlobalAnnouncementsWidget.tsx
	/* ✓ */ KeyDates = 'key_dates', // KeyDatesWidget.tsx
	/* ✓ */ SizePathway = 'size_pathway', // SizePathwayWidget.tsx
	/* ✓ */ Form = 'form', // FormWidget.tsx
	/* ✓ */ QuickLinks = 'quick_links', // QuickLinksWidget.tsx
	/* ✓ */ Pathway = 'pathway', // PathwayWidget.tsx
	ResourceFinder = 'resource_finder', // ResourceFinderWidget.tsx
	MySubjects = 'my_subjects', // MySubjectsWidget.tsx
	SubjectsList = 'subjects_list' // SubjectsListWidget.tsx
}

export enum WidgetGroup {
	Content = 'content',
	Filters = 'filters',
	Other = 'other'
}

// Constrains on placing a widget in the page
export type WidgetConstrains = {
	// If true, widget can only be added to the top of the widgets list
	firstOnPage: boolean;
	// If true, widget can only be added to the bottom of the widgets list
	lastOnPage: boolean;
	// If defined, widget can only be added after a widget of this type;
	onlyAfter: WidgetType;
	// If true, widget can only be added once to the page
	once: boolean;
	// If true, widget can only be added on the page marked as homepage
	onlyOnHomePage: boolean;
	// If defined and widget can be added multiple times, then the given property of the widget must be different
	// from the property of the other widgets of the same type
	distinctProperty: string;
	// Allow only one widget with the properties from this list
	oneOf: {field: string, options: string[]};
}

export type WidgetConfig = {
	// Widget type
	type: WidgetType;
	// Widget name
	name: string;
	// Tooltip description
	description: string;
	// Widget group if any. If no group is defined it will be placed in WidgetGroup.Other
	group?: WidgetGroup;
	// Widget constrains if any
	constrains?: Partial<WidgetConstrains>;
	// If a widget is bound to a stream. If no stream is defined, the widget will be available on all streams
	stream?: 'OCR' | 'International';
}

export type WidgetMetaData = {
	Class: new(...args: any[]) => BaseWidget
} & WidgetConfig

export class BaseWidget extends BaseClass {
	// Holds the list of registered widgets and their metadata
	// added to this store through the @widgetConfig annotation
	public static widgetStore: {[key: string]: WidgetMetaData} = {};

	public type: WidgetType;
	public id: string;
	public __data: any = {};

	constructor(item: any = {}) {
		super(item);
		this.type = item.type;
		if (!window.crypto) {
			// @ts-ignore
			window['crypto'] = {
				randomUUID: () => {
					return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
						// @ts-ignore
						const r = Math.random()*16|0, v = c === 'x' ? r : ((r&0x3)|0x8);
						return v.toString(16);
					});
				},
			};
		}
		// needed to differentiate between widgets of same type
		this.id = Strings.default(item.id, crypto.randomUUID());
	}

	// The id of the widget is set as a unique UUID by the WidgetListRenderer
	// and is used by the Form component and as unique react key properties
	getId(): string {
		return Strings.default(this['id']);
	}

	getConfig(): WidgetConfig {
		return BaseWidget.widgetStore[this.type];
	}

	// Allows user to edit properties of the widget and see widget preview. Should
	// only be set to true for widgets that have properties
	editable(): boolean {
		return true;
	}

	// Returns the widget title amended with additional info if available
	getTitle(): ReactElement | string {
		const config = this.getConfig();
		return config.name;
	}

	// Renders the widget preview by the WidgetListRenderer
	preview(): ReactElement | null {
		return null;
	}

	// This is not explicitly used anywhere, but it's used indirectly through JSON.stringify which
	// calls this method
	toJSON() {
		const res: any = {};
		const excluded = ['__data', '__draft', '__versions', '__commitHistory'];
		for (let key in this) {
			if (excluded.includes(key)) {
				continue;
			}
			if (typeof this[key] === 'undefined' || this[key] === null) {
				continue;
			}
			res[key] = this[key];
		}
		return res;
	}

	public disabledFields(): string[] {
		return []
	}
}

// Class annotations used to register widget classes to the store
export function widgetConfig(config: Partial<WidgetConfig>) {
	return <T extends {new(...constructorArgs: any[])}>(constructorFunction: T) => {
		const tmp = new constructorFunction({});
		const type: any = tmp.type as string;
		BaseWidget.widgetStore[type] = {
			Class: constructorFunction as any,
			...config,
			type: type,
		} as any;
		return constructorFunction;
	};
}