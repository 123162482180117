import styled from '@emotion/styled';
import Button from '@mui/material/Button';

export const ActionButton = styled(Button)`
    padding: 2px 4px;
    margin: 4px 0;
    min-width: 24px;
    display: inline-flex;
    flex-direction: row;
	align-items: center;
	align-content: center;
	
    svg {
        color: var(--color-monochrome);
        width: 20px;
        height: 20px;
    }

    &:disabled {
        &:hover {
            background-color: transparent;
        }

        opacity: 0.5;
        pointer-events: initial !important;
    }
`;
