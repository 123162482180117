import {CMSObject} from './__CMSObject';
import {UUID} from '../types';
import {Strings} from '../../utils/strings';
import {Lists} from '../../utils/lists';
import {getRecoil} from '../../state/recoilNexus';
import {sessionAtom} from '../../state/session';
import {fi} from '../../utils/helpers';
import {Messages} from '../../utils/messages';
import {FormEvent} from '../../components/Form/model';
import {columnDefinition} from '../../utils/decorators';

export class ContentCategory extends CMSObject {
	public static title = 'Content category';
	public static autoPublish = true;

	@columnDefinition({
		label: 'Title'
	})
	public name: string;
	public description: string;
	public content_groups: UUID[];

	constructor(object: any = {}) {
		super(object);
		this.name = Strings.default(object.name);
		this.description = Strings.default(object.description);
		this.content_groups = Lists.default(object.content_groups);
	}

	public canCreate(): string {
		const user = getRecoil(sessionAtom);
		return fi(!user?.isPublisher(), Messages.CantCreateModel.replace('$model_name', 'content category'), '');
	}

	public async canDelete(): Promise<string> {
		const user = getRecoil(sessionAtom);
		if (!user || !user.isPublisher()) {
			return Messages.NoPermissionToDelete;
		}
		return '';
	}

	public formOnSaveError(evt: FormEvent): Error | void {
		if (evt.error.message.toLowerCase().includes('value already exists: name')) {
            evt.state.setError('name', Messages.DuplicateValue)
            evt.error.message = Messages.FixErrors
        }
        return evt.error
	}
}