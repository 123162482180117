import {CMSObject} from './__CMSObject';
import {Strings} from '../../utils/strings';
import {formField, linkedFormField} from '../../utils/decorators';
import {FieldType, ModelInfo} from './__ModelInfo';
import {FormEvent, IFormEvents} from '../../components/Form/model';

const Model = new ModelInfo({
	model: {
		uid: 'publish_schedule',
		name: 'Publish/Schedule',
	},
	fields: [],
});

export class PublishSchedule extends CMSObject implements IFormEvents {
	public static model = Model;

	@formField({
		uid: 'type',
		fieldtype: FieldType.List,
		name: 'Publish mode',
		config: {options: [Strings.localizedTxt('Publish now'), Strings.localizedTxt('Schedule')], isRadioGroup: true},
		order: 1,
	})
	public type: string = '';

	@formField({uid: 'published', group: 'dates', fieldtype: FieldType.Date, name: 'Publish from', order: 2})
	@linkedFormField('type')
	public published: string = '';

	@formField({uid: 'unpublish', group: 'dates', fieldtype: FieldType.Date, name: 'Publish to', order: 2})
	@linkedFormField('type')
	public unpublish: string = '';

	@formField({uid: 'show_updated', fieldtype: FieldType.Boolean, name: 'Show as updated', order: 3})
	public show_updated: boolean = false;

	@formField({uid: 'update_text',flags:{dummyRequired:true},  fieldtype: FieldType.Text, config: {isMultiline: true}, name: 'Summary of change', order: 4})
	@linkedFormField('show_updated')
	public update_text: string = '';

	constructor(item: any = {}) {
		const obj: any = {};
		const now = +new Date();

		if (item instanceof CMSObject) {
			const meta = item.getMeta();
			if (meta.published && !item.isDraft()) {
				obj.published = meta.published.toString();
				obj.type = 'Schedule';
			}

			if (meta.unpublish) {
				obj.unpublish = meta.unpublish.toString();
				if (new Date(obj.unpublish).getTime() < now) {
					obj.type = '';
				}
			}

			obj.__uuid = item.getId();
		}
		super(obj);

		this.published = Strings.default(obj.published);
		this.unpublish = Strings.default(obj.unpublish);

		this.type = Strings.default(obj.type, 'Publish now');
	}

	public formOnRenderField(evt: FormEvent): boolean {
		if (evt.fieldUID === 'published' || evt.fieldUID === 'unpublish') {
			return evt.state.values.type === 'Schedule';
		}
		if (evt.fieldUID === 'update_text') {
			return Boolean(evt.state.values.show_updated);
		}
		return evt.fieldUID !== '__tag';
	}

	public formOnValidateField(evt: FormEvent): string | void {
		const vals = evt.state.values;
		if (evt.fieldUID === 'unpublish') {
			try {
				new Date(evt.state.values.unpublish).toISOString()
			} catch (e) {
				return 'Please enter a valid date'
			}
		}
		if (evt.fieldUID === 'published') {
			try {
				new Date(evt.state.values.published).toISOString()
			} catch (e) {
				return 'Please enter a valid date'
			}
		}

		if (evt.fieldUID === 'unpublish' && evt.fieldValue) {
			if (vals.published && new Date(evt.state.values.published).getTime() >= new Date(evt.fieldValue).getTime()) {
				return 'End time cannot be equal or before start time';
			}
		}

		if (evt.fieldUID === 'published' && evt.fieldValue) {
			if (vals.unpublish && new Date(evt.state.values.unpublish).getTime() <= new Date(evt.fieldValue).getTime()) {
				return 'Start time cannot be less or equal to end time';
			}
		}
	}

	public formOnGroupValidation(evt: FormEvent): any {
		const errors: any = evt.state.errors
		if(new Date(evt.state.values.published).getTime() < new Date(evt.state.values.unpublish).getTime()) {
			errors['unpublish'] = "";
			errors['published'] = "";
		}
		return errors
	}

	public formOnValidate(evt: FormEvent): any {
		const errors: any = {};
		if (evt.state.values.type === 'Schedule') {
			if (!evt.state.values.published) {
				errors.published = 'Please select a valid date';
			}
			if (evt.state.values.unpublish) {
				try {
					new Date(evt.state.values.unpublish).toISOString()
				} catch (e) {
					errors.unpublish = 'Please select a valid date';
				}
			}
		}
		if (evt.state.values.show_updated && evt.state.values.update_text.trim() === '') {
			errors['update_text'] = 'Please enter a summary of change';
		}
		return errors;
	}

	public formOnBeforeSave(evt: FormEvent): any {
		const res: any = {};
		res.uuids = [this.__uuid];
		if (evt.state.values.type === 'Schedule') {
			if (evt.state.values.published) {
				res.start_date = new Date(evt.state.values.published).toISOString();
			}
			if (evt.state.values.unpublish) {
				res.end_date = new Date(evt.state.values.unpublish).toISOString();
			}
		} else {
			res.start_date = new Date().toISOString();
		}
		if (evt.state.values.show_updated) {
			res.change = evt.state.values.update_text.trim();
		} else {
			res.minor_change = true;
		}
		return res;
	}

}