import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Client from '../../cms/client';
import { useRecoilValue } from 'recoil';
import { cacheBuster, pageIconSelector } from '../../state/state';
import axios from 'axios';
import { sessionAtom } from '../../state/session';
import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import CloudUploadOutlined from '@mui/icons-material/CloudUploadOutlined';
import { Strings } from '../../utils/strings';
import { CMSFile } from '../../cms/models/File';
import { Objects } from '../../utils/objects';
import ClearIcon from '@mui/icons-material/Clear';
import { setRecoil } from '../../state/recoilNexus';
import { useSnackbar } from 'notistack';

const IconsWrapper = styled.div`
    padding: 8px;
    gap: 8px;
    margin-left: 8px;

    max-width: 500px;
    overflow: hidden;
    flex-wrap: wrap;
`;

const Icon = styled.div`
    display: flex;

    :hover {
        background-color: rgba(0, 0, 0, 0.05);

        > img {
            opacity: 0.1;
        }

        > div#clearIcon {
            display: flex;
            z-index: 1;
            color: var(--color-backdrop)
        }
    }
`;

const PageIconsWrapper = styled.div`
    display: flex;
    align-items: flex-start;

    & > label {
        margin-left: auto;
        padding: 8px 14px;
    }
`;

const Label = styled.div`
    font-family: var(--font-semi-bold);
    font-size: 16px;
    padding-top: 8px;
`;

const ClearIconStyled = styled.div`
    display: none;
    position: absolute;

    color: var(--color-monochrome);
    cursor: pointer;

    > svg {
        width: 25px;
        height: 25px;
    }
`;

const VisuallyHiddenInput = styled('input')({
	height: 1,
	overflow: 'hidden',
	position: 'absolute',
	bottom: 0,
	left: 0,
	whiteSpace: 'nowrap',
	width: 1,
});

let folderId = 0;
const validExtensions = [...CMSFile.Extensions.Image, 'svg'];

const Assets = () => {
	const pageIcons = useRecoilValue(pageIconSelector);
	const user = useRecoilValue(sessionAtom);
	const {enqueueSnackbar} = useSnackbar();

	const getLibraryFolders = async () => {
		const folders = await Client.getLibraryFolders();
		folderId = Objects.default(folders.find(f => f.name === 'pageIcons')).folderid;
	};

	useEffect(() => {
		getLibraryFolders().catch();
	}, []);

	if (!user) {
		return null;
	}

	const handleUpload = (evt) => {
		const filesList = evt.target.files;
		const files: File[] = [];

		for (let i = 0; i < filesList.length; i++) {
			const file = filesList[i];
			const ext = Strings.default(file.name.split('.').pop().toLowerCase());
			if (validExtensions.includes(ext)) {
				files.push(file);
			}
		}

		if (files.length === 0) {
			return;
		}

		files.forEach(async (file) => {
			Client.uploadFileToFolder(file, folderId);
			setRecoil(cacheBuster('pageIconCache'), (val) => val + 1);
		});

		enqueueSnackbar(`${Strings.pluralize(files.length, 'icon', 'icons')} uploaded successfully.`, {variant:'success'});

		evt.target.value = '';
	};

	const deleteIcon = async (iconId: string) => {
		await Client.deleteLibraryFile(iconId);
		setRecoil(cacheBuster('pageIconCache'), (val) => val + 1);
		enqueueSnackbar('Icon removed successfully', {variant: 'success'});
	};

	return (
			<div data-testid="assets-container">
				<div className="flex-row spaced">
					<Typography variant={'h4'}>Assets</Typography>
				</div>
				<Divider className="mt8 mb16"/>
				<PageIconsWrapper data-testid="page-icons-container">
					<Label>Page Icons:</Label>
					<IconsWrapper data-testid="icons" className={'flex-row'}>
						{pageIcons.map((icon, idx) => (
							<Icon key={idx} data-testid="icon">
								<img key={icon}
									 src={`${axios.defaults.baseURL}/iconDownload/${icon}?token=${user!.downloadToken}&stream=${user!.selectedBusinessStream}`}
									 alt={icon}
									 width={25}
									 height={25}/>
								<ClearIconStyled id="clearIcon" title="Delete icon"><ClearIcon
									onClick={() => deleteIcon(icon)}/></ClearIconStyled>
							</Icon>
						))}
					</IconsWrapper>
					<Button variant={'outlined'} startIcon={<CloudUploadOutlined/>} component="label" size="small">
						Upload page icons
						<VisuallyHiddenInput type="file" multiple={true} accept=".svg,.png,.jpg,.jpeg"
											 onChange={handleUpload}/>
					</Button>
				</PageIconsWrapper>
			</div>
	);
};

export default Assets;