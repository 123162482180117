import styled from '@emotion/styled';
import ClearIcon from '@mui/icons-material/Clear';
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import React, {useEffect, useRef} from 'react';
import {ActionButton} from '../commons/ActionButton';
import {Numbers} from '../../utils/numbers';

const BackDrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 7000;
    background: var(--color-backdrop);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

export const ModalContainerBox = styled.div<{width: number}>`
    box-sizing: border-box;
    width: ${props => props.width}px;
    height: auto;
    align-content: center;
    background: var(--color-white);
    border-radius: 4px;
    margin: auto;
    cursor: default;
    box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
`;

export const ModalHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    user-select: none;
`;

export const ModalContent = styled.div<{ height: number }>`
  padding: 24px;
	max-height: ${props => props.height}px;
	overflow-y: auto;
	@media (max-height: 720px) {
		max-height: 500px;
	}
`;

export const ModalFooter = styled.div`
    padding: 16px 24px;
    display: flex;
    justify-content: flex-end;
`;

type GenericModalProps = {
	title: string,
	footer?: React.ReactNode,
	children: React.ReactNode,
	width?: number,
	onClose: (evt) => void,
	height?: number,
}

const GenericModal = (props: GenericModalProps) => {
	const ref: any = useRef();

	const onBlur = (evt) => {
		const popover = document.getElementById('column-filter-popover');
		if (popover && popover.contains(evt.relatedTarget)) {
			return false;
		}
		if (evt.relatedTarget && !ref.current.contains(evt.relatedTarget) && evt.relatedTarget.tagName!=="INPUT") {
			try {
				ref.current.focus();
			} catch (e) {
			}
		}
	};

	useEffect(() => {
		ref.current.focus();
	}, [ref]);

	return (
		<BackDrop tabIndex={0} onBlur={onBlur} ref={ref}>
			<ModalContainerBox width={Numbers.default(props.width, 710)}>
				<ModalHeader>
					<Typography variant='h2' data-testid='modal-title'>{props.title}</Typography>
					<ActionButton
						onClick={props.onClose}
						data-testid='modal-close-button'
						title='Close'>
						<ClearIcon />
					</ActionButton>
				</ModalHeader>
				<Divider />
				<ModalContent height={Numbers.default(props.height, 600)}>
					{props.children}
				</ModalContent>
				{props.footer && (
					<>
						<Divider />
						<ModalFooter>
							{props.footer}
						</ModalFooter>
					</>
				)}
			</ModalContainerBox>
		</BackDrop>
	);
};

export default GenericModal;
