import {BaseWidget, widgetConfig, WidgetGroup, WidgetType} from './PageWidget';
import {FieldType} from './__ModelInfo';
import {formField, formHidden} from '../../utils/decorators';
import {Lists} from '../../utils/lists';
import React, {ReactElement} from 'react';
import {getRecoil} from '../../state/recoilNexus';
import {productDataSelector} from '../../state/state';
import {PreviewRow, PreviewWrapper} from './_style';

@widgetConfig({
	group: WidgetGroup.Other,
	name: 'Subjects list',
	description: 'Displays a list of available subjects under a Qualification group.',
})
export class SubjectsListWidget extends BaseWidget {

	@formHidden()
	public qualification_group_mapping: string[];
	@formHidden()
	public qualification_mapping: string[];

	@formField({
		fieldtype: FieldType.ProductData,
		order: 1,
		name: 'Select product mapping',
		flags: {required: true, subjectOnly: true},
	})
	public productData: string = '';

	@formField({
		fieldtype: FieldType.Boolean,
		flags: {hidden: true},
	})
	public all_assessments: boolean = false;

	constructor(item: any = {}) {
		super(item);

		this.type = WidgetType.SubjectsList;

		this.qualification_mapping = Lists.default(item.qualification_mapping);
		this.qualification_group_mapping = Lists.default(item.qualification_group_mapping);
		if (this.qualification_mapping.length === 0 && this.qualification_group_mapping.length === 0) {
			this.all_assessments = true;
			this.productData = 'all_assessments';
		} else {
			this.productData = [...this.qualification_group_mapping, ...this.qualification_mapping].join(',');
		}
	}


	preview(): ReactElement | null {
		const qualifications: any[] = [];
		this.qualification_group_mapping.forEach(q => {
			const productDataTree = getRecoil(productDataSelector);
			const qualification = productDataTree.qualifications.find(qq => qq.getId() === q);
			if (qualification) {
				let subjects = qualification.subjects.filter(s => this.qualification_mapping.includes(s.getId())).map(sbj => sbj.title);
				qualifications.push({
					qualification: qualification.title,
					subjects: subjects.length === qualification.subjects.length ? ['All subjects'] : subjects,
				});
			}
		});

		return (
			<PreviewWrapper>
				<PreviewRow className="vertical">
					{qualifications.map((q, idx) => (
						<PreviewRow key={idx}>
							<label>{q.qualification}</label>
							<ul>
								{q.subjects.map((s) => (
									<li key={s}>{s}</li>
								))}
							</ul>
						</PreviewRow>
					))}
				</PreviewRow>
			</PreviewWrapper>
		);
	}
}
