import {BaseWidget, widgetConfig, WidgetGroup, WidgetType} from "./PageWidget";
import React, {ReactElement} from "react";
import {Strings} from "../../utils/strings";
import {Lists} from "../../utils/lists";
import {formField} from "../../utils/decorators";
import {FieldType, ModelInfo} from "./__ModelInfo";
import {CMSObject, DisplayMode} from "./__CMSObject";
import {Types} from "../types";
import {FormEvent, IFormEvents} from "../../components/Form/model";
import {getRecoil} from "../../state/recoilNexus";
import {references} from "../../state/state";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import * as commonmark from "commonmark";
import styled from "@emotion/styled";
import LaunchIcon from '@mui/icons-material/Launch';

const Model = new ModelInfo({
    model: {
        uid: Types.PATHWAY_CONFIG,
        name: 'Pathway step',
    },
    fields: [],
});

const Wrapper = styled.div`
    .page-link {
        display: flex;
        align-items: center;
        margin-top: 8px;
        
        svg {
            width: 16px;
            height: 16px;
            margin-right: 4px;
        }
    }
    
    h1 {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 8px;
    }
    
    ul {
        list-style: initial;
        padding-left: 24px;
    }
`

export class PathwayConfig extends CMSObject implements IFormEvents {
    public static title = 'Pathway step';
    public static model = Model;

    @formField({
        name: "Name",
        fieldtype: FieldType.Text,
        flags: {required: true}
    })
    public name: string;
    @formField({
        name: "Color",
        placeholder: "Hex color code, e.g. #000000 for black, #ffffff",
        fieldtype: FieldType.Text,
        flags: {required: true},
        validations: [
            {
                type: 'pattern',
                config: {
                    pattern: /^#[0-9a-fA-F]{6}$/i,
                    patternErr: Strings.localizedTxt('Not a valid hex color code. It must be 6 characters long and start with a #. E.g. #000000')
                }
            }
        ]
    })
    public color: string;
    @formField({
        name: "Age",
        placeholder: "3+",
        fieldtype: FieldType.Text,
        flags: {required: true}
    })
    public age: string;
    @formField({
        name: "Description (you can use Markdown)",
        fieldtype: FieldType.Text,
        flags: {required: true},
        config: {isMultiline: true}
    })
    public description: string;
    @formField({
        name: "Subject info",
        fieldtype: FieldType.Text,
        flags: {required: true},
    })
    public subjectInfo: string;
    @formField({
        name: "Page link",
        fieldtype: FieldType.Reference,
        flags: {required: true},
        config: {refModel: Types.PAGE}
    })
    public link: string;

    constructor(item: any = {}) {
        super(item);
        this.name = Strings.default(item.name);
        this.color = Strings.default(item.color);
        this.age = Strings.default(item.age);
        this.description = Strings.default(item.description);
        this.link = Strings.default(item.link);
        this.subjectInfo = Strings.default(item.subjectInfo);
    }

    public displayLabel(options: DisplayMode = DisplayMode.SHORT): string {
        if (!this.name) return 'New step';

        return this.name + ' ' + this.age;
    }

    public formOnRenderField(evt: FormEvent) {
        return evt.fieldUID !== '__tag';
    }

    public HTMLDescription(): string {
        const reader = new commonmark.Parser();
        const writer = new commonmark.HtmlRenderer();
        const parsed = reader.parse(this.description);
        return writer.render(parsed);
    }

    public preview(): ReactElement | null {
        const page = getRecoil(references(this.link))

        return (
            <Wrapper>
                <div dangerouslySetInnerHTML={{__html:this.HTMLDescription()}} />
                <div className='mt8 page-link'><LaunchIcon /> {page?.displayLabel()}</div>
            </Wrapper>
        );
    }
}

@widgetConfig({
    group: WidgetGroup.Content,
    name: "Pathway",
    description: "Shows the Pathways graphic",
    stream: 'International'
})
export class PathwayWidget extends BaseWidget implements IFormEvents {
    @formField({
        name: "Pathway Title",
        fieldtype: FieldType.Text,
        flags: {required: true}
    })
    public title: string;

    @formField({
        name: "Color",
        placeholder: "Hex color code, e.g. #000000 for black, #ffffff",
        fieldtype: FieldType.Text,
        flags: {required: true},
        validations: [
            {
                type: 'pattern',
                config: {
                    pattern: /^#[0-9a-fA-F]{6}$/i,
                    patternErr: Strings.localizedTxt('Not a valid hex color code. It must be 6 characters long and start with a #. E.g. #000000')
                }
            }
        ]
    })
    public color: string;

    @formField({
        name: "Link",
        fieldtype: FieldType.Text,
        flags: {required: true},
    })
    public link: string;

    @formField({
        name: "Steps",
        fieldtype: FieldType.SubModel,
        flags: {required: true, multiple: true},
        config: {refModel: Types.PATHWAY_CONFIG}
    })
    public steps: PathwayConfig[] = [];

    constructor(item: any = {}) {
        super(item);
        this.title = Strings.default(item.title)
        this.color = Strings.default(item.color)
        this.link = Strings.default(item.link)
        this.type = WidgetType.Pathway
        this.steps = Lists.default(item.steps).map((step: any) => new PathwayConfig(step));
    }

    preview(): ReactElement | null {
        return (
            <div style={{padding: '16px', display: 'flex', flexDirection: 'column', gap: 8}}>
                <h3 style={{color: this.color, fontWeight:'bold'}}>{this.title}</h3>
                {Lists.default(this.steps).map((step: any, idx: number) => {
                    const page = getRecoil(references(step.link))
                    return <div key={idx} style={{
                        display: 'flex',
                        alignItems: 'center',
                        color: step.color
                    }}>{step.name} ({step.age}) <ArrowRightAltIcon/> {page?.displayLabel()}</div>
                })}
            </div>
        )
    }

    formOnBeforeSave(evt: FormEvent): any {
        const result = {...evt.state.values}
        result.steps = result.steps.map((step: any) => {
            const s = new PathwayConfig(step)
            const tmp = {...step}
            delete tmp.__data;
            delete tmp.__tag;
            delete tmp.__commitHistory;
            delete tmp.__draft;
            delete tmp.__meta;
            delete tmp.__versions;

            tmp.markdown = s.HTMLDescription();

            return tmp
        })
        return result;
    }
}