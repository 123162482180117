import tinymce from 'tinymce/tinymce';

window['tileContentActions'] = {
	open: () => {
		// default
	},
	callback: () => {
		// default
	},
}

tinymce.PluginManager.add('tilecontent', function (editor, _url) {
	editor.ui.registry.addIcon('tilecontenticon', `
		<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<style type="text/css">
				.st0 {
					fill: none;
					stroke: #000000;
					stroke-width: 2;
					stroke-linecap: round;
					stroke-linejoin: round;
					stroke-miterlimit: 10;
				}
			</style>
			<line class="st0" x1="22" y1="3" x2="29" y2="3" />
			<line class="st0" x1="22" y1="9" x2="29" y2="9" />
			<line class="st0" x1="22" y1="15" x2="29" y2="15" />
			<line class="st0" x1="3" y1="21" x2="29" y2="21" />
			<line class="st0" x1="3" y1="27" x2="29" y2="27" />
			<rect x="3" y="3" class="st0" width="13" height="12" />
			<polyline class="st0" points="3,13 8,9 12,12 16,9" />
			<circle class="st0" cx="12" cy="7" r="1" />
		</svg>`
	);

	window['tileContentActions'].callback = ({
		imagePath,
		title,
		description = ' ',
		alternativeText,
		link,
	}) => {

		const newContent = `
			<div class="tile-content-items">
				<img
					src="${imagePath}"
					alt="${alternativeText}"
				/>
				<div class="tile-content-text">
					<h4>${title}</h4>
						<p>${description}</p>
				</div>
				<div class="tile-content-link">
					<a href="${link}" target="_blank" rel="noopener noreferrer">
						Link
					</a>
				</div>
			</div>
		`;

		const currentContent = editor.getContent();

		if (
			currentContent.includes('<div class="tile-content-container">')
		) {
			const updatedContent = currentContent.replace(
				/<\/div>\s*<\/div>$/,
				`</div>${newContent}</div>`,
			);

			editor.setContent(updatedContent);
		} else {
			const wrappedContent = `
					<div class="tile-content-container">
							${newContent}
					</div>
			`;

			editor.insertContent(wrappedContent, {format: 'raw'});
		}
	};

	editor.ui.registry.addButton('tilecontent', {
		icon: 'tilecontenticon',
		tooltip: 'Tile Page Content',
		onAction: () => {
			window['tileContentActions'].open();
		},
	});

	return {
		getMetadata: () => ({
			name: 'Content tile plugin',
			url: 'https://teachcambridge.org/',
		}),
	};
});
