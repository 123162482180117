import { BaseClass } from './__base';
import { MediaItemFile } from './__MediaLibrary';
import { Strings } from '../../utils/strings';
import { CMSFile } from './File';
import { Objects } from '../../utils/objects';
import { Numbers } from '../../utils/numbers';
import { CancelTokenSource } from 'axios';
import Client from '../client';
import { getRecoil, getRecoilPromise, setRecoil } from '../../state/recoilNexus';
import { InstanceOf } from './index';
import { Types } from '../types';
import { ModelInfo } from './__ModelInfo';
import { cacheBuster, cmsObjectsLoader, productDataSelector, references } from '../../state/state';
import { bulkTableProps } from '../../components/Bulk/state';
import { ContentType } from './ContentType';
import { ProductDataTree } from './__ProductDataTree';
import { Lists } from '../../utils/lists';
import { Dates } from '../../utils/dates';
import { Assessment } from './Assessment';
import { QualificationSize } from './QualificationSize';
import {
	generateTree,
	Node,
	NodesMap, NodesMapType, resetTree,
	selectUp,
} from '../../components/Form/renderers/components/ProductDataSelect/utils';
import { Component } from './Component';
import { ChildAssessment } from './ChildAssessment';
import { CTUnit } from './CTUnit';
import { DisplayMode } from './__CMSObject';
import { Document } from './Document';
import { debounce } from 'lodash';
import { fi } from '../../utils/helpers';
import moment from 'moment';
import { INTERNATIONAL_STREAM, OCR_STREAM } from '../../utils/constants';

export type UploadInfo = {
	file: File;
	progress: number;
	cancel?: CancelTokenSource
}

const specialContentTypes = ['sa', 'ec'];

//type titleRules = 'none' | 'special' | 'examination';

const enum titleRules {
	none = 'none',
	special = 'special',
	examination = 'examination',
	nonAssessment = 'non-assessment',
}

export class BulkFile extends BaseClass {
	public static files: BulkFile[] = [];
	private static idGen: number = -1;
	public static model: ModelInfo;
	public static columns = bulkTableProps.slice(1).map((item) => item.name);

	// Db row id
	public bulkid: number;
	// CMS file UUID
	public fileid: string;
	public filename: string;
	// Bulk File error (processing or uploading error)
	public error: string;
	// Item data
	public data: any;

	public errors: any = {};

	// CMS file info
	public fileInfo?: CMSFile;
	public item?: MediaItemFile;
	public uploadInfo?: UploadInfo;

	public selected: boolean = false;
	private persistData: (...args: any[]) => any;

	constructor(tmp: any = {}) {
		const item = JSON.parse(JSON.stringify(tmp));
		if (!item.data) {
			item.data = {};
		}
		if (!item.data.__type) {
			item.data.__type = Types.DOCUMENT;
		}

		if (item.data.__tag) {
			if (typeof item.data.__tag === 'string') {
				item.data.__tag = item.data.__tag.split(',').map((s: string) => s.trim());
			} else if (typeof item.data.__tag === 'undefined') {
				item.data.__tag = [];
			}
		}

		super(item);

		this.persistData = debounce(async (files: any[]) => {
			return Client.updateBulkFiles(files);
		}, 500);

		this.bulkid = Numbers.default(item.bulkid, --BulkFile.idGen);
		this.fileid = Strings.default(item.fileid);
		this.filename = Strings.default(item.filename);
		this.error = Strings.default(item.error);
		this.data = Objects.default(item.data);

		if (this.data.series) {
			this.data.series = this.data.series.toUpperCase();
		}

		if (this.data.__type) {
			this.item = new Document(this.data);
			this.data.productData = this.item.productData;
		}
		if (item.file) {
			this.fileInfo = new CMSFile(item.file);
		}

		if (BulkFile.model) {
			this.errors = BulkFile.model.validate(this.data);
		}
	}

	public uploadFile(file: File): void {
		this.filename = file.name;
		this.uploadInfo = {
			file: file,
			progress: 0,
		};

		const [upload, cancel] = Client.upload(file, (progress => {
			this.uploadInfo!.progress = progress.percent * 175 / 100;
			this.invalidateCache();
		}));

		this.uploadInfo!.cancel = cancel;
		upload.then(res => {
			this.fileid = res.fileuid;
			this.data.file = res.fileuid;
			this.fileInfo = res;
			if (res.isAudio()) {
				this.data.__type = Types.AUDIO;
			} else {
				this.data.__type = Types.DOCUMENT;
			}
			this.item = InstanceOf(this.data);
			this.setData({...this.data}, true);
		}).catch(err => {
			this.error = err.message;
		}).finally(() => {
			this.uploadInfo = undefined;
			this.invalidateCache();
		});

		this.invalidateCache();
	}

	public setData(data: any = {}, persisit: boolean = false) {
		this.data = {...this.data, ...data};
		if (BulkFile.model) {
			this.errors = BulkFile.model.validate(this.data);
		}
		if (persisit) {
			this.save().catch(err => {
				this.error = err.message;
			});
		}
		this.invalidateCache();
	}

	public async save(): Promise<void> {
		if (this.bulkid < 1) {
			const obj: any = {fileid: this.fileid, filename: this.filename, data: this.data};
			if (!obj.fileid) {
				delete obj.fileid;
			}
			const tmp = await Client.addBulkItem(obj as BulkFile);
			this.bulkid = tmp.bulkid;
			this.error = Strings.default(tmp.error);
		} else {
			const obj: any = {bulkid: this.bulkid, fileid: this.fileid, filename: this.filename, data: this.data};
			if (!obj.fileid) {
				delete obj.fileid;
			}
			const tmp = await this.persistData([obj as BulkFile]);
			if (tmp && tmp.length === 1) {
				this.error = Strings.default(tmp[0].error);
			}
		}
		this.invalidateCache();
	}

	public invalidateCache(): void {
		setRecoil(cacheBuster(`bulk-file-${this.filename}`), (val) => val + 1);
	}

	public static addOrUpdate(item: BulkFile, cb: (existing: BulkFile) => void): BulkFile {
		let idx = BulkFile.files.findIndex(f => f.filename === item.filename);
		if (idx === -1) {
			BulkFile.files.push(item);
			Lists.sort(BulkFile.files, 'filename');
			setRecoil(cacheBuster(`bulk-file-all`), (val) => val + 1);
			resetTree(NodesMap[item.filename]);
			return item;
		} else {
			const existing = BulkFile.files[idx];
			cb(existing);
			BulkFile.files[idx] = existing;
			return existing;
		}
	}

	public static fromFileName(fileName: string): BulkFile {
		const data: any = {filename: fileName};
		const parts = fileName.split('.')[0].split('_');
		let title: string[] = [];
		const contentTypes = getRecoil(cmsObjectsLoader([Types.CONTENT_TYPE]));
		const productData = getRecoil(productDataSelector);

		let rule: string = titleRules.none;

		let years: string = '';
		parts.forEach((part, idx) => {
			if (idx === 0) {
				if (part.length !== 4) {
					return;
				}
				const [assessment, subject, qualification] = productData.getAssessment(part);
				if (assessment) {
					const a: Assessment = assessment as Assessment;
					data.assessment = [a.getId()];

					// assessment (syllabus code)
					title.push(a.code);
					// assessment name (syllabus name)
					title.push(a.name);

					data.child_assessment = a.childAssessments.map(c => c.getId());
					data.component = a.components.map(c => c.getId());
					if (a.specification_group) {
						data.specification_group = [...a.specification_group];
					}
					if (subject) {
						data.subject = [subject.getId()];
					}
					if (qualification) {
						data.qualification = [qualification.getId()];
						data.qualification_group = [...qualification.qualification_group];
					}
				}
				return;
			}

			if ('ymsw'.includes(part[0]) && Numbers.default(part[1])) {
				const year = part.substring(1).split('-');

				if (year.length === 1) {
					data.exam_year = [2000 + +year[0]];
					years = data.exam_year[0].toString();

				} else if (year.length === 2) {
					data.exam_year = [];
					for (let i = +year[0]; i <= +year[1]; i++) {
						data.exam_year.push(2000 + i);
					}
					years = data.exam_year[0] + '-' + data.exam_year[data.exam_year.length - 1];
				}

				switch (part[0]) {
					case 'm':
						data.series = 'MARCH';
						title.push('March');
						break;
					case 'w':
						data.series = 'NOVEMBER';
						title.push('November');
						break;
					case 's':
						data.series = 'JUNE';
						title.push('June');
						break;
				}

				title.push(years);

				return;
			}

			const ct = contentTypes.find((ct: any) => ct.keyword === part);

			if (ct) {
				// if content type keyword is ec or sa
				if (specialContentTypes.includes(part)) {
					rule = titleRules.special;
				}
				// if content type has #non-assessment  tag
				if (ct.hasTag('#non-assessment')) {
					rule = titleRules.nonAssessment;
				}
				// if the content type is ec or sa or is a non-assessment content type than the title should not contain the assessment (syllabus) name (keep only the code)
				if (rule === titleRules.special || rule === titleRules.nonAssessment) {
					title = [title[0]];
				}
				data.content_type = ct.getId();
				title.push((ct as ContentType).name);
			}
		});

		const paperNumber = parts[parts.length - 1];

		if (+paperNumber) {
			switch (rule) {
				case titleRules.special:
					if (years.includes('-')) {
						const parts = years.split('-');
						title.push(` Paper ${+paperNumber} (for examination from ${parts[0]} to ${parts[1]})`);
					} else {
						title.push(` Paper ${+paperNumber} (for examination from ${years})`);
					}
					break;
				case titleRules.nonAssessment:
					if (years.includes('-')) {
						const parts = years.split('-');
						title.push(` (for examination from ${parts[0]} to ${parts[1]})`);
					} else {
						title.push(` (for examination from ${years})`);
					}
					break;
					// here comes the implementation for examination rule that has to contain paper number as number followed by (for examination from year)
				default:
					title.push(` ${paperNumber}`);
			}
			data.paper_number = paperNumber;
		}

		data.title = title.join(' ');

		return new BulkFile({filename: fileName, data});
	}

	public static async fromTemplate(rows: string[][][], businessStream: string): Promise<BulkFile[]> {
		const response: BulkFile[] = [];
		const productData = await getRecoilPromise(productDataSelector);
		const contentTypes: ContentType[] = await getRecoilPromise(cmsObjectsLoader([Types.CONTENT_TYPE])) as any[];

		if (rows.length !== 2) {
			return [];
		}
		rows[0].forEach(row => {
			if (Lists.default(row).length) {
				response.push(BulkFile.fromExcelRow(row, false, contentTypes, productData, businessStream));
			}
		});

		rows[1].forEach(row => {
			if (Lists.default(row).length) {
				response.push(BulkFile.fromExcelRow(row, true, contentTypes, productData, businessStream));
			}
		});
		return response;
	}

	private static fromExcelRow(row: string[], isTechnicals: boolean, contentTypes: ContentType[], productData: ProductDataTree, businessStream: string): BulkFile {
		let [title, tag, filename, description, assessment, content_type, unit, ...rest] = row;

		let exam_year, series, public_from, component, paper_number;

		switch (businessStream) {
			case OCR_STREAM:
				if (isTechnicals) {
					[exam_year, series, public_from] = rest;
				} else {
					[component, exam_year, series, public_from] = rest;
				}
				break;
			case INTERNATIONAL_STREAM:
				({title, paper_number, exam_year, series } = BulkFile.fromFileName(filename));
				[component, public_from] = rest;
				break;
		}

		const data: any = {
			title,
			description,
		};

		const isValidYear = (year) => {
			const currentYear = new Date().getFullYear()
			return +year > 2004 && +year < currentYear + 40 && Number.isInteger(+year)
		}

		if (exam_year) {
			const years = exam_year.split(',').flatMap((year: any) => {
				if (isValidYear(year)) {
					return +year
				}

				if (year.includes('-')) {
					const range: number[] = []
					const years = year.split('-');

					const start = years[0]
					const end = years[years.length - 1]

					if (
						+start && +end
						&& +start <= +end
						&& isValidYear(start)
						&& isValidYear(end)
					) {
						for (let i = +start; i <= +end; i++) {
							range.push(i)
						}
						return range
					}
					return `${year} <INVALID YEAR>`
				}

				return `${year} <INVALID YEAR>`
			}).sort();

			const yearRanges = Array.from(new Set(years))

			if (yearRanges.length !== 0) {
				data.exam_year = yearRanges;
			}
		}

		if (paper_number) {
			data.paper_number = paper_number;
		}

		if (series) {
			if (['JANUARY', 'JUNE', 'NOVEMBER', 'MARCH'].includes(series.toUpperCase())) {
				data.series = series.toUpperCase();
			}
		}

		if (public_from) {
			data.public_from = moment(public_from, 'DD/MM/YYYY HH:mm').toISOString();
		}

		if (content_type) {
			const ct = contentTypes.find((item: ContentType) => item.name === content_type);
			if (ct) {
				data.content_type = ct.getId();
			}
		}

		if (tag) {
			data.__tag = tag.split(',').map(item => item.trim());
		}

		if (assessment) {
			if (assessment === 'All') {
				data.all_assessments = true;
				data.all_child_assessments = true;
			} else {
				const assessmentRefs: (Assessment | QualificationSize)[] = [];
				// map assessment ids
				const assessments = assessment.split(',').map(item => item.trim());
				assessments.forEach((code) => {
					const [assessment] = productData.getAssessment(code);
					if (assessment) {
						assessmentRefs.push(assessment);
						if (assessment.getType() === Types.ASSESSMENT) {
							data.assessment = Array.from(new Set([...Lists.default(data.assessment), assessment.getId()]));
						} else {
							data.qualification_size = Array.from(new Set([...Lists.default(data.qualification_size), assessment.getId()]));
						}
					}
				});

				// map units
				if (unit) {
					if (unit === 'All') { // push all child assessment/ct_unit codes
						data.all_child_assessments = true;
						assessmentRefs.forEach((a) => {
							if (a.getType() === Types.ASSESSMENT) {
								(a as Assessment).childAssessments.forEach((c) => {
									data.child_assessment = Array.from(new Set([...Lists.default(data.child_assessment), c.getId()]));
								});
							} else {
								(a as QualificationSize).units.forEach((c) => {
									data.ct_unit = Array.from(new Set([...Lists.default(data.ct_unit), c.getId()]));
								});
							}
						});

					} else { // push only what's defined
						const units = unit.split(',').map(item => item.trim());
						units.forEach((code) => {
							const [unit] = productData.getUnit(code);
							if (unit) {
								if (unit.getType() === Types.CT_UNIT) {
									data.ct_unit = Array.from(new Set([...Lists.default(data.ct_unit), unit.getId()]));
								} else {
									data.child_assessment = Array.from(new Set([...Lists.default(data.child_assessment), unit.getId()]));
								}
							}
						});
					}
				}

				if (component) {
					if (component === 'All') {
						assessmentRefs.forEach((a) => {
							(a as Assessment).components.forEach((c) => {
								data.component = Array.from(new Set([...Lists.default(data.component), c.getId()]));
							});
						});
					} else {
						const units = component.split(',').map(item => item.trim());
						units.forEach((code) => {
							const [unit] = productData.getUnit(code);
							if (unit) {
								data.component = Array.from(new Set([...Lists.default(data.component), unit.getId()]));
							}
						});
					}
				}
			}
		}

		return new BulkFile({
			filename,
			data,
		});
	}

	get title(): string {
		return Strings.default(Objects.default(this.data).title);
	}

	get description(): string {
		return Strings.default(Objects.default(this.data).description);
	}

	get __tag(): string {
		return Lists.default(Objects.default(this.data).__tag).join(', ');
	}

	get productData(): string {
		if (this.data.all_assessments || this.data.productData === 'all_assessments') {
			return 'All products selected';
		}

		let tmp: any;
		if (!NodesMap[this.filename]) {
			const tree = getRecoil(productDataSelector);
			const nodesTree: NodesMapType = {}
			generateTree(tree, false, nodesTree);
			NodesMap[this.filename] = nodesTree
		}

		tmp = NodesMap[this.filename]

		for (let k in tmp) {
			const obj = tmp[k];
			if (obj.object instanceof Component && Lists.default(this.data.component).includes(obj.id)) {
				obj.selected = true;
				selectUp(obj);
			}
			if (obj.object instanceof ChildAssessment && Lists.default(this.data.child_assessment).includes(obj.id)) {
				obj.selected = true;
				selectUp(obj);
			}
			if (obj.object instanceof CTUnit && Lists.default(this.data.ct_unit).includes(obj.id)) {
				obj.selected = true;
				selectUp(obj);
			}
		}

		const list: any[] = [];
		const added: any = {};
		const walk = (node: Node) => {
			if (node.selected) {
				if (!added[node.id]) {
					list.push(node.object.displayLabel(DisplayMode.SHORT));
					added[node.id] = true;
				}
			} else {
				for (let k in node.children) {
					walk(node.children[k]);
				}
			}
		};
		tmp[''].children.forEach(walk);
		return list.join(', ');
	}

	get public_from(): string {
		if (Objects.default(this.data).public_from) {
			return Dates.local(Objects.default(this.data).public_from);
		}
		return '';
	}

	get paper_number(): string {
		return Strings.default(Objects.default(this.data).paper_number);
	}

	get series(): string {
		return Strings.capitalize(Strings.default(Objects.default(this.data).series));
	}

	get exam_year(): string {
		const years = this.data.exam_year
		if (Array.isArray(years)) {
			let result = ""
			let i = 0;

			while (i < years.length) {
        let start = years[i];
        let end = start;

        while (years[i + 1] === end + 1) {
          end = years[i + 1];
          i++;
        }

        result += (start === end) ? `${start}, ` : `${start}-${end}, `;
        i++;
			}
			return result.slice(0, -2);
		} else {
			return Strings.default(Objects.default(this.data).exam_year);
		}
	}

	get content_type(): string {
		if (Objects.default(this.data).content_type) {
			const ct = getRecoil(references(this.data.content_type)) as any as ContentType;
			if (ct) {
				return ct.name;
			}
		}
		return '';
	}

	get uploadProgress(): number {
		if (this.uploadInfo) {
			return this.uploadInfo.progress;
		}
		return 0;
	}

	get errorMessage(): string {
		if (this.error) {
			return this.error;
		}
		const errors = Object.keys(this.errors).length;
		if (errors > 0) {
			return `${errors} Error${fi(errors > 1, 's', '')}`;
		}
		return '';
	}

	get errorMessages(): string {
		if (this.error) {
			return this.error;
		}
		const res: string[] = [];
		for (let k in this.errors) {
			const e = this.errors[k];
			switch (k) {
				case 'file':
					res.push(`File - ${e}`);
					break;
				case 'title':
					res.push(`Title - ${e}`);
					break;
				case 'productData':
					res.push(`Product mapping - ${e}`);
					break;
				case 'content_type':
					res.push(`Content type - ${e}`);
					break;
				case 'description':
					res.push(`Title - ${e}`);
					break;
				case 'exam_year':
					res.push(`Exam year - ${e}`);
					break;
				case 'series':
					res.push(`Series - ${e}`);
					break;
				case '__tag':
					res.push(`Tags - ${e}`);
					break;
				case 'public_from':
					res.push(`Public date - ${e}`);
					break;
			}
		}
		return res.join('\n');
	}

	public cancelUpload(): void {
		if (this.uploadInfo && this.uploadInfo.cancel) {
			this.uploadInfo.cancel.cancel();
			this.error = 'Canceled';
		}
	}
}
